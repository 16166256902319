export const APPOINTMENT_API_URL = {
  //Sessions
  GET_APPOINMENT_SESSIONS:
    "/api/APIappointment/AppointmentSession/GetAppointmentSessions",
  //Add_Sessions
  ADD_APPOINMENT_SESSIONS:
    "/api/APIappointment/AppointmentSession/AddAppointmentSession",
  //Requests
  GET_APPOINMENT_REQUESTS:
    "/api/APIappointment/AppointmentRequest/GetAppointmentRequests",
  //Get_Appointments
  GET_APPOINTMENTS: "/api/APIappointment/Appointment/GetAppointments",
  //Categories
  GET_APPOINMENT_CATEGORIES:
    "/api/APIappointment/AppointmentCategory/GetAppointmentCategorys",
  //Add_Categories
  ADD_APPOINMENT_CATEGORIES:
    "/api/APIappointment/AppointmentCategory/AddAppointmentCategory",
  //Update_Categories
  UPDATE_APPOINMENT_CATEGORIES:
    "/api/APIappointment/AppointmentCategory/UpdateAppointmentCategory",
  GETBYID_APPOINMENT_CATEGORIES: "/api/APIappointment/AppointmentCategory/GetAppointmentCategoryById",
  DELETE_APPOINMENT_CATEGORIES: "/api/APIappointment/AppointmentCategory/DeleteAppointmentCategory",
  //Checkout_Patients
  CHECKOUT_PATIENTS: "/api/APIappointment/patienttracker/CheckoutPatients",
  //Add_Multi_Appointment_Session
  ADD_MULTI_APPOINTMENT: "/api/APIappointment/AppointmentMultiSession/AddAppointmentMultiSession",
  //Get_Appointment_Multi_Sessions
  GET_APPOINTMENT_MULTI_SESSIONS:
    "/api/APIappointment/AppointmentMultiSession/GetAppointmentMultiSessions",
  //Get_Appointment_Multi_Session_ById
  GET_APPOINTMENT_MULTI_SESSION_BYID:
    "/api/APIappointment/AppointmentMultiSession/GetAppointmentMultiSessionById",
  //Update_Appointment_Multi_Session
  UPDATE_APPOINTMENT_MULTI_SESSION:
    "/api/APIappointment/AppointmentMultiSession/UpdateAppointmentMultiSession",
  //Add_Appointment_Session
  ADD_APPOINTMENT: "/api/APIappointment/Appointment/AddAppointment",
  //Get_Appointment_Sessions
  GET_APPOINTMENT_SESSION_BYID:
    "/api/APIappointment/AppointmentSession/GetAppointmentSessionById",
  GET_APPOINMENT_ID: "/api/APIAppointment/Appointment/GetAppointments",
  //Appointment_Manage_holidays
  APPOINTMENT_MANAGE_HOLIDAYS: "/api/APIappointment/AppointmentHoliday/ManageAppointmentHolidays",
  //Appointment_Manage_holidays
  GET_APPOINTMENT_HOLIDAYS: "/api/APIappointment/AppointmentHoliday/GetAppointmentHolidays"
  
};
