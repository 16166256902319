import { Routes } from "@angular/router";
import { AuthGuard } from "@guards/auth.guard";

export const routes: Routes = [
  {
    path: "auth/login",
    loadComponent: () =>
      import("@components/login-new/login-new.component").then((m) => m.LoginNewComponent),
    data: { showSideNav: false },
  },
  {
    path: "app",
    canActivate: [AuthGuard],
    data: { breadcrumb: "Home" },
    loadChildren: () =>
      import("@features/appmanager/appmanager.routes").then(
        (m) => m.appManagerRoutes
      ),
  },
  {
    path: "app",
    canActivate: [AuthGuard],
    data: { breadcrumb: "Home" },
    loadChildren: () =>
      import("@features/registration/registration.routes").then(
        (m) => m.registrationRoutes
      ),
  },
  {
    path: "app",
    canActivate: [AuthGuard],
    data: { breadcrumb: "Home" },
    loadChildren: () =>
      import("@features/appointments/appointment.routes").then(
        (m) => m.appointmentRoutes
      ),
  },
  {
    path: "dashboard",
    canActivate: [AuthGuard],
    data: { breadcrumb: "Home" },
    loadChildren: () =>
      import("@features/dashboard/dashboard.routes").then(
        (m) => m.dashboardRoutes
      ),
  },

  { path: "", redirectTo: "dashboard", pathMatch: "full" },
];

// import { Routes } from '@angular/router';
// import { AuthGuard } from '@guards/auth.guard';

// import { UserLayoutComponent } from '@components/user-layout/user-layout.component';
// import { AuthLayoutComponent } from '@components/auth-layout/auth-layout.component';

// export const routes: Routes = [
// {
//   path: "auth/login",
//   loadComponent: () =>
//     import("@components/login/login.component").then((m) => m.LoginComponent),
//   data: { showSideNav: false },
// },
//   {
//     path: '',
//     data: { breadcrumb: 'Home' },
//     component: UserLayoutComponent,
//     canActivate: [AuthGuard],
//     // canActivateChild: [AuthGuard],
//     children: [
//       { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
//       {
//         path: 'dashboard',
//         loadChildren: () =>
//           import('@features/dashboard/dashboard.routes').then(
//             (m) => m.dashboardRoutes
//           ),
//       },
//       {
//         path: 'app',
//         loadChildren: () =>
//           import('@features/appmanager/appmanager.routes').then(
//             (m) => m.appManagerRoutes
//           ),
//       },
//       {
//         path: 'registration',
//         loadChildren: () =>
//           import('@features/registration/registration.routes').then(
//             (m) => m.registrationRoutes
//           ),
//       },
//     ],
//   },
//   {
//     path: 'auth',
//     component: AuthLayoutComponent,
//     children: [
//       {
//         path: 'login',
//         loadComponent: () =>
//           import('@components/login/login.component').then(
//             (m) => m.LoginComponent
//           ),
//         data: { showSideNav: false },
//       },
//     ],
//   },
//   { path: '**', redirectTo: 'dashboard' },
// ];
