import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from "@angular/core";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormControl, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { SafeHtml } from "@angular/platform-browser";
import { UtilsService } from "@services/utils.service";
import { IconModule } from "@shared/icon.module";

@Component({
  selector: "app-select",
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    CommonModule,
    IconModule,
  ],
  templateUrl: "./select.component.html",
  styleUrl: "./select.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class SelectComponent {
  @Input() label: string = "";
  @Input() options: any = null;
  @Output() selectedValueChange = new EventEmitter<any>();
  @Input() control!: FormControl;
  @Input() formsubmitted: boolean = false;
  @Input() required: boolean = false;
  @Input() timeSelect: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() class: string = "";
  SelectDropdownDownArrowIcon!: SafeHtml;

  constructor(private utilsService: UtilsService) {}

  ngOnInit(): void {
    this.SelectDropdownDownArrowIcon =
      this.utilsService.SelectDropdownDownArrowIcon();
    // this.DatePickerClockIcon = this.utilsService.DatePickerClockIcon();
  }

  onSelectionChange(event: any) {
    this.selectedValueChange.emit(event.value);
  }
}
