import { inject, Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import {
  addEventTemplate,
  addEventTemplateSuccess,
  addFacility,
  addFacilitySuccess,
  addOrganization,
  addOrganizationSuccess,
  editEventTemplate,
  editEventTemplateSuccess,
  editOrganization,
  editOrganizationSuccess,
  facilityDeptMap,
  facilityDeptMapSuccess,
  facilityFileUpload,
  facilityFileUploadSuccess,
  getCity,
  getCitySuccess,
  getCountry,
  getCountrySuccess,
  getDepartmentList,
  getDepartmentListSuccess,
  getEventTemplateList,
  getEventTemplateListSuccess,
  getFacilityList,
  getFacilityListSuccess,
  getGroupsList,
  getGroupsListSuccess,
  getOptions,
  getOptionsSuccess,
  getOrganizationList,
  getOrganizationListSuccess,
  getPincode,
  getPincodeSuccess,
  getRolesList,
  getRolesListSuccess,
  getSpecialityList,
  getSpecialityListSuccess,
  getState,
  getStateSuccess,
  getUsersList,
  getUsersListSuccess,
  orgFileUpload,
  orgFileUploadSuccess,
  viewEventTemplate,
  viewEventTemplateSuccess,
  viewOrganization,
  viewOrganizationSuccess,
  getSpecialPrivilegeList,
  getSpecialPrivilegeListSuccess,
  viewFacility,
  viewFacilitySuccess,
  editFacility,
  editFacilitySuccess,
  facilityPreferenceTypes,
  facilityPreferenceTypesSuccess,
  facilityAddPreference,
  facilityAddPreferenceSuccess,
  setFacilityIdSuccess,
  setFacilityId,
  getFacilityDefaultServicesList,
  getFacilityDefaultServicesListSuccess,
  getFacilitySpecialHolidaySettingsList,
  getFacilitySpecialHolidaySettingsListSuccess,
  addHolidays,
  addHolidaysSuccess,
  editHolidays,
  editHolidaysSuccess,
  getFacilityServiceItems,
  getFacilityServiceItemsSuccess,
  orgGetLogo,
  orgGetLogoSuccess,
  getFacilityFirstLogo,
  getFacilityFirstLogoSuccess,
  getFacilitySecondLogo,
  getFacilitySecondLogoSuccess,
  addRole,
  addRoleSuccess,
  viewRole,
  viewRoleSuccess,
  editRole,
  editRoleSuccess,
  rolesMapFacility,
  rolesMapFacilitySuccess,
  getReferenceValues,
  getReferenceValuesSuccess,
  getModules,
  getModulesSuccess,
  getModulesScreens,
  getModulesScreensSuccess,
  getErpOutbound,
  getErpInbound,
  getErpInboundSuccess,
  getErpOutboundSuccess,
  getControls,
  getControlsSuccess,
  getReportSchedularsList,
  getReportSchedularsListSuccess,
  errorMessage,
  clearErrorMessage,
  clearErrorMessageSuccess,
  facilityGetDeptMap,
  facilityGetDeptMapSuccess,
  addDefaultService,
  addDefaultServiceSuccess,
  getHolidaySuccess,
  getHoliday,
  addDepartment,
  addDepartmentSuccess,
  viewDepartment,
  viewDepartmentSuccess,
  editDepartment,
  editDepartmentSuccess,
  addDeptLogo,
  addDeptLogoSuccess,
  getDeptLogo,
  getDeptLogoSuccess,
  rolesGetFacility,
  rolesGetFacilitySuccess,
  addGroup,
  addGroupSuccess,
  viewGroup,
  viewGroupSuccess,
  editGroup,
  editGroupSuccess,
  groupsMapFacility,
  groupsMapFacilitySuccess,
  groupsGetFacility,
  groupsGetFacilitySuccess,
  groupsMapRole,
  groupsMapRoleSuccess,
  groupsGetRole,
  groupsGetRoleSuccess,
  addFacilitySettings,
  addFacilitySettingsSuccess,
  getFacilitySettings,
  getFacilitySettingsSuccess,
  editFacilitySettings,
  editFacilitySettingsSuccess,
  rolesControlMap,
  rolesControlMapSuccess,
  addSpeciality,
  addSpecialitySuccess,
  rolesMapControlData,
  rolesMapControlDataSuccess,
  getControlforRoleMap,
  getControlforRoleMapSuccess,
  rolesGetAllReports,
  rolesGetAllReportsSuccess,
  rolesGetReports,
  rolesGetReportsSuccess,
  rolesMapReports,
  rolesMapReportsSuccess,
  addSpecialPrivilege,
  addSpecialPrivilegeSuccess,
  getPasswordResetList,
  getPasswordResetListSuccess,
  getResetPassword,
  getResetPasswordSuccess,
  getActiveUsersList,
  getActiveUsersListSuccess,
  getSequenceMasterList,
  getSequenceMasterListSuccess,
  addSequenceMaster,
  addSequenceMasterSuccess,
  redisToSql,
  redisToSqlSuccess,
  sqlToRedis,
  sqlToRedisSuccess,
  viewSpeciality,
  viewSpecialitySuccess,
  updateSpeciality,
  updateSpecialitySuccess,
  addReferenceGroup,
  addReferenceGroupSuccess,
  viewReferenceGroup,
  viewReferenceGroupSuccess,
  updateReferenceGroup,
  updateReferenceGroupSuccess,
  deleteReferenceValue,
  deleteReferenceValueSuccess,
  updateReferenceValue,
  updateReferenceValueSuccess,
  viewReferenceValue,
  viewReferenceValueSuccess,
  addReferenceValue,
  addReferenceValueSuccess,
  getReferenceGroups,
  getReferenceGroupsSuccess,
  addModuleSuccess,
  addModule,
  viewModule,
  viewModuleSuccess,
  updateModuleSuccess,
  updateModule,
  deleteModule,
  deleteModuleSuccess,
  deleteModuleScreenSuccess,
  deleteModuleScreen,
  updateModuleScreenSuccess,
  updateModuleScreen,
  viewModuleScreenSuccess,
  viewModuleScreen,
  addModuleScreen,
  addModuleScreenSuccess,
  getPrintLogs,
  getPrintLogsSuccess,
  addReportSchedulars,
  addReportSchedularsSuccess,
  viewReportSchedulars,
  viewReportSchedularsSuccess,
  editReportSchedulars,
  editReportSchedularsSuccess,
  getUnitDepartmentMappingList,
  getUnitDepartmentMappingListSuccess,
  getUnitDepartmentMappingDocterListSuccess,
  getUnitDepartmentMappingDocterList,
  addUnitDepartmentMapping,
  addUnitDepartmentMappingSuccess,
  updateUnitDepartmentMappingSuccess,
  updateUnitDepartmentMapping,
  viewUnitDepartmentMapping,
  viewUnitDepartmentMappingSuccess,
  deleteUnitDepartmentMapping,
  deleteUnitDepartmentMappingSuccess,
  addControl,
  addControlSuccess,
  viewControl,
  viewControlSuccess,
  updateControl,
  updateControlSuccess,
  addUser,
  addUserSuccess,
  editUser,
  editUserSuccess,
  viewUser,
  viewUserSuccess,
  userMapDept,
  userMapDeptSuccess,
  userGetDept,
  userGetDeptSuccess,
  userMapFacility,
  userMapFacilitySuccess,
  userGetFacility,
  userGetFacilitySuccess,
  userMapSpeciality,
  userMapSpecialitySuccess,
  userGetSpeciality,
  userGetSpecialitySuccess,
  userAddTeam,
  userAddTeamSuccess,
  userGetTeam,
  userGetTeamSuccess,
  userAddTaxDetails,
  userAddTaxDetailsSuccess,
  userViewTaxDetails,
  userViewTaxDetailsSuccess,
  userEditTaxDetails,
  userEditTaxDetailsSuccess,
  getRoleReportList,
  getRoleReportListSuccess,
  userAddDefaultService,
  userAddDefaultServiceSuccess,
  userViewDefaultService,
  userViewDefaultServiceSuccess,
  userGetEmployees,
  userGetEmployeesSuccess,
  addEventDashboard,
  addEventDashboardSuccess,
  getEventDashboardList,
  getEventDashboardListSuccess,
  viewEventDashboard,
  viewEventDashboardSuccess,
  editEventDashboard,
  editEventDashboardSuccess,
  getUserProfile,
  getUserProfileSuccess,
  getUserSign,
  getUserSignSuccess,
  getDistrictSuccess,
  getDistrict,
} from "./actions";

import { catchError, map, mergeMap, of, tap } from "rxjs";
import { Router } from "@angular/router";
import { CommonService } from "@services/common.service";
import { APP_MANAGER_API_URL } from "@core/api";
@Injectable()
export class AppManagerEffects {
  private action$ = inject(Actions);
  constructor(private commonService: CommonService, private router: Router) {}

  getEventTemplateList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getEventTemplateList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_EVENT_TEMPLATES)
          .pipe(
            map((tempResp) => {
              // console.log("tempResp::", tempResp);
              return getEventTemplateListSuccess({
                eventTemplateData: tempResp,
              });
            })
          )
      )
    )
  );

  addEventTemplate$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(addEventTemplate),
        mergeMap((action) =>
          this.commonService
            .post(action.payload, APP_MANAGER_API_URL.ADD_EVENT_TEMPLATES)
            .pipe(
              map((addTempResp) =>
                addEventTemplateSuccess({ addEventTemplateData: addTempResp })
              ),
              catchError((error) => {
                return of(errorMessage({ errorData: error }));
              })
            )
        )
      ),
    { dispatch: true }
  );

  viewEventTemplate$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewEventTemplate),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_EVENT_TEMPLATES)
          .pipe(
            map((viewTempResp) => {
              return viewEventTemplateSuccess({
                viewEventTemplateData: viewTempResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  editEventTemplate$ = createEffect(() =>
    this.action$.pipe(
      ofType(editEventTemplate),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_EVENT_TEMPLATES)
          .pipe(
            map((editTempResp) => {
              return editEventTemplateSuccess({
                editEventTemplateData: editTempResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getOrganizationList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getOrganizationList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_ORGANIZATION)
          .pipe(
            map((orgResp) => {
              return getOrganizationListSuccess({ organizationData: orgResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addOrganization$ = createEffect(() =>
    this.action$.pipe(
      ofType(addOrganization),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_ORGANIZATION)
          .pipe(
            map((orgResp) => {
              return addOrganizationSuccess({ addOrganizationData: orgResp });
            })
          )
      )
    )
  );
  viewOrganization$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewOrganization),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_ORGANIZATION)
          .pipe(
            map((orgResp) => {
              return viewOrganizationSuccess({ viewOrganizationData: orgResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  editOrganization$ = createEffect(() =>
    this.action$.pipe(
      ofType(editOrganization),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_ORGANIZATION)
          .pipe(
            map((editResp) => {
              return editOrganizationSuccess({
                editOrganizationData: editResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  orgFileUpload$ = createEffect(() =>
    this.action$.pipe(
      ofType(orgFileUpload),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_LOGO_ORGANIZATION)
          .pipe(
            map((fileResp) => {
              return orgFileUploadSuccess({ orgFileUploadData: fileResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  orgGetLogo$ = createEffect(() =>
    this.action$.pipe(
      ofType(orgGetLogo),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_LOGO_ORGANIZATION)
          .pipe(
            map((getLogoResp) => {
              return orgGetLogoSuccess({ orgGetLogoData: getLogoResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getFacilityList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getFacilityList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_FACILITY)
          .pipe(
            map((facilityResp) => {
              return getFacilityListSuccess({ facilityData: facilityResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addFacility$ = createEffect(() =>
    this.action$.pipe(
      ofType(addFacility),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_FACILITY)
          .pipe(
            map((addFacilityResp) => {
              return addFacilitySuccess({ addFacilityData: addFacilityResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  facilityFileUpload$ = createEffect(() =>
    this.action$.pipe(
      ofType(facilityFileUpload),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPLOAD_FILE_FACILITY)
          .pipe(
            map((fileUploadResp) => {
              return facilityFileUploadSuccess({
                facilityFileUploadData: fileUploadResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getFacilityFirstLogo$ = createEffect(() =>
    this.action$.pipe(
      ofType(getFacilityFirstLogo),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_FACILITY_FIRST_LOGO)
          .pipe(
            map((logo1Resp) => {
              return getFacilityFirstLogoSuccess({
                facilityFirstLogoData: logo1Resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getFacilitySecondLogo$ = createEffect(() =>
    this.action$.pipe(
      ofType(getFacilitySecondLogo),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_FACILITY_SECOND_LOGO)
          .pipe(
            map((logo2Resp) => {
              return getFacilitySecondLogoSuccess({
                facilitySecondLogoData: logo2Resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getFacilitySettings$ = createEffect(() =>
    this.action$.pipe(
      ofType(getFacilitySettings),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_FACILITY_SETINGS)
          .pipe(
            map((getFacilitySettingsResp) => {
              return getFacilitySettingsSuccess({
                getFacilitySettingsData: getFacilitySettingsResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addFacilitySettings$ = createEffect(() =>
    this.action$.pipe(
      ofType(addFacilitySettings),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_FACILITY_SETINGS)
          .pipe(
            map((addFacilitySettingsResp) => {
              return addFacilitySettingsSuccess({
                addFacilitySettingsData: addFacilitySettingsResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  editFacilitySettings$ = createEffect(() =>
    this.action$.pipe(
      ofType(editFacilitySettings),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_FACILITY_SETINGS)
          .pipe(
            map((editFacilitySettingsResp) => {
              return editFacilitySettingsSuccess({
                editFacilitySettingsData: editFacilitySettingsResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  facilityDeptMap$ = createEffect(() =>
    this.action$.pipe(
      ofType(facilityDeptMap),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.FACILITY_MAP_DEPARTMENT)
          .pipe(
            map((depMapResp) => {
              return facilityDeptMapSuccess({
                facilityDeptMapData: depMapResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  facilityGetDeptMap$ = createEffect(() =>
    this.action$.pipe(
      ofType(facilityGetDeptMap),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.FACILITY_GET_DEPARTMENT)
          .pipe(
            map((depMapResp) => {
              return facilityGetDeptMapSuccess({
                facilityGetDepMapData: depMapResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewFacility$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewFacility),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_FACILITY)
          .pipe(
            map((viewFacilityResp) => {
              return viewFacilitySuccess({
                viewFacilityData: viewFacilityResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  editFacility$ = createEffect(() =>
    this.action$.pipe(
      ofType(editFacility),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_FACILITY)
          .pipe(
            map((editFacilityResp) => {
              return editFacilitySuccess({
                editFacilityData: editFacilityResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  facilityPreferenceTypes$ = createEffect(() =>
    this.action$.pipe(
      ofType(facilityPreferenceTypes),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.FACILITY_PREFERENCE_TYPES)
          .pipe(
            map((facilityPrefResp) => {
              return facilityPreferenceTypesSuccess({
                facilityPreferenceData: facilityPrefResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  facilityAddPreference$ = createEffect(() =>
    this.action$.pipe(
      ofType(facilityAddPreference),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.FACILITY_ADD_PREFERENCE)
          .pipe(
            map((addResp) => {
              return facilityAddPreferenceSuccess({
                facilityAddPreferenceData: addResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  setFacilityId$ = createEffect(() =>
    this.action$.pipe(
      ofType(setFacilityId),
      map((action) => setFacilityIdSuccess({ facilityId: action.facilityId }))
    )
  );
  getFacilityDefaultServicesList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getFacilityDefaultServicesList),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APP_MANAGER_API_URL.GET_FACILITY_DEFAULT_SERVICES
          )
          .pipe(
            map((prvgResp) => {
              return getFacilityDefaultServicesListSuccess({
                facilityDefaultServicesData: prvgResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addDefaultService$ = createEffect(() =>
    this.action$.pipe(
      ofType(addDefaultService),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_DEFAULT_SERVICES)
          .pipe(
            map((prvgResp) => {
              return addDefaultServiceSuccess({
                addDefaultServiceData: prvgResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getFacilitySpecialHolidaySettingsList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getFacilitySpecialHolidaySettingsList),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APP_MANAGER_API_URL.GET_FACILITY_SPECIAL_HOLIDAY_SETTINGS
          )
          .pipe(
            map((tempResp) => {
              return getFacilitySpecialHolidaySettingsListSuccess({
                facilitySpecialHolidaySettingsData: tempResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  addHolidays$ = createEffect(() =>
    this.action$.pipe(
      ofType(addHolidays),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_HOLIDAYS)
          .pipe(
            map((addHolidayResp) => {
              return addHolidaysSuccess({ addHolidaysData: addHolidayResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getHoliday$ = createEffect(() =>
    this.action$.pipe(
      ofType(getHoliday),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_HOLIDAY)
          .pipe(
            map((addHolidayResp) => {
              return getHolidaySuccess({ getHolidaysData: addHolidayResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  editHolidays$ = createEffect(() =>
    this.action$.pipe(
      ofType(editHolidays),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_HOLIDAYS)
          .pipe(
            map((editHolidayResp) => {
              return editHolidaysSuccess({ editHolidaysData: editHolidayResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getFacilityServiceItems$ = createEffect(() =>
    this.action$.pipe(
      ofType(getFacilityServiceItems),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APP_MANAGER_API_URL.FACILITY_GET_DEFAULT_SERVICES_ITEMS
          )
          .pipe(
            map((serviceItemsResp) => {
              return getFacilityServiceItemsSuccess({
                serviceItemsData: serviceItemsResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getDepartmentList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getDepartmentList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_DEPARTMENTS)
          .pipe(
            map((deptResp) => {
              return getDepartmentListSuccess({ departmentData: deptResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addDepartment$ = createEffect(() =>
    this.action$.pipe(
      ofType(addDepartment),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_DEPARTMENT)
          .pipe(
            map((deptResp) => {
              return addDepartmentSuccess({ addDepartmentData: deptResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewDepartment$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewDepartment),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_DEPARTMENT)
          .pipe(
            map((deptResp) => {
              return viewDepartmentSuccess({ viewDepartmentData: deptResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  editDepartment$ = createEffect(() =>
    this.action$.pipe(
      ofType(editDepartment),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_DEPARTMENT)
          .pipe(
            map((deptResp) => {
              return editDepartmentSuccess({ editDepartmentData: deptResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addDeptLogo$ = createEffect(() =>
    this.action$.pipe(
      ofType(addDeptLogo),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_DEPARTMENT_LOGO)
          .pipe(
            map((deptResp) => {
              return addDeptLogoSuccess({ addDeptLogoData: deptResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getDeptLogo$ = createEffect(() =>
    this.action$.pipe(
      ofType(getDeptLogo),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_DEPARTMENT_LOGO)
          .pipe(
            map((deptResp) => {
              return getDeptLogoSuccess({ getDeptLogoData: deptResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getRolesList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getRolesList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_ROLES)
          .pipe(
            map((roleResp) => {
              return getRolesListSuccess({ rolesData: roleResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addRole$ = createEffect(() =>
    this.action$.pipe(
      ofType(addRole),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_ROLE)
          .pipe(
            map((roleResp) => {
              return addRoleSuccess({ addRoleData: roleResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewRole$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewRole),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_ROLE)
          .pipe(
            map((roleResp) => {
              return viewRoleSuccess({ viewRoleData: roleResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  editRole$ = createEffect(() =>
    this.action$.pipe(
      ofType(editRole),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_ROLE)
          .pipe(
            map((roleResp) => {
              return editRoleSuccess({ editRoleData: roleResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  rolesMapFacility$ = createEffect(() =>
    this.action$.pipe(
      ofType(rolesMapFacility),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ROLES_MAP_FACILITY)
          .pipe(
            map((mapResp) => {
              return rolesMapFacilitySuccess({ rolesMapFacilityData: mapResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  rolesGetFacility$ = createEffect(() =>
    this.action$.pipe(
      ofType(rolesGetFacility),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ROLES_GET_FACILITY)
          .pipe(
            map((mapResp) => {
              return rolesGetFacilitySuccess({ rolesGetFacilityData: mapResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getSpecialPrivilegeList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getSpecialPrivilegeList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_SPECIAL_PRIVILEGES)
          .pipe(
            map((prvgResp) => {
              return getSpecialPrivilegeListSuccess({
                specialPrivilegeData: prvgResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addSpecialPrivilege$ = createEffect(() =>
    this.action$.pipe(
      ofType(addSpecialPrivilege),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_SPECIAL_PRIVILEGES)
          .pipe(
            map((prvgResp) => {
              return addSpecialPrivilegeSuccess({
                addSpecialPrivilegeData: prvgResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getControlforRoleMap$ = createEffect(() =>
    this.action$.pipe(
      ofType(getControlforRoleMap),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_CONTROLS)
          .pipe(
            map((controlResp) => {
              return getControlforRoleMapSuccess({
                getControlRoleData: controlResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  rolesControlMap$ = createEffect(() =>
    this.action$.pipe(
      ofType(rolesControlMap),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ROLES_CONTROLS_GET_MAP)
          .pipe(
            map((controlResp) => {
              return rolesControlMapSuccess({
                rolesControlMapData: controlResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  rolesMapControlData$ = createEffect(() =>
    this.action$.pipe(
      ofType(rolesMapControlData),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ROLES_CONTROLS_MAP)
          .pipe(
            map((controlResp) => {
              return rolesMapControlDataSuccess({
                rolesControlMapsData: controlResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  rolesGetAllReports$ = createEffect(() =>
    this.action$.pipe(
      ofType(rolesGetAllReports),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ROLES_GET_ALL_REPORTS)
          .pipe(
            map((controlResp) => {
              return rolesGetAllReportsSuccess({
                rolesGetAllReportsData: controlResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  rolesGetReports$ = createEffect(() =>
    this.action$.pipe(
      ofType(rolesGetReports),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ROLES_GET_REPORTS)
          .pipe(
            map((controlResp) => {
              return rolesGetReportsSuccess({
                rolesGetReportsData: controlResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  rolesMapReports$ = createEffect(() =>
    this.action$.pipe(
      ofType(rolesMapReports),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ROLES_MAP_REPORTS)
          .pipe(
            map((controlResp) => {
              return rolesMapReportsSuccess({
                rolesMapReportsData: controlResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getGroupsList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getGroupsList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_GROUPS)
          .pipe(
            map((grpResp) => {
              return getGroupsListSuccess({ groupsData: grpResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addGroup$ = createEffect(() =>
    this.action$.pipe(
      ofType(addGroup),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_GROUP)
          .pipe(
            map((addGrpResp) => {
              return addGroupSuccess({ addGroupsData: addGrpResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewGroup$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewGroup),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_GROUP)
          .pipe(
            map((viewGrpResp) => {
              return viewGroupSuccess({ viewGroupsData: viewGrpResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  editGroup$ = createEffect(() =>
    this.action$.pipe(
      ofType(editGroup),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_GROUP)
          .pipe(
            map((editGrpResp) => {
              return editGroupSuccess({ editGroupsData: editGrpResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  groupsMapFacility$ = createEffect(() =>
    this.action$.pipe(
      ofType(groupsMapFacility),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GROUPS_MAP_FACILITY)
          .pipe(
            map((GrpMapResp) => {
              return groupsMapFacilitySuccess({
                groupsMapFacilityData: GrpMapResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  groupsGetFacility$ = createEffect(() =>
    this.action$.pipe(
      ofType(groupsGetFacility),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GROUPS_GET_FACILITY)
          .pipe(
            map((GrpGetResp) => {
              return groupsGetFacilitySuccess({
                groupsGetFacilityData: GrpGetResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  groupsMapRole$ = createEffect(() =>
    this.action$.pipe(
      ofType(groupsMapRole),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GROUPS_MAP_ROLES)
          .pipe(
            map((GrpMapResp) => {
              return groupsMapRoleSuccess({ groupsMapRoleData: GrpMapResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  groupsGetRole$ = createEffect(() =>
    this.action$.pipe(
      ofType(groupsGetRole),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GROUPS_GET_ROLES)
          .pipe(
            map((GrpGetResp) => {
              return groupsGetRoleSuccess({ groupsGetRoleData: GrpGetResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getSpecialityList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getSpecialityList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_SPECIALITY)
          .pipe(
            map((specialityResp) => {
              return getSpecialityListSuccess({
                specialityData: specialityResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  addSpeciality$ = createEffect(() =>
    this.action$.pipe(
      ofType(addSpeciality),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.SPECIALITY_ADD)
          .pipe(
            map((addSpecialityResp) => {
              return addSpecialitySuccess({
                addSpecialityData: addSpecialityResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  viewSpeciality$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewSpeciality),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.SPECIALITY_VIEW)
          .pipe(
            map((viewSpecialityResp) => {
              return viewSpecialitySuccess({
                viewSpecialityData: viewSpecialityResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  updateSpeciality$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateSpeciality),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.SPECIALITY_UPDATE)
          .pipe(
            map((updateSpecialityResp) => {
              return updateSpecialitySuccess({
                updateSpecialityData: updateSpecialityResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getUsersList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getUsersList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_USERS)
          .pipe(
            map((usersResp) => {
              return getUsersListSuccess({ usersData: usersResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addUser$ = createEffect(() =>
    this.action$.pipe(
      ofType(addUser),
      mergeMap((action) =>
        this.commonService
          .postWithFormData(action.payload, APP_MANAGER_API_URL.ADD_USER)
          .pipe(
            map((addUserResp) => {
              return addUserSuccess({ addUserData: addUserResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  editUser$ = createEffect(() =>
    this.action$.pipe(
      ofType(editUser),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_USER)
          .pipe(
            map((editUserResp) => {
              return editUserSuccess({ editUserData: editUserResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewUser$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewUser),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_USER)
          .pipe(
            map((viewUserResp) => {
              return viewUserSuccess({ viewUserData: viewUserResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userGetEmployees$ = createEffect(() =>
    this.action$.pipe(
      ofType(userGetEmployees),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_GET_EMPLOYEES)
          .pipe(
            map((userGetEmployeesResp) => {
              return userGetEmployeesSuccess({
                userGetEmployeesData: userGetEmployeesResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userMapDept$ = createEffect(() =>
    this.action$.pipe(
      ofType(userMapDept),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_MAP_DEPARTMENT)
          .pipe(
            map((userMapDeptResp) => {
              return userMapDeptSuccess({ userMapDeptData: userMapDeptResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userGetDept$ = createEffect(() =>
    this.action$.pipe(
      ofType(userGetDept),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_GET_DEPARTMENT)
          .pipe(
            map((userGetDeptResp) => {
              return userGetDeptSuccess({ userGetDeptData: userGetDeptResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userMapFacility$ = createEffect(() =>
    this.action$.pipe(
      ofType(userMapFacility),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_MAP_FACILITY)
          .pipe(
            map((userMapFacilityResp) => {
              return userMapFacilitySuccess({
                userMapFacilityData: userMapFacilityResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userGetFacility$ = createEffect(() =>
    this.action$.pipe(
      ofType(userGetFacility),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_GET_FACILITY)
          .pipe(
            map((userGetFacilityResp) => {
              return userGetFacilitySuccess({
                userGetFacilityData: userGetFacilityResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userMapSpeciality$ = createEffect(() =>
    this.action$.pipe(
      ofType(userMapSpeciality),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_MAP_SPECIALITY)
          .pipe(
            map((userMapSpecialityResp) => {
              return userMapSpecialitySuccess({
                userMapSpecialityData: userMapSpecialityResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userGetSpeciality$ = createEffect(() =>
    this.action$.pipe(
      ofType(userGetSpeciality),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_GET_SPECIALITY)
          .pipe(
            map((userGetSpecialityResp) => {
              return userGetSpecialitySuccess({
                userGetSpecialityData: userGetSpecialityResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userAddTeam$ = createEffect(() =>
    this.action$.pipe(
      ofType(userAddTeam),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_ADD_TEAMS)
          .pipe(
            map((userAddTeamResp) => {
              return userAddTeamSuccess({ userAddTeamData: userAddTeamResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userGetTeam$ = createEffect(() =>
    this.action$.pipe(
      ofType(userGetTeam),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_GET_TEAMS)
          .pipe(
            map((userGetTeamResp) => {
              return userGetTeamSuccess({ userGetTeamData: userGetTeamResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userAddTaxDetails$ = createEffect(() =>
    this.action$.pipe(
      ofType(userAddTaxDetails),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_ADD_TAX_DETAILS)
          .pipe(
            map((userAddTaxDetailsResp) => {
              return userAddTaxDetailsSuccess({
                userAddTaxDetailsData: userAddTaxDetailsResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userViewTaxDetails$ = createEffect(() =>
    this.action$.pipe(
      ofType(userViewTaxDetails),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_VIEW_TAX_DETAILS)
          .pipe(
            map((userViewTaxDetailsResp) => {
              return userViewTaxDetailsSuccess({
                userViewTaxDetailsData: userViewTaxDetailsResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userEditTaxDetails$ = createEffect(() =>
    this.action$.pipe(
      ofType(userEditTaxDetails),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_UPDATE_TAX_DETAILS)
          .pipe(
            map((userEditTaxDetailsResp) => {
              return userEditTaxDetailsSuccess({
                userEditTaxDetailsData: userEditTaxDetailsResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userAddDefaultService$ = createEffect(() =>
    this.action$.pipe(
      ofType(userAddDefaultService),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_ADD_DEFAULT_SERVICE)
          .pipe(
            map((userAddDefaultServiceResp) => {
              return userAddDefaultServiceSuccess({
                userAddDefaultServiceData: userAddDefaultServiceResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  userViewDefaultService$ = createEffect(() =>
    this.action$.pipe(
      ofType(userViewDefaultService),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.USER_GET_DEFAULT_SERVICE)
          .pipe(
            map((userViewDefaultServiceResp) => {
              return userViewDefaultServiceSuccess({
                userViewDefaultServiceData: userViewDefaultServiceResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getUserProfile$ = createEffect(() =>
    this.action$.pipe(
      ofType(getUserProfile),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_USER_PROFILE)
          .pipe(
            map((profileResp) => {
              console.log("profileResp", profileResp);
              return getUserProfileSuccess({
                userProfileData: profileResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getUserSign$ = createEffect(() =>
    this.action$.pipe(
      ofType(getUserSign),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_USER_SIGNATURE)
          .pipe(
            map((signResp) => {
              return getUserSignSuccess({
                userSignData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getPasswordResetList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPasswordResetList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_PASSWORD_RESET)
          .pipe(
            map((passwordResetResp) => {
              return getPasswordResetListSuccess({
                passwordResetData: passwordResetResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getResetPassword$ = createEffect(() =>
    this.action$.pipe(
      ofType(getResetPassword),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_RESET_PASSWORD)
          .pipe(
            map((resetPasswordResp) => {
              return getResetPasswordSuccess({
                resetPasswordData: resetPasswordResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getOptions$ = createEffect(() =>
    this.action$.pipe(
      ofType(getOptions),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_OPTIONS)
          .pipe(
            map((optResp) => {
              return getOptionsSuccess({ optionsData: optResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getCountry$ = createEffect(() =>
    this.action$.pipe(
      ofType(getCountry),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_COUNTRY)
          .pipe(
            map((countryResp) => {
              return getCountrySuccess({ countryData: countryResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getState$ = createEffect(() =>
    this.action$.pipe(
      ofType(getState),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_STATE)
          .pipe(
            map((stateResp) => {
              return getStateSuccess({ stateData: stateResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getCity$ = createEffect(() =>
    this.action$.pipe(
      ofType(getCity),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_CITY)
          .pipe(
            map((cityResp) => {
              return getCitySuccess({ cityData: cityResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getDistrict$ = createEffect(() =>
    this.action$.pipe(
      ofType(getDistrict),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_DISTRICT)
          .pipe(
            map((DistrictResp) => {
              return getDistrictSuccess({ DistrictData: DistrictResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getPincode$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPincode),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_PINCODE)
          .pipe(
            map((pincodeResp) => {
              return getPincodeSuccess({ pincodeData: pincodeResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getReferenceGroups$ = createEffect(() =>
    this.action$.pipe(
      ofType(getReferenceGroups),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_REFERENCE_GROUPS)
          .pipe(
            map((referenceResp) => {
              return getReferenceGroupsSuccess({
                referenceGroupsData: referenceResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addReferenceGroup$ = createEffect(() =>
    this.action$.pipe(
      ofType(addReferenceGroup),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.REFERENCE_GROUP_ADD)
          .pipe(
            map((referenceResp) => {
              return addReferenceGroupSuccess({
                addReferenceGroupData: referenceResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewReferenceGroup$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewReferenceGroup),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.REFERENCE_GROUP_VIEW)
          .pipe(
            map((referenceResp) => {
              return viewReferenceGroupSuccess({
                viewReferenceGroupData: referenceResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  updateReferenceGroup$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateReferenceGroup),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.REFERENCE_GROUP_UPDATE)
          .pipe(
            map((referenceResp) => {
              return updateReferenceGroupSuccess({
                updateReferenceGroupData: referenceResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getReferenceValues$ = createEffect(() =>
    this.action$.pipe(
      ofType(getReferenceValues),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_REFERENCE_VALUE)
          .pipe(
            map((referenceValueResp) => {
              return getReferenceValuesSuccess({
                referenceValueData: referenceValueResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  addReferenceValue$ = createEffect(() =>
    this.action$.pipe(
      ofType(addReferenceValue),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.REFERENCE_VALUE_ADD)
          .pipe(
            map((referenceResp) => {
              return addReferenceValueSuccess({
                addReferenceValueData: referenceResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewReferenceValue$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewReferenceValue),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.REFERENCE_VALUE_VIEW)
          .pipe(
            map((referenceResp) => {
              return viewReferenceValueSuccess({
                viewReferenceValueData: referenceResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  updateReferenceValue$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateReferenceValue),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.REFERENCE_VALUE_UPDATE)
          .pipe(
            map((referenceResp) => {
              return updateReferenceValueSuccess({
                updateReferenceValueData: referenceResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  deleteReferenceValue$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteReferenceValue),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.REFERENCE_VALUE_DELETE)
          .pipe(
            map((referenceResp) => {
              return deleteReferenceValueSuccess({
                deleteReferenceValueData: referenceResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getModules$ = createEffect(() =>
    this.action$.pipe(
      ofType(getModules),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_MODULES)
          .pipe(
            map((modResp) => {
              return getModulesSuccess({ modulesData: modResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addModule$ = createEffect(() =>
    this.action$.pipe(
      ofType(addModule),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_MODULE)
          .pipe(
            map((modResp) => {
              return addModuleSuccess({ addModuleData: modResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewModule$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewModule),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_MODULE)
          .pipe(
            map((modResp) => {
              return viewModuleSuccess({ viewModuleData: modResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  updateModule$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateModule),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_MODULE)
          .pipe(
            map((modResp) => {
              return updateModuleSuccess({ updateModuleData: modResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  deleteModule$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteModule),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.DELETE_MODULE)
          .pipe(
            map((modResp) => {
              return deleteModuleSuccess({ deleteModuleData: modResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getModulesScreens$ = createEffect(() =>
    this.action$.pipe(
      ofType(getModulesScreens),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_MODULES_SCREEN)
          .pipe(
            map((screenResp) => {
              return getModulesScreensSuccess({ screensData: screenResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addModuleScreen$ = createEffect(() =>
    this.action$.pipe(
      ofType(addModuleScreen),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_MODULE_SCREEN)
          .pipe(
            map((modResp) => {
              return addModuleScreenSuccess({ addModuleScreenData: modResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewModuleScreen$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewModuleScreen),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_MODULE_SCREEN)
          .pipe(
            map((modResp) => {
              return viewModuleScreenSuccess({ viewModuleScreenData: modResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  updateModuleScreen$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateModuleScreen),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_MODULE_SCREEN)
          .pipe(
            map((modResp) => {
              return updateModuleScreenSuccess({
                updateModuleScreenData: modResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  deleteModuleScreen$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteModuleScreen),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.DELETE_MODULE_SCREEN)
          .pipe(
            map((modResp) => {
              return deleteModuleScreenSuccess({
                deleteModuleScreenData: modResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getErpOutbound$ = createEffect(() =>
    this.action$.pipe(
      ofType(getErpOutbound),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_ERP_OUTBOUND)
          .pipe(
            map((outboundResp) => {
              return getErpOutboundSuccess({ erpOutboundData: outboundResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getErpInbound$ = createEffect(() =>
    this.action$.pipe(
      ofType(getErpInbound),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_ERP_INBOUND)
          .pipe(
            map((inboundResp) => {
              return getErpInboundSuccess({ erpInboundData: inboundResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getControls$ = createEffect(() =>
    this.action$.pipe(
      ofType(getControls),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_CONTROLS)
          .pipe(
            map((controlsResp) => {
              return getControlsSuccess({ controlsData: controlsResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addControl$ = createEffect(() =>
    this.action$.pipe(
      ofType(addControl),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_CONTROL)
          .pipe(
            map((controlsResp) => {
              return addControlSuccess({ addControlData: controlsResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewControl$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewControl),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_CONTROL)
          .pipe(
            map((controlsResp) => {
              return viewControlSuccess({ viewControlData: controlsResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  updateControl$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateControl),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_CONTROL)
          .pipe(
            map((controlsResp) => {
              return updateControlSuccess({ updateControlData: controlsResp });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getActiveUsersList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getActiveUsersList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_ACTIVE_USERS)
          .pipe(
            map((tempResp) => {
              return getActiveUsersListSuccess({
                activeUsersListData: tempResp,
              });
            })
          )
      )
    )
  );
  getSequenceMasterList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getSequenceMasterList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_SEQUENCE_MASTERS)
          .pipe(
            map((tempResp) => {
              return getSequenceMasterListSuccess({
                sequenceMasterData: tempResp,
              });
            })
          )
      )
    )
  );
  addSequenceMaster$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(addSequenceMaster),
        mergeMap((action) =>
          this.commonService
            .post(action.payload, APP_MANAGER_API_URL.ADD_SEQUENCE_MASTER)
            .pipe(
              map((addSeqResp) =>
                addSequenceMasterSuccess({ addSequenceMasterData: addSeqResp })
              ),
              catchError((error) => {
                return of(errorMessage({ errorData: error }));
              })
            )
        )
      ),
    { dispatch: true }
  );

  redisToSql$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(redisToSql),
        mergeMap((action) =>
          this.commonService
            .post(action.payload, APP_MANAGER_API_URL.REDIS_TO_SQL_SEQUENCE)
            .pipe(
              map((redisToSqlResp) =>
                redisToSqlSuccess({ redisToSqlData: redisToSqlResp })
              ),
              catchError((error) => {
                return of(errorMessage({ errorData: error }));
              })
            )
        )
      ),
    { dispatch: true }
  );
  sqlToRedis$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(sqlToRedis),
        mergeMap((action) =>
          this.commonService
            .post(action.payload, APP_MANAGER_API_URL.SQL_TO_REDIS_SEQUENCE)
            .pipe(
              map((sqlToRedisResp) =>
                sqlToRedisSuccess({ sqlToRedisData: sqlToRedisResp })
              ),
              catchError((error) => {
                return of(errorMessage({ errorData: error }));
              })
            )
        )
      ),
    { dispatch: true }
  );

  getPrintLogs$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPrintLogs),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_PRINT_LOGS)
          .pipe(
            map((tempResp) => {
              return getPrintLogsSuccess({
                getPrintLogData: tempResp,
              });
            })
          )
      )
    )
  );
  addReportSchedulars$ = createEffect(() =>
    this.action$.pipe(
      ofType(addReportSchedulars),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_REPORT_SCHEDULARS)
          .pipe(
            map((addResp) => {
              return addReportSchedularsSuccess({
                addReportSchedularsData: addResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewReportSchedulars$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewReportSchedulars),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_REPORT_SCHEDULARS)
          .pipe(
            map((viewResp) => {
              return viewReportSchedularsSuccess({
                viewReportSchedularsData: viewResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  editReportSchedulars$ = createEffect(() =>
    this.action$.pipe(
      ofType(editReportSchedulars),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_REPORT_SCHEDULARS)
          .pipe(
            map((editResp) => {
              return editReportSchedularsSuccess({
                editReportSchedularsData: editResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getReportSchedularsList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getReportSchedularsList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_REPORT_SCHEDULARS)
          .pipe(
            map((reportSchedularsResp) => {
              return getReportSchedularsListSuccess({
                reportSchedularsData: reportSchedularsResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getRoleReportList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getRoleReportList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_ROLE_REPORTS)
          .pipe(
            map((reportSchedularsResp) => {
              return getRoleReportListSuccess({
                roleReportData: reportSchedularsResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getUnitDepartmentMappingList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getUnitDepartmentMappingList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_UNIT_DEPARTMENT_MAPPING)
          .pipe(
            map((unitDepartmentResp) => {
              return getUnitDepartmentMappingListSuccess({
                unitDepartmentMappingData: unitDepartmentResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getUnitDepartmentMappingDocterList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getUnitDepartmentMappingDocterList),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APP_MANAGER_API_URL.GET_UNIT_DEPARTMENT_MAPPING_DOCTOR_LIST
          )
          .pipe(
            map((unitDepartmentResp) => {
              return getUnitDepartmentMappingDocterListSuccess({
                unitDepartmentMappingDocterListData: unitDepartmentResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  addUnitDepartmentMapping$ = createEffect(() =>
    this.action$.pipe(
      ofType(addUnitDepartmentMapping),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_UNIT_DEPARTMENT_MAPPING)
          .pipe(
            map((unitDepartmentResp) => {
              return addUnitDepartmentMappingSuccess({
                addUnitDepartmentMappingData: unitDepartmentResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  updateUnitDepartmentMapping$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateUnitDepartmentMapping),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APP_MANAGER_API_URL.UPDATE_UNIT_DEPARTMENT_MAPPING
          )
          .pipe(
            map((unitDepartmentResp) => {
              return updateUnitDepartmentMappingSuccess({
                updateUnitDepartmentMappingData: unitDepartmentResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  viewUnitDepartmentMapping$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewUnitDepartmentMapping),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APP_MANAGER_API_URL.VIEW_UNIT_DEPARTMENT_MAPPING
          )
          .pipe(
            map((unitDepartmentResp) => {
              return viewUnitDepartmentMappingSuccess({
                viewUnitDepartmentMappingData: unitDepartmentResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  deleteUnitDepartmentMapping$ = createEffect(() =>
    this.action$.pipe(
      ofType(deleteUnitDepartmentMapping),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APP_MANAGER_API_URL.DELETE_UNIT_DEPARTMENT_MAPPING
          )
          .pipe(
            map((unitDepartmentResp) => {
              return deleteUnitDepartmentMappingSuccess({
                deleteUnitDepartmentMappingData: unitDepartmentResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addEventDashboard$ = createEffect(() =>
    this.action$.pipe(
      ofType(addEventDashboard),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.ADD_EVENT_DASHBOARD)
          .pipe(
            map((unitDepartmentResp) => {
              return addEventDashboardSuccess({
                addEventDashboardData: unitDepartmentResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getEventDashboardList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getEventDashboardList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.GET_EVENT_DASHBOARD)
          .pipe(
            map((eventResp) => {
              return getEventDashboardListSuccess({
                getEventDashboardListData: eventResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewEventDashboard$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewEventDashboard),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.VIEW_EVENT_DASHBOARD)
          .pipe(
            map((eventResp) => {
              return viewEventDashboardSuccess({
                viewEventDashboardData: eventResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  editEventDashboard$ = createEffect(() =>
    this.action$.pipe(
      ofType(editEventDashboard),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APP_MANAGER_API_URL.UPDATE_EVENT_DASHBOARD)
          .pipe(
            map((eventResp) => {
              return editEventDashboardSuccess({
                editEventDashboardData: eventResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  clearErrorMessage$ = createEffect(() =>
    this.action$.pipe(
      ofType(clearErrorMessage),
      map(() => {
        return clearErrorMessageSuccess();
      })
    )
  );
}
