import { localStorageSync } from "ngrx-store-localstorage";
import { ActionReducer, MetaReducer } from "@ngrx/store";

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({
    keys: ["authState"], // Specify the state slices to persist
    rehydrate: true,
  })(reducer);
}

export const metaReducers: MetaReducer<any>[] = [localStorageSyncReducer];
