import { inject, Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import {
  addConsultantCheckoutPatient,
  addConsultantCheckoutPatientSuccess,
  addMrdRequest,
  addMrdRequestSuccess,
  addOccupation,
  addOccupationSuccess,
  AddPaitentGuarantor,
  AddPaitentGuarantorGl,
  AddPaitentGuarantorGlSuccess,
  AddPaitentGuarantorSuccess,
  addPatient,
  addPatientAttachment,
  addPatientAttachmentSuccess,
  addPatientFollowup,
  addPatientFollowupSuccess,
  addPatientId,
  addPatientIdSuccess,
  addPatientKins,
  addPatientKinsSuccess,
  addPatientSuccess,
  addReferral,
  addReferralSuccess,
  addRegisterCumVisit,
  addRegisterCumVisitSuccess,
  addRegisterWithBill,
  addRegisterWithBillSuccess,
  assignPatient,
  assignPatientSuccess,
  bulkAddPatients,
  bulkAddPatientsSuccess,
  CheckoutPatient,
  CheckoutPatientSuccess,
  clearErrorMessage,
  clearErrorMessageSuccess,
  convertXlstoJson,
  convertXlstoJsonSuccess,
  DeletePatientGuarantorGlById,
  DeletePatientGuarantorGlByIdSuccess,
  editPatientId,
  editPatientIdSuccess,
  editPatientKin,
  editPatientKinSuccess,
  errorMessage,
  getDefaultService,
  getDefaultServiceSuccess,
  getDiagnosiss,
  getDiagnosissSuccess,
  getDoctorDefaultService,
  getDoctorDefaultServiceSuccess,
  getDoctorShare,
  getDoctorShareSuccess,
  getEncounter,
  getEncounterSuccess,
  getFacilityDashboard,
  getFacilityDashboardSuccess,
  getFamilyLinks,
  getFamilyLinksSuccess,
  getMrdRequest,
  getMrdRequestSuccess,
  getOccupation,
  getOccupationSuccess,
  getOpdDashboard,
  getOpdDashboardSuccess,
  GetOtRegisters,
  GetOtRegistersSuccess,
  GetPaitentGuarantor,
  GetPaitentGuarantorGl,
  GetPaitentGuarantorGlSuccess,
  GetPaitentGuarantorSuccess,
  getPatientAttachments,
  getPatientAttachmentsSuccess,
  GetPatientAuditLogs,
  GetPatientAuditLogsSuccess,
  getPatientBills,
  getPatientBillsSuccess,
  getPatientById,
  getPatientByIdSuccess,
  getPatientFollowup,
  getPatientFollowupSuccess,
  GetPatientGuarantorById,
  GetPatientGuarantorByIdSuccess,
  GetPatientGuarantorGlById,
  GetPatientGuarantorGlByIdSuccess,
  getPatientId,
  getPatientIdSuccess,
  GetPatientKin,
  GetPatientKins,
  GetPatientKinsSuccess,
  GetPatientKinSuccess,
  getPatientProfilePic,
  getPatientProfilePicSuccess,
  getPatientSearch,
  getPatientSearchSuccess,
  getReferrals,
  getReferralsSuccess,
  manageFamilyLinks,
  manageFamilyLinksSuccess,
  managePatientId,
  managePatientIdSuccess,
  mergePatient,
  mergePatientSuccess,
  patientPortal,
  patientPortalSuccess,
  UpdatePaitentGuarantor,
  UpdatePaitentGuarantorGl,
  UpdatePaitentGuarantorGlSuccess,
  UpdatePaitentGuarantorSuccess,
  updatePatient,
  updatePatientFollowup,
  updatePatientFollowupSuccess,
  updatePatientSuccess,
  viewPatientFollowup,
  viewPatientFollowupSuccess,
} from "./actions";

import { catchError, map, mergeMap, of } from "rxjs";
import { Router } from "@angular/router";
import { CommonService } from "@services/common.service";
import { REGISTRAION_API_URL } from "@core/api/registration";
@Injectable()
export class RegisterEffects {
  private action$ = inject(Actions);
  constructor(private commonService: CommonService, private router: Router) { }

  getPatientSearch$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPatientSearch),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.PATIENT_SEARCH)
          .pipe(
            map((tempResp) => {
              return getPatientSearchSuccess({
                patientSearchData: tempResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getPatientProfilePic$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPatientProfilePic),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_PROFILE_PIC)
          .pipe(
            map((profileResp) => {
              return getPatientProfilePicSuccess({
                patientProfilePicData: profileResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addPatient$ = createEffect(() =>
    this.action$.pipe(
      ofType(addPatient),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_PATIENT)
          .pipe(
            map((signResp) => {
              return addPatientSuccess({
                addPatientData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addRegisterCumVisit$ = createEffect(() =>
    this.action$.pipe(
      ofType(addRegisterCumVisit),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_REGISTER_CUM_VIIST)
          .pipe(
            map((signResp) => {
              return addRegisterCumVisitSuccess({
                addRegisterCumVisitData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  updatePatient$ = createEffect(() =>
    this.action$.pipe(
      ofType(updatePatient),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.UPDATE_PATIENT)
          .pipe(
            map((signResp) => {
              return updatePatientSuccess({
                updatePatientData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getPatientById$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPatientById),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_BY_ID)
          .pipe(
            map((resp) => {
              return getPatientByIdSuccess({
                getPatientByIdData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  patientPortal$ = createEffect(() =>
    this.action$.pipe(
      ofType(patientPortal),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_PATIENT_PORTAL)
          .pipe(
            map((resp) => {
              return patientPortalSuccess({
                patientPortalData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  convertXlstoJson$ = createEffect(() =>
    this.action$.pipe(
      ofType(convertXlstoJson),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.CONVERT_XLS_TO_JSON)
          .pipe(
            map((signResp) => {
              return convertXlstoJsonSuccess({
                convertXlstoJsonData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  bulkAddPatients$ = createEffect(() =>
    this.action$.pipe(
      ofType(bulkAddPatients),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_BULK_PATIENTS)
          .pipe(
            map((signResp) => {
              return bulkAddPatientsSuccess({
                bulkAddPatientsData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  addPatientAttachment$ = createEffect(() =>
    this.action$.pipe(
      ofType(addPatientAttachment),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_PATIENT_ATTACHMENT)
          .pipe(
            map((signResp) => {
              return addPatientAttachmentSuccess({
                addPatientAttachmentData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getPatientAttachments$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPatientAttachments),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_ATTACHMENTS)
          .pipe(
            map((signResp) => {
              return getPatientAttachmentsSuccess({
                getPatientAttachmentsData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  GetPaitentGuarantor$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetPaitentGuarantor),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_GUARANTORS)
          .pipe(
            map((signResp) => {
              return GetPaitentGuarantorSuccess({
                GetPaitentGuarantorData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  AddPaitentGuarantor$ = createEffect(() =>
    this.action$.pipe(
      ofType(AddPaitentGuarantor),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_PATIENT_GUARANTOR)
          .pipe(
            map((signResp) => {
              return AddPaitentGuarantorSuccess({
                AddPaitentGuarantorData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  UpdatePaitentGuarantor$ = createEffect(() =>
    this.action$.pipe(
      ofType(UpdatePaitentGuarantor),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.UPDATE_PATIENT_GUARANTOR)
          .pipe(
            map((signResp) => {
              return UpdatePaitentGuarantorSuccess({
                UpdatePaitentGuarantorData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  GetPatientGuarantorById$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetPatientGuarantorById),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_GUARANTOR_BYID)
          .pipe(
            map((signResp) => {
              return GetPatientGuarantorByIdSuccess({
                GetPatientGuarantorByIdData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  GetPaitentGuarantorGl$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetPaitentGuarantorGl),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_GUARANTOR_GLS)
          .pipe(
            map((signResp) => {
              return GetPaitentGuarantorGlSuccess({
                GetPaitentGuarantorGlData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  AddPaitentGuarantorGl$ = createEffect(() =>
    this.action$.pipe(
      ofType(AddPaitentGuarantorGl),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_PATIENT_GUARANTOR_GL)
          .pipe(
            map((signResp) => {
              return AddPaitentGuarantorGlSuccess({
                AddPaitentGuarantorGlData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  UpdatePaitentGuarantorGl$ = createEffect(() =>
    this.action$.pipe(
      ofType(UpdatePaitentGuarantorGl),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.UPDATE_PATIENT_GUARANTOR_GL)
          .pipe(
            map((signResp) => {
              return UpdatePaitentGuarantorGlSuccess({
                UpdatePaitentGuarantorGlData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  DeletePatientGuarantorGlById$ = createEffect(() =>
    this.action$.pipe(
      ofType(DeletePatientGuarantorGlById),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.DELETE_PATIENT_GUARANTOR_GL)
          .pipe(
            map((signResp) => {
              return DeletePatientGuarantorGlByIdSuccess({
                DeletePatientGuarantorGlByIdData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  GetPatientGuarantorGlById$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetPatientGuarantorGlById),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            REGISTRAION_API_URL.GET_PATIENT_GUARANTOR_GL_BYID
          )
          .pipe(
            map((signResp) => {
              return GetPatientGuarantorGlByIdSuccess({
                GetPatientGuarantorGlByIdData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  GetPatientAuditLogs$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetPatientAuditLogs),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_AUDIT_LOGS)
          .pipe(
            map((signResp) => {
              return GetPatientAuditLogsSuccess({
                getPatientAuditLogsData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  GetPatientKins$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetPatientKins),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_KINS)
          .pipe(
            map((signResp) => {
              return GetPatientKinsSuccess({
                getPatientKinsData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  addPatientKins$ = createEffect(() =>
    this.action$.pipe(
      ofType(addPatientKins),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_PATIENT_KINS)
          .pipe(
            map((signResp) => {
              return addPatientKinsSuccess({
                addPatientKinsData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  GetPatientKin$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetPatientKin),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_KINS_BYID)
          .pipe(
            map((signResp) => {
              return GetPatientKinSuccess({
                getPatientKinData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  editPatientKin$ = createEffect(() =>
    this.action$.pipe(
      ofType(editPatientKin),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.UPDATE_PATIENT_KINS)
          .pipe(
            map((signResp) => {
              return editPatientKinSuccess({
                editPatientKinData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  manageFamilyLinks$ = createEffect(() =>
    this.action$.pipe(
      ofType(manageFamilyLinks),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.MANAGE_FAMILY_LINKS)
          .pipe(
            map((signResp) => {
              return manageFamilyLinksSuccess({
                manageFamilyLinksData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getFamilyLinks$ = createEffect(() =>
    this.action$.pipe(
      ofType(getFamilyLinks),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_FAMILY_LINKS)
          .pipe(
            map((signResp) => {
              return getFamilyLinksSuccess({
                getFamilyLinksData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  addPatientId$ = createEffect(() =>
    this.action$.pipe(
      ofType(addPatientId),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_PATIENT_IDENTITYS)
          .pipe(
            map((signResp) => {
              return addPatientIdSuccess({
                addPatientIdData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  editPatientId$ = createEffect(() =>
    this.action$.pipe(
      ofType(editPatientId),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.UPDATE_PATIENT_IDENTITYS)
          .pipe(
            map((signResp) => {
              return editPatientIdSuccess({
                editPatientIdData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getPatientId$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPatientId),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_IDENTITYS)
          .pipe(
            map((signResp) => {
              return getPatientIdSuccess({
                getPatientIdData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  managePatientId$ = createEffect(() =>
    this.action$.pipe(
      ofType(managePatientId),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.MANAGE_PATIENT_ID)
          .pipe(
            map((signResp) => {
              return managePatientIdSuccess({
                managePatientIdData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  addReferral$ = createEffect(() =>
    this.action$.pipe(
      ofType(addReferral),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_REFERRAL)
          .pipe(
            map((signResp) => {
              return addReferralSuccess({
                addReferralData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getEncounter$ = createEffect(() =>
    this.action$.pipe(
      ofType(getEncounter),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_ENCOUNTER)
          .pipe(
            map((signResp) => {
              return getEncounterSuccess({
                getEncounterData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getDefaultService$ = createEffect(() =>
    this.action$.pipe(
      ofType(getDefaultService),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_OP_DEFAULT_SERVICE)
          .pipe(
            map((signResp) => {
              return getDefaultServiceSuccess({
                getDefaultServiceData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getDoctorDefaultService$ = createEffect(() =>
    this.action$.pipe(
      ofType(getDoctorDefaultService),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_DOCTOR_DEFAULT_SERVICE)
          .pipe(
            map((signResp) => {
              return getDoctorDefaultServiceSuccess({
                getDoctorDefaultServiceData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getDoctorShare$ = createEffect(() =>
    this.action$.pipe(
      ofType(getDoctorShare),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_DOCTOR_SHARE)
          .pipe(
            map((signResp) => {
              return getDoctorShareSuccess({
                getDoctorShareData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getReferrals$ = createEffect(() =>
    this.action$.pipe(
      ofType(getReferrals),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_DEFAULT_REFERRALS)
          .pipe(
            map((signResp) => {
              return getReferralsSuccess({
                getReferralsData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  addRegisterWithBill$ = createEffect(() =>
    this.action$.pipe(
      ofType(addRegisterWithBill),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            REGISTRAION_API_URL.ADD_REGISTER_CUM_VISIT_WITH_BILL
          )
          .pipe(
            map((signResp) => {
              return addRegisterWithBillSuccess({
                addRegisterWithBillData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getFacilityDashboard$ = createEffect(() =>
    this.action$.pipe(
      ofType(getFacilityDashboard),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            REGISTRAION_API_URL.GET_FACILITY_DASHBOARD_OPTIONS
          )
          .pipe(
            map((signResp) => {
              return getFacilityDashboardSuccess({
                getFacilityDashboardData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getOpdDashboard$ = createEffect(() =>
    this.action$.pipe(
      ofType(getOpdDashboard),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_OP_DASHBOARD_OPTIONS)
          .pipe(
            map((signResp) => {
              return getOpdDashboardSuccess({
                getOpdDashboardData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  GetOtRegisters$ = createEffect(() =>
    this.action$.pipe(
      ofType(GetOtRegisters),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_OT_REGISTER)
          .pipe(
            map((signResp) => {
              return GetOtRegistersSuccess({
                GetOtRegistersData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getMrdRequest$ = createEffect(() =>
    this.action$.pipe(
      ofType(getMrdRequest),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_MRD_LOCATION)
          .pipe(
            map((signResp) => {
              return getMrdRequestSuccess({
                getMrdRequestData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addMrdRequest$ = createEffect(() =>
    this.action$.pipe(
      ofType(addMrdRequest),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_MRD_FILE)
          .pipe(
            map((signResp) => {
              return addMrdRequestSuccess({
                addMrdRequestData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addConsultantCheckoutPatient$ = createEffect(() =>
    this.action$.pipe(
      ofType(addConsultantCheckoutPatient),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            REGISTRAION_API_URL.CHECKOUT_CONSULTATION_PATIENT
          )
          .pipe(
            map((signResp) => {
              return addConsultantCheckoutPatientSuccess({
                addConsultantCheckoutPatientData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  CheckoutPatient$ = createEffect(() =>
    this.action$.pipe(
      ofType(CheckoutPatient),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.CHECKOUT_PATIENT)
          .pipe(
            map((signResp) => {
              return CheckoutPatientSuccess({
                CheckoutPatientData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  assignPatient$ = createEffect(() =>
    this.action$.pipe(
      ofType(assignPatient),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ASSIGN_PATIENT)
          .pipe(
            map((signResp) => {
              return assignPatientSuccess({
                assignPatientData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  mergePatient$ = createEffect(() =>
    this.action$.pipe(
      ofType(mergePatient),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.MERGE_PATIENTS)
          .pipe(
            map((signResp) => {
              return mergePatientSuccess({
                mergePatientData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  getPatientBills$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPatientBills),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_BILLS)
          .pipe(
            map((signResp) => {
              return getPatientBillsSuccess({
                getPatientBillsData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  //Patient FollowUP

  getPatientFollowup$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPatientFollowup),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_PATIENT_FOLLOWUPS)
          .pipe(
            map((signResp) => {
              return getPatientFollowupSuccess({
                getPatientFollowupData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addPatientFollowup$ = createEffect(() =>
    this.action$.pipe(
      ofType(addPatientFollowup),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_PATIENT_FOLLOWUP)
          .pipe(
            map((signResp) => {
              return addPatientFollowupSuccess({
                addPatientFollowupData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  updatePatientFollowup$ = createEffect(() =>
    this.action$.pipe(
      ofType(updatePatientFollowup),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.UPDATE_PATIENT_FOLLOWUP)
          .pipe(
            map((signResp) => {
              return updatePatientFollowupSuccess({
                updatePatientFollowupData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  viewPatientFollowup$ = createEffect(() =>
    this.action$.pipe(
      ofType(viewPatientFollowup),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.VIEW_PATIENT_FOLLOWUP)
          .pipe(
            map((signResp) => {
              return viewPatientFollowupSuccess({
                viewPatientFollowupData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  addOccupation$ = createEffect(() =>
    this.action$.pipe(
      ofType(addOccupation),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.ADD_OCCUPATION)
          .pipe(
            map((signResp) => {
              return addOccupationSuccess({
                addOccupationData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getOccupation$ = createEffect(() =>
    this.action$.pipe(
      ofType(getOccupation),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_OCCUPATION)
          .pipe(
            map((signResp) => {
              return getOccupationSuccess({
                getOccupationData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  getDiagnosiss$ = createEffect(() =>
    this.action$.pipe(
      ofType(getDiagnosiss),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, REGISTRAION_API_URL.GET_DIAGNOSISS)
          .pipe(
            map((signResp) => {
              return getDiagnosissSuccess({
                getDiagnosissData: signResp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  clearErrorMessage$ = createEffect(() =>
    this.action$.pipe(
      ofType(clearErrorMessage),
      map(() => {
        return clearErrorMessageSuccess();
      })
    )
  );
}
