import { createAction, props } from "@ngrx/store";

//Appointments
export const getAppoinmentList = createAction(
  "[Auth] get Appoinments",
  props<{ payload: any }>()
);

export const getAppoinmentListSuccess = createAction(
  "[Auth] get Appoinments Success",
  props<{ appoinmentListData: any }>()
);
//Doctor_Appointments
export const doctorAppointments = createAction(
  "[Auth] doctor Appointments",
  props<{ payload: any }>()
);
export const doctorAppointmentsSuccess = createAction(
  "[Auth] doctor Appointments Success",
  props<{ doctorAppointmentsData: any }>()
);

//Add_Appointment
export const addAppointment = createAction(
  "[Auth] add Appointment",
  props<{ payload: any }>()
);

export const addAppointmentSuccess = createAction(
  "[Auth] add Appointment Success",
  props<{ addAppointmentData: any }>()
);

//Get_Appointment_Multi_Sessions
export const getAppointmentMultiSessions = createAction(
  "[Auth] get Appointment Multi Sessions",
  props<{ payload: any }>()
);

export const getAppointmentMultiSessionsSuccess = createAction(
  "[Auth] get Appointment Multi Sessions Success",
  props<{ getAppointmentMultiSessionsData: any }>()
);

//Checkout_Patients
export const checkoutPatients = createAction(
  "[Auth] checkout Patients",
  props<{ payload: any }>()
);

export const checkoutPatientsSuccess = createAction(
  "[Auth] checkout Patients Success",
  props<{ checkoutPatientsData: any }>()
);

//Sessions
export const getAppoinmentSessionList = createAction(
  "[Auth] get Appoinment Session List",
  props<{ payload: any }>()
);

export const getAppoinmentSessionListSuccess = createAction(
  "[Auth] get Appoinment Session List Success",
  props<{ appoinmentSessionListData: any }>()
);

//Add_Sessions
export const addAppointmentSession = createAction(
  "[Auth] add Appoinment Session",
  props<{ payload: any }>()
);

export const addAppointmentSessionSuccess = createAction(
  "[Auth] add Appoinment Session Success",
  props<{ addAppointmentSessionData: any }>()
);

//Get_Appointment_Session_ById
export const getAppointmentSession = createAction(
  "[Auth] get Appoinment Session",
  props<{ payload: any }>()
);

export const getAppointmentSessionSuccess = createAction(
  "[Auth] get Appoinment Session Success",
  props<{ getAppointmentSessionData: any }>()
);

//Add_Appointment_Multi_Session
export const addAppointmentMultiSession = createAction(
  "[Auth] add Appoinment Multi Session",
  props<{ payload: any }>()
);

export const addAppointmentMultiSessionSuccess = createAction(
  "[Auth] add Appoinment Multi Session Success",
  props<{ addAppointmentMultiSessionData: any }>()
);

//Get_Appointment_Multi_Session_ById
export const getAppointmentMultiSession = createAction(
  "[Auth] get Appoinment Multi Session ById",
  props<{ payload: any }>()
);

export const getAppointmentMultiSessionSuccess = createAction(
  "[Auth] get Appoinment Multi Session ById Success",
  props<{ getAppointmentMultiSessionData: any }>()
);

//Edit_Appointment_Multi_Session
export const editAppointmentMultiSession = createAction(
  "[Auth] add Appoinment Multi Session",
  props<{ payload: any }>()
);

export const editAppointmentMultiSessionSuccess = createAction(
  "[Auth] add Appoinment Multi Session Success",
  props<{ editAppointmentMultiSessionData: any }>()
);

//Add_Appointment_Holidays
export const addappointmentHolidays = createAction(
  "[Auth] add Appoinment Holidays",
  props<{ payload: any }>()
);

export const addappointmentHolidaysSuccess = createAction(
  "[Auth] add Appoinment Holidays Success",
  props<{ addappointmentHolidaysData: any }>()
);

//Get_Appointment_Holidays
export const getappointmentHolidays = createAction(
  "[Auth] get Appoinment Holidays",
  props<{ payload: any }>()
);

export const getappointmentHolidaysSuccess = createAction(
  "[Auth] get Appoinment Holidays Success",
  props<{ getappointmentHolidaysData: any }>()
);

//Requests
export const getAppoinmentRequestList = createAction(
  "[Auth] get Appoinment Request",
  props<{ payload: any }>()
);

export const getAppoinmentRequestListSuccess = createAction(
  "[Auth] get Appoinment Request Success",
  props<{ appoinmentRequestListData: any }>()
);

//Categories
export const getAppoinmentCategoryList = createAction(
  "[Auth] get Appoinment Categories",
  props<{ payload: any }>()
);

export const getAppoinmentCategoryListSuccess = createAction(
  "[Auth] get Appoinment Categories Success",
  props<{ appoinmentCategoryListData: any }>()
);

//Add_Categories
export const addAppointmentCatogory = createAction(
  "[Auth] add Appoinment Catogory",
  props<{ payload: any }>()
);

export const addAppointmentCatogorySuccess = createAction(
  "[Auth] add Appoinment Catogory Success",
  props<{ addAppointmentCatogoryData: any }>()
);

//Add_Categories
export const updateAppointmentCatogory = createAction(
  "[Auth] update Appoinment Catogory",
  props<{ payload: any }>()
);

export const updateAppointmentCatogorySuccess = createAction(
  "[Auth] update Appoinment Catogory Success",
  props<{ updateAppointmentCatogoryData: any }>()
);//Add_Categories
export const getByIdAppointmentCatogory = createAction(
  "[Auth] getById Appoinment Catogory",
  props<{ payload: any }>()
);

export const getByIdAppointmentCatogorySuccess = createAction(
  "[Auth] getById Appoinment Catogory Success",
  props<{ getByIdAppointmentCatogoryData: any }>()
);

export const errorMessage = createAction(
  "[Auth] error Message",
  props<{ errorData: any }>()
);
export const clearErrorMessage = createAction(
  "[Auth] clear Error Message",
  props<{ payload: string }>()
);
export const clearErrorMessageSuccess = createAction(
  "[Auth] clear Error Message Success"
);
