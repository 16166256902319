import { createAction, props } from "@ngrx/store";

export const getTodayInformation = createAction(
  " [dashboard] get Today Information",
  props<{ payload: any }>()
);

export const getTodayInformationSuccess = createAction(
  " [dashboard] get Today Information Success",
  props<{ todayInfo: any }>()
);

export const getWardInformation = createAction(
  " [dashboard] get Ward Information",
  props<{ payload: any }>()
);

export const getWardInformationSuccess = createAction(
  " [dashboard] get Ward Information Success",
  props<{ wardInfo: any }>()
);

export const logoutSuccess = createAction("[Auth] logout Success");
