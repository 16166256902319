import { createReducer, Action, on } from "@ngrx/store";
import {
  addConsultantCheckoutPatient,
  addConsultantCheckoutPatientSuccess,
  addMrdRequest,
  addMrdRequestSuccess,
  addOccupation,
  addOccupationSuccess,
  AddPaitentGuarantor,
  AddPaitentGuarantorGl,
  AddPaitentGuarantorGlSuccess,
  AddPaitentGuarantorSuccess,
  addPatient,
  addPatientAttachment,
  addPatientAttachmentSuccess,
  addPatientFollowup,
  addPatientFollowupSuccess,
  addPatientId,
  addPatientIdSuccess,
  addPatientKins,
  addPatientKinsSuccess,
  addPatientSuccess,
  addReferral,
  addReferralSuccess,
  addRegisterCumVisit,
  addRegisterCumVisitSuccess,
  addRegisterWithBill,
  addRegisterWithBillSuccess,
  assignPatient,
  assignPatientSuccess,
  bulkAddPatients,
  bulkAddPatientsSuccess,
  CheckoutPatient,
  CheckoutPatientSuccess,
  clearErrorMessage,
  clearErrorMessageSuccess,
  convertXlstoJson,
  convertXlstoJsonSuccess,
  DeletePatientGuarantorGlById,
  DeletePatientGuarantorGlByIdSuccess,
  editPatientId,
  editPatientIdSuccess,
  editPatientKin,
  editPatientKinSuccess,
  errorMessage,
  getDefaultService,
  getDefaultServiceSuccess,
  getDiagnosiss,
  getDiagnosissSuccess,
  getDoctorDefaultService,
  getDoctorDefaultServiceSuccess,
  getDoctorShare,
  getDoctorShareSuccess,
  getEncounter,
  getEncounterSuccess,
  getFacilityDashboard,
  getFacilityDashboardSuccess,
  getFamilyLinks,
  getFamilyLinksSuccess,
  getMrdRequest,
  getMrdRequestSuccess,
  getOccupation,
  getOccupationSuccess,
  getOpdDashboard,
  getOpdDashboardSuccess,
  GetOtRegisters,
  GetOtRegistersSuccess,
  GetPaitentGuarantor,
  GetPaitentGuarantorGl,
  GetPaitentGuarantorGlSuccess,
  GetPaitentGuarantorSuccess,
  getPatientAttachments,
  getPatientAttachmentsSuccess,
  GetPatientAuditLogs,
  GetPatientAuditLogsSuccess,
  getPatientBills,
  getPatientBillsSuccess,
  getPatientById,
  getPatientByIdSuccess,
  getPatientFollowup,
  getPatientFollowupSuccess,
  GetPatientGuarantorById,
  GetPatientGuarantorByIdSuccess,
  GetPatientGuarantorGlById,
  GetPatientGuarantorGlByIdSuccess,
  getPatientId,
  getPatientIdSuccess,
  GetPatientKin,
  GetPatientKins,
  GetPatientKinsSuccess,
  GetPatientKinSuccess,
  getPatientProfilePic,
  getPatientProfilePicSuccess,
  getPatientSearch,
  getPatientSearchSuccess,
  getReferrals,
  getReferralsSuccess,
  manageFamilyLinks,
  manageFamilyLinksSuccess,
  managePatientId,
  managePatientIdSuccess,
  mergePatient,
  mergePatientSuccess,
  patientPortal,
  patientPortalSuccess,
  UpdatePaitentGuarantor,
  UpdatePaitentGuarantorGl,
  UpdatePaitentGuarantorGlSuccess,
  UpdatePaitentGuarantorSuccess,
  updatePatient,
  updatePatientFollowup,
  updatePatientFollowupSuccess,
  updatePatientSuccess,
  viewPatientFollowup,
  viewPatientFollowupSuccess,
} from "./actions";
export interface RegistrationState {
  updatePatientFollowupData: any;
  viewPatientFollowupData: any;
  addPatientFollowupData: any;
  getPatientFollowupData: any;
  mergePatientData: any;
  addMrdRequestData: any;
  assignPatientData: any;
  CheckoutPatientData: any;
  addConsultantCheckoutPatientData: any;
  getMrdRequestData: any;
  getOpdDashboardData: any;
  GetOtRegistersData: any;
  getFacilityDashboardData: any;
  addRegisterCumVisitData: any;
  getPatientIdData: any;
  addPatientIdData: any;
  DeletePatientGuarantorGlByIdData: any;
  GetPatientGuarantorGlByIdData: any;
  GetPaitentGuarantorGlData: any;
  UpdatePaitentGuarantorGlData: any;
  AddPaitentGuarantorGlData: any;
  GetPatientGuarantorByIdData: any;
  GetPaitentGuarantorData: any;
  UpdatePaitentGuarantorData: any;
  AddPaitentGuarantorData: any;
  getPatientAttachmentsData: any;
  addPatientAttachmentData: any;
  updatePatientData: any;
  patientPortalData: any;
  getPatientByIdData: any;
  bulkAddPatientsData: any;
  convertXlstoJsonData: any;
  patientProfilePicData: any;
  patientSearchData: any;
  errorData: any;
  addPatientData: any;
  getPatientAuditLogsData: any;
  getPatientKinsData: any;
  addPatientKinsData: any;
  getPatientKinData: any;
  editPatientKinData: any;
  manageFamilyLinksData: any;
  getFamilyLinksData: any;
  addReferralData: any;
  getEncounterData: any;
  getDefaultServiceData: any;
  getDoctorDefaultServiceData: any;
  getDoctorShareData: any;
  getReferralsData: any;
  addRegisterWithBillData: any;
  getPatientBillsData: any;
  managePatientIdData: any;
  editPatientIdData: any;
  addOccupationData: any;
  getOccupationData: any;
  getDiagnosissData: any;
}

export const initialState: RegistrationState = {
  patientSearchData: undefined,
  errorData: undefined,
  addPatientData: undefined,
  patientProfilePicData: undefined,
  bulkAddPatientsData: undefined,
  convertXlstoJsonData: undefined,
  getPatientByIdData: undefined,
  patientPortalData: undefined,
  updatePatientData: undefined,
  getPatientAttachmentsData: undefined,
  addPatientAttachmentData: undefined,
  getPatientAuditLogsData: undefined,
  getPatientKinsData: undefined,
  DeletePatientGuarantorGlByIdData: undefined,
  GetPatientGuarantorGlByIdData: undefined,
  GetPaitentGuarantorGlData: undefined,
  UpdatePaitentGuarantorGlData: undefined,
  AddPaitentGuarantorGlData: undefined,
  GetPatientGuarantorByIdData: undefined,
  GetPaitentGuarantorData: undefined,
  UpdatePaitentGuarantorData: undefined,
  AddPaitentGuarantorData: undefined,
  addPatientKinsData: undefined,
  getPatientKinData: undefined,
  editPatientKinData: undefined,
  manageFamilyLinksData: undefined,
  getFamilyLinksData: undefined,
  addReferralData: undefined,
  getEncounterData: undefined,
  getDefaultServiceData: undefined,
  getDoctorDefaultServiceData: undefined,
  getDoctorShareData: undefined,
  getPatientIdData: undefined,
  addPatientIdData: undefined,
  addRegisterCumVisitData: undefined,
  getReferralsData: undefined,
  addRegisterWithBillData: undefined,
  getOpdDashboardData: undefined,
  GetOtRegistersData: undefined,
  getFacilityDashboardData: undefined,
  getMrdRequestData: undefined,
  getPatientBillsData: undefined,
  addConsultantCheckoutPatientData: undefined,
  assignPatientData: undefined,
  CheckoutPatientData: undefined,
  addMrdRequestData: undefined,
  managePatientIdData: undefined,
  editPatientIdData: undefined,
  mergePatientData: undefined,
  updatePatientFollowupData: undefined,
  viewPatientFollowupData: undefined,
  addPatientFollowupData: undefined,
  getPatientFollowupData: undefined,
  addOccupationData: undefined,
  getOccupationData: undefined,
  getDiagnosissData: undefined
};

const _reducer = createReducer(
  initialState,
  on(getPatientSearch, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPatientSearchSuccess, (state, { patientSearchData }) => ({
    ...state,
    patientSearchData,
    errorData: null,
  })),
  on(getPatientProfilePic, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPatientProfilePicSuccess, (state, { patientProfilePicData }) => ({
    ...state,
    patientProfilePicData,
    errorData: null,
  })),
  on(addPatient, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addPatientSuccess, (state, { addPatientData }) => ({
    ...state,
    addPatientData,
    errorData: null,
  })),
  on(addRegisterCumVisit, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addRegisterCumVisitSuccess, (state, { addRegisterCumVisitData }) => ({
    ...state,
    addRegisterCumVisitData,
    errorData: null,
  })),
  on(updatePatient, (state) => ({
    ...state,
    errorData: null,
  })),
  on(updatePatientSuccess, (state, { updatePatientData }) => ({
    ...state,
    updatePatientData,
    errorData: null,
  })),
  on(convertXlstoJson, (state) => ({
    ...state,
    errorData: null,
  })),
  on(convertXlstoJsonSuccess, (state, { convertXlstoJsonData }) => ({
    ...state,
    convertXlstoJsonData,
    errorData: null,
  })),
  on(bulkAddPatients, (state) => ({
    ...state,
    errorData: null,
  })),
  on(bulkAddPatientsSuccess, (state, { bulkAddPatientsData }) => ({
    ...state,
    bulkAddPatientsData,
    errorData: null,
  })),
  on(getPatientById, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPatientByIdSuccess, (state, { getPatientByIdData }) => ({
    ...state,
    getPatientByIdData,
    errorData: null,
  })),
  on(patientPortal, (state) => ({
    ...state,
    errorData: null,
  })),
  on(patientPortalSuccess, (state, { patientPortalData }) => ({
    ...state,
    patientPortalData,
    errorData: null,
  })),
  on(addPatientAttachment, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addPatientAttachmentSuccess, (state, { addPatientAttachmentData }) => ({
    ...state,
    addPatientAttachmentData,
    errorData: null,
  })),
  on(getPatientAttachments, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPatientAttachmentsSuccess, (state, { getPatientAttachmentsData }) => ({
    ...state,
    getPatientAttachmentsData,
    errorData: null,
  })),
  on(AddPaitentGuarantor, (state) => ({
    ...state,
    errorData: null,
  })),
  on(AddPaitentGuarantorSuccess, (state, { AddPaitentGuarantorData }) => ({
    ...state,
    AddPaitentGuarantorData,
    errorData: null,
  })),
  on(UpdatePaitentGuarantor, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    UpdatePaitentGuarantorSuccess,
    (state, { UpdatePaitentGuarantorData }) => ({
      ...state,
      UpdatePaitentGuarantorData,
      errorData: null,
    })
  ),
  on(GetPaitentGuarantor, (state) => ({
    ...state,
    errorData: null,
  })),
  on(GetPaitentGuarantorSuccess, (state, { GetPaitentGuarantorData }) => ({
    ...state,
    GetPaitentGuarantorData,
    errorData: null,
  })),
  on(GetPatientGuarantorById, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    GetPatientGuarantorByIdSuccess,
    (state, { GetPatientGuarantorByIdData }) => ({
      ...state,
      GetPatientGuarantorByIdData,
      errorData: null,
    })
  ),
  on(AddPaitentGuarantorGl, (state) => ({
    ...state,
    errorData: null,
  })),
  on(AddPaitentGuarantorGlSuccess, (state, { AddPaitentGuarantorGlData }) => ({
    ...state,
    AddPaitentGuarantorGlData,
    errorData: null,
  })),
  on(UpdatePaitentGuarantorGl, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    UpdatePaitentGuarantorGlSuccess,
    (state, { UpdatePaitentGuarantorGlData }) => ({
      ...state,
      UpdatePaitentGuarantorGlData,
      errorData: null,
    })
  ),
  on(GetPaitentGuarantorGl, (state) => ({
    ...state,
    errorData: null,
  })),
  on(GetPaitentGuarantorGlSuccess, (state, { GetPaitentGuarantorGlData }) => ({
    ...state,
    GetPaitentGuarantorGlData,
    errorData: null,
  })),
  on(GetPatientGuarantorGlById, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    GetPatientGuarantorGlByIdSuccess,
    (state, { GetPatientGuarantorGlByIdData }) => ({
      ...state,
      GetPatientGuarantorGlByIdData,
      errorData: null,
    })
  ),
  on(DeletePatientGuarantorGlById, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    DeletePatientGuarantorGlByIdSuccess,
    (state, { DeletePatientGuarantorGlByIdData }) => ({
      ...state,
      DeletePatientGuarantorGlByIdData,
      errorData: null,
    })
  ),
  on(GetPatientAuditLogs, (state) => ({
    ...state,
    errorData: null,
  })),
  on(GetPatientAuditLogsSuccess, (state, { getPatientAuditLogsData }) => ({
    ...state,
    getPatientAuditLogsData,
    errorData: null,
  })),
  on(GetPatientKins, (state) => ({
    ...state,
    errorData: null,
  })),
  on(GetPatientKinsSuccess, (state, { getPatientKinsData }) => ({
    ...state,
    getPatientKinsData,
    errorData: null,
  })),
  on(addPatientKins, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addPatientKinsSuccess, (state, { addPatientKinsData }) => ({
    ...state,
    addPatientKinsData,
    errorData: null,
  })),
  on(GetPatientKin, (state) => ({
    ...state,
    errorData: null,
  })),
  on(GetPatientKinSuccess, (state, { getPatientKinData }) => ({
    ...state,
    getPatientKinData,
    errorData: null,
  })),
  on(editPatientKin, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editPatientKinSuccess, (state, { editPatientKinData }) => ({
    ...state,
    editPatientKinData,
    errorData: null,
  })),
  on(manageFamilyLinks, (state) => ({
    ...state,
    errorData: null,
  })),
  on(manageFamilyLinksSuccess, (state, { manageFamilyLinksData }) => ({
    ...state,
    manageFamilyLinksData,
    errorData: null,
  })),
  on(getFamilyLinks, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getFamilyLinksSuccess, (state, { getFamilyLinksData }) => ({
    ...state,
    getFamilyLinksData,
    errorData: null,
  })),
  on(addPatientId, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addPatientIdSuccess, (state, { addPatientIdData }) => ({
    ...state,
    addPatientIdData,
    errorData: null,
  })),
  on(editPatientId, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editPatientIdSuccess, (state, { editPatientIdData }) => ({
    ...state,
    editPatientIdData,
    errorData: null,
  })),
  on(getPatientId, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPatientIdSuccess, (state, { getPatientIdData }) => ({
    ...state,
    getPatientIdData,
    errorData: null,
  })),
  on(managePatientId, (state) => ({
    ...state,
    errorData: null,
  })),
  on(managePatientIdSuccess, (state, { managePatientIdData }) => ({
    ...state,
    managePatientIdData,
    errorData: null,
  })),
  on(addReferral, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addReferralSuccess, (state, { addReferralData }) => ({
    ...state,
    addReferralData,
    errorData: null,
  })),
  on(getEncounter, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getEncounterSuccess, (state, { getEncounterData }) => ({
    ...state,
    getEncounterData,
    errorData: null,
  })),
  on(getDefaultService, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getDefaultServiceSuccess, (state, { getDefaultServiceData }) => ({
    ...state,
    getDefaultServiceData,
    errorData: null,
  })),
  on(getDoctorDefaultService, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    getDoctorDefaultServiceSuccess,
    (state, { getDoctorDefaultServiceData }) => ({
      ...state,
      getDoctorDefaultServiceData,
      errorData: null,
    })
  ),
  on(getDoctorShare, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getDoctorShareSuccess, (state, { getDoctorShareData }) => ({
    ...state,
    getDoctorShareData,
    errorData: null,
  })),
  on(getReferrals, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getReferralsSuccess, (state, { getReferralsData }) => ({
    ...state,
    getReferralsData,
    errorData: null,
  })),
  on(addRegisterWithBill, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addRegisterWithBillSuccess, (state, { addRegisterWithBillData }) => ({
    ...state,
    addRegisterWithBillData,
    errorData: null,
  })),
  on(getFacilityDashboard, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getFacilityDashboardSuccess, (state, { getFacilityDashboardData }) => ({
    ...state,
    getFacilityDashboardData,
    errorData: null,
  })),
  on(GetOtRegisters, (state) => ({
    ...state,
    errorData: null,
  })),
  on(GetOtRegistersSuccess, (state, { GetOtRegistersData }) => ({
    ...state,
    GetOtRegistersData,
    errorData: null,
  })),
  on(getOpdDashboard, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getOpdDashboardSuccess, (state, { getOpdDashboardData }) => ({
    ...state,
    getOpdDashboardData,
    errorData: null,
  })),
  on(getMrdRequest, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getMrdRequestSuccess, (state, { getMrdRequestData }) => ({
    ...state,
    getMrdRequestData,
    errorData: null,
  })),
  on(addMrdRequest, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addMrdRequestSuccess, (state, { addMrdRequestData }) => ({
    ...state,
    addMrdRequestData,
    errorData: null,
  })),
  on(addConsultantCheckoutPatient, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addConsultantCheckoutPatientSuccess, (state, { addConsultantCheckoutPatientData }) => ({
    ...state,
    addConsultantCheckoutPatientData,
    errorData: null,
  })),
  on(CheckoutPatient, (state) => ({
    ...state,
    errorData: null,
  })),
  on(CheckoutPatientSuccess, (state, { CheckoutPatientData }) => ({
    ...state,
    CheckoutPatientData,
    errorData: null,
  })),
  on(assignPatient, (state) => ({
    ...state,
    errorData: null,
  })),
  on(assignPatientSuccess, (state, { assignPatientData }) => ({
    ...state,
    assignPatientData,
    errorData: null,
  })),
  on(mergePatient, (state) => ({
    ...state,
    errorData: null,
  })),
  on(mergePatientSuccess, (state, { mergePatientData }) => ({
    ...state,
    mergePatientData,
    errorData: null,
  })),
  on(getPatientBills, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPatientBillsSuccess, (state, { getPatientBillsData }) => ({
    ...state,
    getPatientBillsData,
    errorData: null,
  })),
  //Patient_followup
  on(getPatientFollowup, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPatientFollowupSuccess, (state, { getPatientFollowupData }) => ({
    ...state,
    getPatientFollowupData,
    errorData: null,
  })),
  on(addPatientFollowup, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addPatientFollowupSuccess, (state, { addPatientFollowupData }) => ({
    ...state,
    addPatientFollowupData,
    errorData: null,
  })),
  on(updatePatientFollowup, (state) => ({
    ...state,
    errorData: null,
  })),
  on(updatePatientFollowupSuccess, (state, { updatePatientFollowupData }) => ({
    ...state,
    updatePatientFollowupData,
    errorData: null,
  })),
  on(addOccupation, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addOccupationSuccess, (state, { addOccupationData }) => ({
    ...state,
    addOccupationData,
    errorData: null,
  })),
  on(getOccupation, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getOccupationSuccess, (state, { getOccupationData }) => ({
    ...state,
    getOccupationData,
    errorData: null,
  })),
  on(getDiagnosiss, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getDiagnosissSuccess, (state, { getDiagnosissData }) => ({
    ...state,
    getDiagnosissData,
    errorData: null,
  })),
  on(viewPatientFollowup, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewPatientFollowupSuccess, (state, { viewPatientFollowupData }) => ({
    ...state,
    viewPatientFollowupData,
    errorData: null,
  })),
  on(errorMessage, (state, { errorData }) => ({
    ...state,
    errorData,
  })),
  on(clearErrorMessage, (state) => ({
    ...state,
    errorData: null,
  })),
  on(clearErrorMessageSuccess, (state) => ({
    ...state,
    errorData: null,
  }))
);

export function registrationReducer(
  state: RegistrationState | undefined,
  action: Action
) {
  return _reducer(state, action);
}
