import { createReducer, Action, on } from "@ngrx/store";
import {
  getControl,
  getControlSuccess,
  getPatientAlert,
  getPatientAlertSuccess,
  getUser,
  getUserRole,
  getUserRoleSuccess,
  getUserSuccess,
  login,
  loginFailure,
  loginSuccess,
  logout,
  logoutSuccess,
  setAccessToken,
  setAccessTokenSuccess,
} from "./actions";
export interface AuthState {
  userRoleData: any;
  errorData: any;
  accessToken: string;
  refreshToken: string;
  userId: number;
  userDetails: any;
  controls: any;
  patientAlertData: any;
  controlCompleted: boolean;
}

export const initialState: AuthState = {
  errorData: null,
  accessToken: "",
  refreshToken: "",
  userId: 0,
  userDetails: undefined,
  controls: undefined,
  controlCompleted: false,
  patientAlertData: undefined,
  userRoleData: undefined,
};

const _reducer = createReducer(
  initialState,
  on(login, (state) => ({
    ...state,
    errorData: null,
  })),
  on(loginSuccess, (state, { accessToken, refreshToken, userId }) => ({
    ...state,
    accessToken,
    refreshToken,
    userId,
    isAuthenticated: true,
    errorData: null,
  })),
  on(loginFailure, (state, { error }) => ({
    ...state,
    isAuthenticated: false,
    errorData: error,
  })),
  on(getUser, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getUserSuccess, (state, { userDetails }) => ({
    ...state,
    isAuthenticated: false,
    userDetails,
    errorData: null,
  })),
  on(getPatientAlert, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPatientAlertSuccess, (state, { patientAlertData }) => ({
    ...state,
    patientAlertData,

    errorData: null,
  })),
  on(getControl, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getControlSuccess, (state, { controls }) => ({
    ...state,
    controls,
    controlCompleted: true,
    errorData: null,
  })),

  on(getUserRole, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getUserRoleSuccess, (state, { userRoleData }) => ({
    ...state,
    userRoleData,
    errorData: null,
  })),
  on(setAccessToken, (state) => ({
    ...state,

    errorData: null,
  })),
  on(setAccessTokenSuccess, (state, { accessToken }) => ({
    ...state,
    accessToken,
    errorData: null,
  })),
  on(logout, (state) => ({
    ...state,
  })),
  on(logoutSuccess, (state) => ({
    ...state,
    controlCompleted: false,
    accessToken: "",
    refreshToken: "",
    userDetails: undefined,
    userId: 0,
  }))
);

export function authReducer(state: AuthState | undefined, action: Action) {
  return _reducer(state, action);
}
