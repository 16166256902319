export const REGISTRAION_API_URL = {
  GET_PATIENTS: "/api/APIregistration/patient/GetPatients",
  PATIENT_SEARCH: "/api/APIregistration/patient/GetPatientSearchList",
  GET_PATIENT_PROFILE_PIC: "/api/APIregistration/patient/GetPatientProfilePic",
  ADD_PATIENT: "/api/APIRegistration/patient/AddPatient",
  UPDATE_PATIENT: "/api/APIRegistration/patient/UpdatePatient",
  GET_PATIENT_BY_ID: "/api/APIregistration/patient/GetPatientById",
  ADD_PATIENT_PORTAL: "/api/APIAppmanager/User/AddUser",
  PRINT_PATIENT: "/api/APIregistration/Patient/PrintPatient",
  PRINT_PATIENT_LABEL: "/api/APIregistration/Patient/PrintPatientLabel",
  ADD_PATIENT_ATTACHMENT:
    "/api/APIregistration/PatientAttachment/AddPatientAttachment",
  GET_PATIENT_ATTACHMENTS:
    "/api/APIregistration/PatientAttachment/GetPatientAttachments",
  GET_ATTACHMENT_FILE:
    "/api/APIregistration/PatientAttachment/GetAttachmentFile",
  DELETE_ATTACHMENT:
    "/api/APIregistration/PatientAttachment/DeletePatientAttachment",
  GET_PATIENT_AUDIT_LOGS:
    "/api/APIregistration/PatientAuditLog/GetPatientAuditLogs",
  GET_PATIENT_KINS: "/api/APIregistration/PatientKin/GetPatientKins",
  ADD_PATIENT_KINS: "/api/APIregistration/patientkin/AddPatientKin",
  GET_PATIENT_KINS_BYID: "/api/APIregistration/patientkin/GetPatientKinById",
  UPDATE_PATIENT_KINS: "/api/APIregistration/patientkin/UpdatePatientKin",
  DELETE_PATIENT_KINS: "/api/APIregistration/PatientKin/DeletePatientKin",
  
  MANAGE_FAMILY_LINKS: "/api/APIregistration/FamilyLink/ManageFamilyLinks",
  GET_FAMILY_LINKS: "/api/APIregistration/FamilyLink/GetFamilyLinks",
  //Bulk Registration
  CONVERT_XLS_TO_JSON: "/api/APIAttendance/TimeManagement/ConvertXLtoJSON",
  ADD_BULK_PATIENTS: "/api/APIregistration/patient/AddPatients",
  //PatientId
  ADD_PATIENT_IDENTITYS: "/api/APIregistration/PatientIdentity/AddPatientIdentity",
  UPDATE_PATIENT_IDENTITYS:
    "/api/APIregistration/PatientIdentity/UpdatePatientIdentity",
  GET_PATIENT_IDENTITYS: "/api/APIregistration/PatientIdentity/GetPatientIdentitys",
  MANAGE_PATIENT_ID:
    "/api/APIregistration/PatientIdentity/ManagePatientIdentities",
  GET_PATIENT_ID_DOCS: "/api/APIregistration/PatientIdentity/GetPatientIdDocs",
  //Full_Reg Guarantor
  ADD_PATIENT_GUARANTOR:
    "/api/APIregistration/PatientGuarantor/AddPatientGuarantor",
  UPDATE_PATIENT_GUARANTOR:
    "/api/APIregistration/PatientGuarantor/UpdatePatientGuarantor",
  GET_PATIENT_GUARANTOR_BYID:
    "/api/APIregistration/PatientGuarantor/GetPatientGuarantorById",
  GET_PATIENT_GUARANTORS:
    "/api/APIregistration/PatientGuarantor/GetPatientGuarantors",
  ADD_PATIENT_GUARANTOR_GL:
    "/api/APIregistration/patientguarantorgl/AddPatientGuarantorGL",
  GET_PATIENT_GUARANTOR_GLS:
    "/api/APIregistration/patientguarantorgl/GetPatientGuarantorGLs",
  GET_PATIENT_GUARANTOR_GL_BYID:
    "/api/APIregistration/patientguarantorgl/GetPatientGuarantorGLById",
  UPDATE_PATIENT_GUARANTOR_GL:
    "/api/APIregistration/patientguarantorgl/UpdatePatientGuarantorGL",
  DELETE_PATIENT_GUARANTOR_GL:
    "/api/APIregistration/patientguarantorgl/DeletePatientGuarantorGL",
  GET_GUARANTOR_CUSTOMERS:
    "/api/APIgeneralmaster/GuarantorCustomer/GetGuarantorCustomers",
  ADD_REFERRAL: "/api/APIGeneralMaster/referral/AddReferral",
  ADD_OCCUPATION: "/api/APIGeneralMaster/Occupation/AddOccupation",
  GET_OCCUPATION: "/api/APIGeneralMaster/Occupation/GetOccupations",
  //Registration_With_Bill
  GET_ENCOUNTER: "/api/APIEncounter/Encounter/GetEncounters",
  GET_OP_DEFAULT_SERVICE: "/api/APIEncounter/Encounter/GetOPDefaultServices",
  GET_DOCTOR_DEFAULT_SERVICE:
    "/api/APIappmanager/userdefaultservice/GetDoctorDefaultServices",
  GET_DOCTOR_SHARE: "/api/APIBill/doctorshare/GetDoctorShare",
  ADD_REGISTER_CUM_VISIT_WITH_BILL:
    "/api/APIregistration/patient/RegCumVisitWithBill",
  GET_PATIENT_BILLS: "/api/APIbilling/patientbills/GetPatientBills",
  PRINT_PATIENT_BILLS: "/api/APIBill/patientbills/PrintPatientBills",
  GET_DIAGNOSISS: "/api/APIclinicalmaster/diagnosis/GetDiagnosiss",
  //RegisterCumVisit
  GET_DEFAULT_REFERRALS: "/api/APIgeneralmaster/referral/GetReferrals",
  ADD_REGISTER_CUM_VIIST: "/api/APIregistration/patient/RegistrationCumVisit",
  CUM_VISIT_REGISTRATION_FORM: "/api/APIregistration/Patient/regprintform",
  //OPD Dashboard
  GET_FACILITY_DASHBOARD_OPTIONS:
    "/api/APIAppmanager/facilitydashboard/GetFacilityDashboardOptions",
  GET_OT_REGISTER: "/api/APIOTManagement/otregister/GetOtRegisters",
  GET_OP_DASHBOARD_OPTIONS:
    "/api/APIregistration/OPDDashboard/GetOPDDashboardOptions",
  //MRD_REQUEST
  GET_MRD_LOCATION: "/api/APIinpatient/MRDLocation/GetMRDLocations",
  ADD_MRD_FILE: "/api/APIinpatient/filerequest/AddFileRequest",
  //PRINT_PATIENT:
  PRINT_PATIENT_ENCOUNTER:
    "/api/API/registration/Patient/PrintPatientWithEncounter",
  VISIT_SLIP: "/api/APIappointment/Appointment/PrintAppointment",

  //CHECKOUT_API
  CHECKOUT_CONSULTATION_PATIENT:
    "/api/APIappointment/patienttracker/CheckoutConsultationPatient",
  CHECKOUT_PATIENT: "/api/APIappointment/patienttracker/CheckoutPatient",
  ASSIGN_PATIENT: "/api/APIappointment/patienttracker/AssignPatient",
  //Patient_Merge
  MERGE_PATIENTS: "/api/APIregistration/patientmerge/ManagePatientMerge",
  //Patient_FollowUP
  GET_PATIENT_FOLLOWUPS: "/api/APIregistration/patientfollowup/GetPatientFollowups",
  ADD_PATIENT_FOLLOWUP:"/api/APIregistration/patientfollowup/Addpatientfollowup",
  VIEW_PATIENT_FOLLOWUP: "/api/APIregistration/patientfollowup/GetPatientFollowupById",
  UPDATE_PATIENT_FOLLOWUP: "/api/APIregistration/PatientFollowup/Updatepatientfollowup",
  DELETE_PATIENT_FOLLOWUP: "/api/APIregistration/PatientFollowup/DeletePatientFollowup"
};
