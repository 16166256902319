import { inject, Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import {
  getControl,
  getControlSuccess,
  getPatientAlert,
  getPatientAlertSuccess,
  getUser,
  getUserRole,
  getUserRoleSuccess,
  getUserSuccess,
  login,
  loginFailure,
  loginSuccess,
  logout,
  logoutSuccess,
  setAccessToken,
  setAccessTokenSuccess,
} from "./actions";
import { catchError, map, mergeMap, of, tap } from "rxjs";
import { Router } from "@angular/router";
import { CommonService } from "@services/common.service";
import { AUTH_API_URL } from "@core/api";

@Injectable()
export class AuthEffects {
  private action$ = inject(Actions);
  constructor(private commonService: CommonService, private router: Router) {}

  login$ = createEffect(() =>
    this.action$.pipe(
      ofType(login),
      mergeMap((action) =>
        this.commonService.post(action.payload, AUTH_API_URL.LOGIN).pipe(
          map((loginResp) => {
            return loginSuccess({
              accessToken: loginResp?.accessToken,
              refreshToken: loginResp?.refreshToken,
              userId: loginResp?.UserId,
            });
          }),
          catchError((error) => {
            return of(loginFailure({ error }));
          })
        )
      )
    )
  );

  getUser$ = createEffect(() =>
    this.action$.pipe(
      ofType(getUser),
      mergeMap((action) =>
        this.commonService.post(action.payload, AUTH_API_URL.GET_USER).pipe(
          map((userResp) => {
            return getUserSuccess({ userDetails: userResp });
          })
        )
      )
    )
  );

  getPatientAlert$ = createEffect(() =>
    this.action$.pipe(
      ofType(getPatientAlert),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, AUTH_API_URL.GET_PATIENT_ALERT)
          .pipe(
            map((patientResp) => {
              return getPatientAlertSuccess({ patientAlertData: patientResp });
            })
          )
      )
    )
  );

  getControl$ = createEffect(() =>
    this.action$.pipe(
      ofType(getControl),
      mergeMap((action) =>
        this.commonService.post(action.payload, AUTH_API_URL.GET_CONTROL).pipe(
          map((controlResp) => {
            return getControlSuccess({ controls: controlResp });
          })
        )
      )
    )
  );

  getUserRole$ = createEffect(() =>
    this.action$.pipe(
      ofType(getUserRole),
      mergeMap((action) =>
        this.commonService.post(action.payload, AUTH_API_URL.USER_ROLE).pipe(
          map((userRoleResp) => {
            return getUserRoleSuccess({ userRoleData: userRoleResp });
          })
        )
      )
    )
  );

  setAccessToken$ = createEffect(() =>
    this.action$.pipe(
      ofType(setAccessToken),
      map((action) => {
        return setAccessTokenSuccess({ accessToken: action.payload });
      })
    )
  );

  logout$ = createEffect(() =>
    this.action$.pipe(
      ofType(logout),
      mergeMap((action) =>
        this.commonService.post(action.payload, AUTH_API_URL.LOGOUT).pipe(
          map((logoutResp) => {
            return logoutSuccess();
          })
        )
      ),
      tap(() => {
        this.router.navigateByUrl("/auth/login");
      })
    )
  );
}
