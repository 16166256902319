import { ActionReducerMap } from "@ngrx/store";
import { AuthState, authReducer } from "./auth/reducer";
import { DashboardState, dashboardReducer } from "./dashboard/reducers";
import { AppManagerState, appManagerReducer } from "./appmanager/reducer";
import { registrationReducer, RegistrationState } from "./register/reducer";
import { appointmentReducer, AppointmentState } from "./appoinment/reducer";
export interface AppState {
  authState: AuthState;
  dashboardState: DashboardState;
  appManagerState: AppManagerState;
  registrationState: RegistrationState;
  appointmentState: AppointmentState;
}

export const reducers: ActionReducerMap<AppState> = {
  authState: authReducer,
  dashboardState: dashboardReducer,
  appManagerState: appManagerReducer,
  registrationState: registrationReducer,
  appointmentState: appointmentReducer,
};
