import { Component, Inject, OnInit, ViewEncapsulation } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { ButtonComponent } from "@reusableComponents/button/button.component";
import { UtilsService } from "@services/utils.service";
import { IconModule } from "@shared/icon.module";

@Component({
  selector: "app-delete-dialog",
  standalone: true,
  imports: [ButtonComponent, IconModule],
  templateUrl: "./delete-dialog.component.html",
  styleUrl: "./delete-dialog.component.scss",
    encapsulation: ViewEncapsulation.None,
})
export class DeleteDialogComponent implements OnInit {
  dialogOpened: boolean = false;
  dialogClosed: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utillService: UtilsService
  ) {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      dialogRef.updateSize("300px", "208px");
    } else if (screenWidth < 1320) {
      dialogRef.updateSize("350px", "256px");
    } else {
      dialogRef.updateSize("400px", "346px");
    }
  }

  ngOnInit(): void {
    this.dialogOpened = true;
  }

  onCancel() {
    this.dialogRef.close(false);
    this.dialogClosed = true;
  }

  onDelete() {
    this.dialogRef.close(true);
    this.dialogClosed = true;
  }
}
