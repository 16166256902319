import { inject, Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { getTodayInfo } from "@assets/data/GetFacilityDashboardOptions";
import { getWardInfo } from "@assets/data/gerWardInfoDashboard";
import {
  getTodayInformation,
  getTodayInformationSuccess,
  getWardInformation,
  getWardInformationSuccess,
} from "./actions";
import { catchError, map, mergeMap } from "rxjs";
import { CommonService } from "@services/common.service";
@Injectable()
export class DashboardEffects {
  private action$ = inject(Actions);

  constructor(private commonService: CommonService) {}

  TodayInfo$ = createEffect(() =>
    this.action$.pipe(
      ofType(getTodayInformation),
      // mergeMap(action =>
      //     this.commonService.post(action.payload, API_URL.TODAY_INFORMATION).pipe(
      //         map(res => {
      //             return getTodayInformationSuccess({todayInfo: res})
      //         }),
      //     )
      // ),
      map(() => {
        return getTodayInformationSuccess({ todayInfo: getTodayInfo });
      })
    )
  );

  WardInfo$ = createEffect(() =>
    this.action$.pipe(
      ofType(getWardInformation),
      map(() => {
        return getWardInformationSuccess({ wardInfo: getWardInfo });
      })
    )
  );
}
