import { ApplicationConfig, provideZoneChangeDetection } from "@angular/core";
import { provideRouter } from "@angular/router";
import { routes } from "./app.routes";
import { provideAnimations } from "@angular/platform-browser/animations";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { provideStore } from "@ngrx/store";
import { provideEffects } from "@ngrx/effects";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import { reducers } from "@store/index";
import { metaReducers } from "@store/meta-reducers";
import { AuthEffects } from "@store/auth/effects";
import { DashboardEffects } from "@store/dashboard/effects";
import { provideNativeDateAdapter } from "@angular/material/core";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { AppManagerEffects } from "@store/appmanager/effects";
import { RegisterEffects } from "@store/register/effects";
import { AppointmentEffects } from "@store/appoinment/effects";

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(
      // withInterceptors([ authInterceptor])
      withInterceptorsFromDi()
    ),
    provideNativeDateAdapter(),
    provideStore(reducers, { metaReducers }),
    provideEffects([
      AuthEffects,
      DashboardEffects,
      AppManagerEffects,
      RegisterEffects,
      AppointmentEffects
    ]),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: true,
    }),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
};
