import { createReducer, Action, on } from "@ngrx/store";
import {
  clearErrorMessage,
  clearErrorMessageSuccess,
  errorMessage,
  getAppoinmentCategoryList,
  getAppoinmentCategoryListSuccess,
  getAppoinmentRequestList,
  getAppoinmentRequestListSuccess,
  getAppoinmentSessionList,
  getAppoinmentSessionListSuccess,
  getAppoinmentList,
  getAppoinmentListSuccess,
  checkoutPatients,
  checkoutPatientsSuccess,
  addAppointment,
  addAppointmentSuccess,
  getAppointmentMultiSessions,
  getAppointmentMultiSessionsSuccess,
  addAppointmentCatogory,
  addAppointmentCatogorySuccess,
  doctorAppointments,
  doctorAppointmentsSuccess,
  addAppointmentSession,
  addAppointmentSessionSuccess,
  getAppointmentSession,
  getAppointmentSessionSuccess,
  getAppointmentMultiSession,
  getAppointmentMultiSessionSuccess,
  updateAppointmentCatogory,
  updateAppointmentCatogorySuccess,
  getByIdAppointmentCatogory,
  getByIdAppointmentCatogorySuccess,
  addAppointmentMultiSession,
  addAppointmentMultiSessionSuccess,
  editAppointmentMultiSession,
  editAppointmentMultiSessionSuccess,
  addappointmentHolidays,
  addappointmentHolidaysSuccess,
  getappointmentHolidays,
  getappointmentHolidaysSuccess,
} from "./actions";

export interface AppointmentState {
  updateAppointmentCatogoryData: any;
  getByIdAppointmentCatogoryData: any;
  appoinmentSessionListData: any;
  appoinmentRequestListData: any;
  appoinmentCategoryListData: any;
  appoinmentListData: any;
  errorData: any;
  checkoutPatientsData: any;
  addAppointmentData: any;
  getAppointmentMultiSessionsData: any;
  addAppointmentCatogoryData: any;
  doctorAppointmentsData: any;
  addAppointmentSessionData: any;
  getAppointmentSessionData: any;
  getAppointmentMultiSessionData: any;
  addAppointmentMultiSessionData: any;
  editAppointmentMultiSessionData: any;
  addappointmentHolidaysData: any;
  getappointmentHolidaysData: any;
}
export const initialState: AppointmentState = {
  errorData: undefined,
  appoinmentCategoryListData: undefined,
  appoinmentSessionListData: undefined,
  appoinmentRequestListData: undefined,
  appoinmentListData: undefined,
  checkoutPatientsData: undefined,
  addAppointmentData: undefined,
  getAppointmentMultiSessionsData: undefined,
  addAppointmentCatogoryData: undefined,
  doctorAppointmentsData: undefined,
  addAppointmentSessionData: undefined,
  getAppointmentSessionData: undefined,
  getAppointmentMultiSessionData: undefined,
  updateAppointmentCatogoryData: undefined,
  getByIdAppointmentCatogoryData: undefined,
  addAppointmentMultiSessionData: undefined,
  editAppointmentMultiSessionData: undefined,
  addappointmentHolidaysData: undefined,
  getappointmentHolidaysData: undefined
};

const _reducer = createReducer(
  initialState,

  /*Appointments*/
  on(getAppoinmentList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getAppoinmentListSuccess, (state, { appoinmentListData }) => ({
    ...state,
    appoinmentListData,
    errorData: null,
  })),
  /*Appointments End*/

  /*Doctor_Appointments*/
  on(doctorAppointments, (state) => ({
    ...state,
    errorData: null,
  })),
  on(doctorAppointmentsSuccess, (state, { doctorAppointmentsData }) => ({
    ...state,
    doctorAppointmentsData,
    errorData: null,
  })),
  /*Doctor_Appointments End*/

  /*Add_Appointment*/
  on(addAppointment, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addAppointmentSuccess,
    (state, { addAppointmentData }) => ({
      ...state,
      addAppointmentData,
      errorData: null,
    })
  ),
  /*Add_Appointment End*/

  /*Add_Appointment*/
  on(getAppointmentMultiSessions, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getAppointmentMultiSessionsSuccess,
    (state, { getAppointmentMultiSessionsData }) => ({
      ...state,
      getAppointmentMultiSessionsData,
      errorData: null,
    })
  ),
  /*Add_Appointment End*/

  /*Get_Appointment_Multi_Sessions*/
  on(getAppointmentMultiSessions, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getAppointmentMultiSessionsSuccess,
    (state, { getAppointmentMultiSessionsData }) => ({
      ...state,
      getAppointmentMultiSessionsData,
      errorData: null,
    })
  ),
  /*Get_Appointment_Multi_Sessions End*/

  /*Checkout_Patients*/
  on(checkoutPatients, (state) => ({
    ...state,
    errorData: null,
  })),
  on(checkoutPatientsSuccess,
    (state, { checkoutPatientsData }) => ({
      ...state,
      checkoutPatientsData,
      errorData: null,
    })
  ),
  /*Checkout_Patients End*/

  /*Session*/
  on(getAppoinmentSessionList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getAppoinmentSessionListSuccess,
    (state, { appoinmentSessionListData }) => ({
      ...state,
      appoinmentSessionListData,
      errorData: null,
    })
  ),
  /*Session End*/

  /*Add_Sessions*/
  on(addAppointmentSession, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addAppointmentSessionSuccess,
    (state, { addAppointmentSessionData }) => ({
      ...state,
      addAppointmentSessionData,
      errorData: null,
    })
  ),
  /*Add_Sessions End*/

  /*Get_Appointment_Session_ById*/
  on(getAppointmentSession, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getAppointmentSessionSuccess,
    (state, { getAppointmentSessionData }) => ({
      ...state,
      getAppointmentSessionData,
      errorData: null,
    })
  ),
  /*Get_Appointment_Session_ById End*/

  /*Add_Appointment_Multi_Session*/
  on(addAppointmentMultiSession, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addAppointmentMultiSessionSuccess,
    (state, { addAppointmentMultiSessionData }) => ({
      ...state,
      addAppointmentMultiSessionData,
      errorData: null,
    })
  ),
  /*Add_Appointment_Multi_Session End*/

  /*Get_Appointment_Multi_Session_ById*/
  on(getAppointmentMultiSession, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getAppointmentMultiSessionSuccess,
    (state, { getAppointmentMultiSessionData }) => ({
      ...state,
      getAppointmentMultiSessionData,
      errorData: null,
    })
  ),

  /*Edit_Appointment_Multi_Session*/
  on(editAppointmentMultiSession, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editAppointmentMultiSessionSuccess,
    (state, { editAppointmentMultiSessionData }) => ({
      ...state,
      editAppointmentMultiSessionData,
      errorData: null,
    })
  ),
  /*Edit_Appointment_Multi_Session End*/

  /*Add_Appointment_Holidays*/
  on(addappointmentHolidays, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addappointmentHolidaysSuccess,
    (state, { addappointmentHolidaysData }) => ({
      ...state,
      addappointmentHolidaysData,
      errorData: null,
    })
  ),
  /*Add_Appointment_Holidays End*/

  /*Get_Appointment_Holidays*/
  on(getappointmentHolidays, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getappointmentHolidaysSuccess,
    (state, { getappointmentHolidaysData }) => ({
      ...state,
      getappointmentHolidaysData,
      errorData: null,
    })
  ),
  /*Get_Appointment_Holidays End*/

  /*Request*/
  on(getAppoinmentRequestList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getAppoinmentRequestListSuccess,
    (state, { appoinmentRequestListData }) => ({
      ...state,
      appoinmentRequestListData,
      errorData: null,
    })
  ),
  /*Request End*/

  /*Category*/
  on(getAppoinmentCategoryList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getAppoinmentCategoryListSuccess,
    (state, { appoinmentCategoryListData }) => ({
      ...state,
      appoinmentCategoryListData,
      errorData: null,
    })
  ),
  /*Category End*/

  /*Add_Categories*/
  on(addAppointmentCatogory, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addAppointmentCatogorySuccess,
    (state, { addAppointmentCatogoryData }) => ({
      ...state,
      addAppointmentCatogoryData,
      errorData: null,
    })
  ),
  on(updateAppointmentCatogory, (state) => ({
    ...state,
    errorData: null,
  })),
  on(updateAppointmentCatogorySuccess,
    (state, { updateAppointmentCatogoryData }) => ({
      ...state,
      updateAppointmentCatogoryData,
      errorData: null,
    })
  ),
  on(getByIdAppointmentCatogory, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getByIdAppointmentCatogorySuccess,
    (state, { getByIdAppointmentCatogoryData }) => ({
      ...state,
      getByIdAppointmentCatogoryData,
      errorData: null,
    })
  ),
  /*Add_Categories End*/

  on(errorMessage, (state, { errorData }) => ({
    ...state,
    errorData,
  })),
  on(clearErrorMessage, (state) => ({
    ...state,
    errorData: null,
  })),
  on(clearErrorMessageSuccess, (state) => ({
    ...state,
    errorData: null,
  }))
);
export function appointmentReducer(
  state: AppointmentState | undefined,
  action: Action
) {
  return _reducer(state, action);
}
