import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { catchError, EMPTY, Observable, Subscription, switchMap, throwError } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@store/index';
import { AccessToken, loginUserId, RefreshToken } from '@store/auth/selector';
import { logout, setAccessToken } from '@store/auth/actions';
import { CommonService } from '@services/common.service';
import { SnackbarService } from '@services/snackbar.service';
import { AUTH_API_URL } from '@core/api';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  accessToken!: string;
  refreshToken!: string;

  constructor(
    private store: Store<AppState>,
    private commonService: CommonService,
    private snackbarService: SnackbarService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.store.select(AccessToken).subscribe((token) => {
      if (token !== "" && token !== null && token !== undefined) {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token}`,
          },
        });
      }
    });
    return next.handle(request).pipe(
      catchError((err) => {
        if ([HttpStatusCode.Unauthorized].includes(err.status)) {
          if (!request?.url.includes("refresh")) {
            return this.handleAuthError(request, next);
          } else {
            this.store.select(loginUserId).subscribe((userId) => {
              if (userId !== 0) {
                let payload = {
                  UserId: userId,
                };
                this.store.dispatch(logout({ payload }));
                this.snackbarService.showError("Unauthorized request");
              }
            });
          }
        }
        return throwError(err);
      })
    );
  }

  private handleAuthError(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.store.select(RefreshToken).pipe(
      switchMap(refreshToken => {
      //     if(refreshToken === "" || refreshToken === null || refreshToken === undefined){
      //        this.store.select(loginUserId).subscribe(userId => {
      //             let payload = {
      //               UserId: userId
      //             }
      //       this.store.dispatch(logout({ payload }))
      //       this.snackbarService.showError('Unauthorized request')
      //   })
      // }
          return this.commonService.post({ refreshToken }, AUTH_API_URL.REFRESH_TOKEN).pipe(
          switchMap((response: any) => {
            this.store.dispatch(setAccessToken({ payload: response?.accessToken }));
            const retryReq = request.clone({ setHeaders: { Authorization: `Bearer ${response?.accessToken}` } });
            return next.handle(retryReq);
          }),
          catchError(err => throwError(err))
        );
      })
    );
  }
}
