<div class="dialog">
  <div class="close">
    <div class="common-cross">
      <mat-icon svgIcon="crossIcon" (click)="onCancel()"></mat-icon>
    </div>
  </div>
  <div class="dialog-body">
    <img src="../../../../assets/GIF/Delete GIF.gif" class="delete-gif" />
    <div mat-dialog-content>
      <div class="headline-2">Are you Sure you want to delete?</div>
    </div>
    <div class="action" mat-dialog-actions>
      <app-button
        name="Cancel"
        [strokeButton]="true"
        (click)="onCancel()"
        [class]="'dialog-cancel-btn'"
      >
      </app-button>

      <app-button
        name="Delete"
        [strokeButton]="false"
        (click)="onDelete()"
        [class]="'dialog-delete-btn'"
      >
      </app-button>
    </div>
  </div>
</div>
