import { createReducer, Action, on } from "@ngrx/store";
import {
  addEventTemplate,
  addEventTemplateSuccess,
  addFacility,
  addFacilitySuccess,
  addFacilitySettings,
  addFacilitySettingsSuccess,
  addOrganization,
  addOrganizationSuccess,
  editEventTemplate,
  editEventTemplateSuccess,
  editFacility,
  editFacilitySuccess,
  editOrganization,
  editOrganizationSuccess,
  facilityAddPreference,
  facilityAddPreferenceSuccess,
  facilityDeptMap,
  facilityDeptMapSuccess,
  facilityFileUpload,
  facilityFileUploadSuccess,
  facilityPreferenceTypes,
  facilityPreferenceTypesSuccess,
  getCity,
  getCitySuccess,
  getCountry,
  getCountrySuccess,
  getDepartmentList,
  getDepartmentListSuccess,
  getEventTemplateList,
  getEventTemplateListSuccess,
  getFacilityDefaultServicesList,
  getFacilityDefaultServicesListSuccess,
  getFacilityList,
  getFacilityListSuccess,
  getFacilitySpecialHolidaySettingsList,
  getFacilitySpecialHolidaySettingsListSuccess,
  getGroupsList,
  getGroupsListSuccess,
  getOptions,
  getOptionsSuccess,
  getOrganizationList,
  getOrganizationListSuccess,
  getPincode,
  getPincodeSuccess,
  getRolesList,
  getRolesListSuccess,
  getSpecialityList,
  getSpecialityListSuccess,
  getSpecialPrivilegeList,
  getSpecialPrivilegeListSuccess,
  getState,
  getStateSuccess,
  getUsersList,
  getUsersListSuccess,
  orgFileUpload,
  orgFileUploadSuccess,
  viewEventTemplate,
  viewEventTemplateSuccess,
  viewFacility,
  viewFacilitySuccess,
  viewOrganization,
  viewOrganizationSuccess,
  setFacilityId,
  setFacilityIdSuccess,
  addHolidays,
  addHolidaysSuccess,
  editHolidays,
  editHolidaysSuccess,
  getFacilityServiceItems,
  getFacilityServiceItemsSuccess,
  orgGetLogo,
  orgGetLogoSuccess,
  getReferenceGroups,
  getReferenceGroupsSuccess,
  getFacilityFirstLogo,
  getFacilityFirstLogoSuccess,
  getFacilitySecondLogo,
  getFacilitySecondLogoSuccess,
  addRole,
  addRoleSuccess,
  viewRole,
  viewRoleSuccess,
  editRole,
  editRoleSuccess,
  rolesMapFacility,
  rolesMapFacilitySuccess,
  getModules,
  getModulesSuccess,
  getModulesScreens,
  getModulesScreensSuccess,
  getErpOutbound,
  getErpOutboundSuccess,
  getErpInbound,
  getErpInboundSuccess,
  getControls,
  getControlsSuccess,
  getReportSchedularsList,
  getReportSchedularsListSuccess,
  errorMessage,
  clearErrorMessage,
  clearErrorMessageSuccess,
  getReferenceValues,
  getReferenceValuesSuccess,
  facilityGetDeptMap,
  facilityGetDeptMapSuccess,
  addDefaultService,
  addDefaultServiceSuccess,
  getHoliday,
  getHolidaySuccess,
  addDepartment,
  addDepartmentSuccess,
  viewDepartment,
  viewDepartmentSuccess,
  editDepartment,
  editDepartmentSuccess,
  addDeptLogo,
  addDeptLogoSuccess,
  getDeptLogo,
  getDeptLogoSuccess,
  rolesGetFacility,
  rolesGetFacilitySuccess,
  addGroup,
  addGroupSuccess,
  viewGroup,
  viewGroupSuccess,
  editGroup,
  editGroupSuccess,
  groupsMapFacility,
  groupsMapFacilitySuccess,
  groupsGetFacility,
  groupsGetFacilitySuccess,
  groupsMapRole,
  groupsMapRoleSuccess,
  groupsGetRole,
  groupsGetRoleSuccess,
  getFacilitySettings,
  getFacilitySettingsSuccess,
  editFacilitySettings,
  editFacilitySettingsSuccess,
  rolesControlMap,
  rolesControlMapSuccess,
  addSpeciality,
  addSpecialitySuccess,
  rolesMapControlData,
  rolesMapControlDataSuccess,
  getControlforRoleMap,
  getControlforRoleMapSuccess,
  rolesGetAllReports,
  rolesGetAllReportsSuccess,
  rolesGetReports,
  rolesGetReportsSuccess,
  rolesMapReports,
  rolesMapReportsSuccess,
  addSpecialPrivilege,
  addSpecialPrivilegeSuccess,
  // setControlsFormat,
  // setControlsFormatSuccess,
  getPasswordResetList,
  getPasswordResetListSuccess,
  getResetPassword,
  getResetPasswordSuccess,
  getActiveUsersListSuccess,
  getActiveUsersList,
  getSequenceMasterList,
  getSequenceMasterListSuccess,
  addSequenceMaster,
  addSequenceMasterSuccess,
  sqlToRedisSuccess,
  sqlToRedis,
  redisToSql,
  redisToSqlSuccess,
  viewSpeciality,
  viewSpecialitySuccess,
  updateSpeciality,
  updateSpecialitySuccess,
  addReferenceGroup,
  addReferenceGroupSuccess,
  updateReferenceGroup,
  updateReferenceGroupSuccess,
  viewReferenceGroup,
  viewReferenceGroupSuccess,
  deleteReferenceValue,
  deleteReferenceValueSuccess,
  viewReferenceValue,
  viewReferenceValueSuccess,
  updateReferenceValueSuccess,
  updateReferenceValue,
  addReferenceValue,
  addReferenceValueSuccess,
  addModule,
  addModuleSuccess,
  viewModule,
  viewModuleSuccess,
  updateModule,
  updateModuleSuccess,
  deleteModule,
  deleteModuleSuccess,
  deleteModuleScreenSuccess,
  deleteModuleScreen,
  updateModuleScreenSuccess,
  updateModuleScreen,
  viewModuleScreenSuccess,
  viewModuleScreen,
  addModuleScreenSuccess,
  addModuleScreen,
  getPrintLogs,
  getPrintLogsSuccess,
  addReportSchedulars,
  addReportSchedularsSuccess,
  viewReportSchedulars,
  viewReportSchedularsSuccess,
  editReportSchedulars,
  editReportSchedularsSuccess,
  getUnitDepartmentMappingList,
  getUnitDepartmentMappingListSuccess,
  getUnitDepartmentMappingDocterList,
  getUnitDepartmentMappingDocterListSuccess,
  addUnitDepartmentMappingSuccess,
  addUnitDepartmentMapping,
  updateUnitDepartmentMapping,
  updateUnitDepartmentMappingSuccess,
  viewUnitDepartmentMapping,
  viewUnitDepartmentMappingSuccess,
  deleteUnitDepartmentMapping,
  deleteUnitDepartmentMappingSuccess,
  addControl,
  addControlSuccess,
  viewControlSuccess,
  viewControl,
  updateControl,
  updateControlSuccess,
  addUser,
  addUserSuccess,
  editUser,
  editUserSuccess,
  viewUser,
  viewUserSuccess,
  userMapDept,
  userMapDeptSuccess,
  userGetDept,
  userGetDeptSuccess,
  userMapFacility,
  userMapFacilitySuccess,
  userGetFacility,
  userGetFacilitySuccess,
  userMapSpeciality,
  userMapSpecialitySuccess,
  userGetSpeciality,
  userGetSpecialitySuccess,
  userAddTeam,
  userAddTeamSuccess,
  userGetTeam,
  userGetTeamSuccess,
  userAddTaxDetails,
  userAddTaxDetailsSuccess,
  userViewTaxDetails,
  userViewTaxDetailsSuccess,
  userEditTaxDetails,
  userEditTaxDetailsSuccess,
  getRoleReportList,
  getRoleReportListSuccess,
  userAddDefaultService,
  userAddDefaultServiceSuccess,
  userViewDefaultService,
  userViewDefaultServiceSuccess,
  userGetEmployees,
  userGetEmployeesSuccess,
  addEventDashboard,
  addEventDashboardSuccess,
  getEventDashboardList,
  getEventDashboardListSuccess,
  viewEventDashboard,
  viewEventDashboardSuccess,
  editEventDashboard,
  editEventDashboardSuccess,
  getUserProfile,
  getUserProfileSuccess,
  getUserSign,
  getUserSignSuccess,
  getDistrict,
  getDistrictSuccess,
} from "./actions";
export interface AppManagerState {
  DistrictData: any;
  userSignData: any;
  userProfileData: any;
  roleReportData: any;
  viewControlData: any;
  updateControlData: any;
  addControlData: any;
  deleteUnitDepartmentMappingData: any;
  viewUnitDepartmentMappingData: any;
  updateUnitDepartmentMappingData: any;
  addUnitDepartmentMappingData: any;
  unitDepartmentMappingDocterListData: any;
  deleteModuleScreenData: any;
  updateModuleScreenData: any;
  viewModuleScreenData: any;
  addModuleScreenData: any;
  viewModuleData: any;
  updateModuleData: any;
  deleteModuleData: any;
  addModuleData: any;
  deleteReferenceValueData: any;
  updateReferenceValueData: any;
  viewReferenceValueData: any;
  addReferenceValueData: any;
  updateReferenceGroupData: any;
  viewReferenceGroupData: any;
  addReferenceGroupData: any;
  deleteSpecialityData: any;
  updateSpecialityData: any;
  viewSpecialityData: any;
  redisToSqlData: any;
  sqlToRedisData: any;
  editSequenceMasterData: any;
  addSequenceMasterData: any;
  sequenceMasterData: any;
  activeUsersListData: any;
  eventTemplateData: any;
  organizationData: any;
  facilityData: any;
  departmentData: any;
  rolesData: any;
  groupsData: any;
  specialityData: any;
  usersData: any;
  passwordResetData: any;
  resetPasswordData: any;
  optionsData: any;
  addEventTemplateData: any;
  viewEventTemplateData: any;
  editEventTemplateData: any;
  addOrganizationData: any;
  viewOrganizationData: any;
  editOrganizationData: any;
  countryData: any;
  stateData: any;
  cityData: any;
  pincodeData: any;
  orgFileUploadData: any;
  addFacilityData: any;
  facilityFileUploadData: any;
  facilityDeptMapData: any;
  specialPrivilegeData: any;
  viewFacilityData: any;
  editFacilityData: any;
  facilityPreferenceData: any;
  facilityAddPreferenceData: any;
  facilityId: number;
  facilityDefaultServicesData: any;
  facilitySpecialHolidaySettingsData: any;
  addHolidaysData: any;
  editHolidaysData: any;
  serviceItemsData: any;
  orgGetLogoData: any;
  referenceGroupsData: any;
  referenceValueData: any;
  facilityFirstLogoData: any;
  facilitySecondLogoData: any;
  addRoleData: any;
  viewRoleData: any;
  editRoleData: any;
  rolesMapFacilityData: any;
  modulesData: any;
  screensData: any;
  erpOutboundData: any;
  erpInboundData: any;
  controlsData: any;
  errorData: any;
  facilityGetDepMapData: any;
  addDefaultServiceData: any;
  getHolidaysData: any;
  addDepartmentData: any;
  viewDepartmentData: any;
  editDepartmentData: any;
  addDeptLogoData: any;
  getDeptLogoData: any;
  rolesGetFacilityData: any;
  addGroupsData: any;
  viewGroupsData: any;
  editGroupsData: any;
  groupsMapFacilityData: any;
  groupsGetFacilityData: any;
  groupsMapRoleData: any;
  groupsGetRoleData: any;
  getFacilitySettingsData: any;
  addFacilitySettingsData: any;
  editFacilitySettingsData: any;
  rolesControlMapData: any;
  addSpecialityData: any;
  rolesControlMapsData: any;
  getControlRoleData: any;
  rolesGetAllReportsData: any;
  rolesGetReportsData: any;
  rolesMapReportsData: any;
  addSpecialPrivilegeData: any;
  getPrintLogData: any;
  addReportSchedularsData: any;
  viewReportSchedularsData: any;
  editReportSchedularsData: any;
  reportSchedularsData: any;
  unitDepartmentMappingData: any;
  addUserData: any;
  editUserData: any;
  viewUserData: any;
  userMapDeptData: any;
  userGetDeptData: any;
  userMapFacilityData: any;
  userGetFacilityData: any;
  userMapSpecialityData: any;
  userGetSpecialityData: any;
  userAddTeamData: any;
  userGetTeamData: any;
  userAddTaxDetailsData: any;
  userViewTaxDetailsData: any;
  userEditTaxDetailsData: any;
  userAddDefaultServiceData: any;
  userViewDefaultServiceData: any;
  userGetEmployeesData: any;
  addEventDashboardData: any;
  getEventDashboardListData: any;
  viewEventDashboardData: any;
  editEventDashboardData: any;
}

export const initialState: AppManagerState = {
  eventTemplateData: undefined,
  organizationData: undefined,
  facilityData: undefined,
  departmentData: undefined,
  rolesData: undefined,
  groupsData: undefined,
  specialityData: undefined,
  usersData: undefined,
  passwordResetData: undefined,
  resetPasswordData: undefined,
  optionsData: undefined,
  viewEventTemplateData: undefined,
  addEventTemplateData: undefined,
  editEventTemplateData: undefined,
  addOrganizationData: undefined,
  viewOrganizationData: undefined,
  countryData: undefined,
  stateData: undefined,
  cityData: undefined,
  pincodeData: undefined,
  orgFileUploadData: undefined,
  editOrganizationData: undefined,
  addFacilityData: undefined,
  facilityFileUploadData: undefined,
  facilityDeptMapData: undefined,
  specialPrivilegeData: undefined,
  viewFacilityData: undefined,
  editFacilityData: undefined,
  facilityPreferenceData: undefined,
  facilityAddPreferenceData: undefined,
  facilityId: 0,
  facilityDefaultServicesData: undefined,
  facilitySpecialHolidaySettingsData: undefined,
  addHolidaysData: undefined,
  editHolidaysData: undefined,
  serviceItemsData: undefined,
  orgGetLogoData: undefined,
  referenceGroupsData: undefined,
  referenceValueData: undefined,
  facilityFirstLogoData: undefined,
  facilitySecondLogoData: undefined,
  addRoleData: undefined,
  viewRoleData: undefined,
  editRoleData: undefined,
  rolesMapFacilityData: undefined,
  modulesData: undefined,
  screensData: undefined,
  erpOutboundData: undefined,
  erpInboundData: undefined,
  controlsData: undefined,
  errorData: undefined,
  facilityGetDepMapData: undefined,
  addDefaultServiceData: undefined,
  getHolidaysData: undefined,
  addDepartmentData: undefined,
  viewDepartmentData: undefined,
  editDepartmentData: undefined,
  addDeptLogoData: undefined,
  getDeptLogoData: undefined,
  rolesGetFacilityData: undefined,
  addGroupsData: undefined,
  viewGroupsData: undefined,
  editGroupsData: undefined,
  groupsMapFacilityData: undefined,
  groupsGetFacilityData: undefined,
  groupsMapRoleData: undefined,
  groupsGetRoleData: undefined,
  getFacilitySettingsData: undefined,
  addFacilitySettingsData: undefined,
  editFacilitySettingsData: undefined,
  rolesControlMapData: undefined,
  addSpecialityData: undefined,
  rolesControlMapsData: undefined,
  getControlRoleData: undefined,
  rolesGetAllReportsData: undefined,
  rolesGetReportsData: undefined,
  rolesMapReportsData: undefined,
  addSpecialPrivilegeData: undefined,
  activeUsersListData: undefined,
  sequenceMasterData: undefined,
  editSequenceMasterData: undefined,
  addSequenceMasterData: undefined,
  redisToSqlData: undefined,
  sqlToRedisData: undefined,
  updateSpecialityData: undefined,
  viewSpecialityData: undefined,
  deleteReferenceValueData: undefined,
  updateReferenceValueData: undefined,
  viewReferenceValueData: undefined,
  addReferenceValueData: undefined,
  updateReferenceGroupData: undefined,
  viewReferenceGroupData: undefined,
  addReferenceGroupData: undefined,
  deleteSpecialityData: undefined,
  deleteModuleScreenData: undefined,
  updateModuleScreenData: undefined,
  viewModuleScreenData: undefined,
  addModuleScreenData: undefined,
  viewModuleData: undefined,
  updateModuleData: undefined,
  deleteModuleData: undefined,
  addModuleData: undefined,
  getPrintLogData: undefined,
  addReportSchedularsData: undefined,
  viewReportSchedularsData: undefined,
  editReportSchedularsData: undefined,
  reportSchedularsData: undefined,
  unitDepartmentMappingData: undefined,
  unitDepartmentMappingDocterListData: undefined,
  deleteUnitDepartmentMappingData: undefined,
  viewUnitDepartmentMappingData: undefined,
  updateUnitDepartmentMappingData: undefined,
  addUnitDepartmentMappingData: undefined,
  viewControlData: undefined,
  updateControlData: undefined,
  addControlData: undefined,
  addUserData: undefined,
  editUserData: undefined,
  viewUserData: undefined,
  userMapDeptData: undefined,
  userGetDeptData: undefined,
  userMapFacilityData: undefined,
  userGetFacilityData: undefined,
  userMapSpecialityData: undefined,
  userGetSpecialityData: undefined,
  userAddTeamData: undefined,
  userGetTeamData: undefined,
  userAddTaxDetailsData: undefined,
  userViewTaxDetailsData: undefined,
  userEditTaxDetailsData: undefined,

  roleReportData: undefined,
  userAddDefaultServiceData: undefined,
  userViewDefaultServiceData: undefined,
  userGetEmployeesData: undefined,
  addEventDashboardData: undefined,
  getEventDashboardListData: undefined,
  viewEventDashboardData: undefined,
  editEventDashboardData: undefined,
  userSignData: undefined,
  userProfileData: undefined,
  DistrictData: undefined
};

const _reducer = createReducer(
  initialState,
  on(getEventTemplateList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getEventTemplateListSuccess, (state, { eventTemplateData }) => ({
    ...state,
    eventTemplateData,
    errorData: null,
  })),
  on(addEventTemplate, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addEventTemplateSuccess, (state, { addEventTemplateData }) => ({
    ...state,
    addEventTemplateData,
    errorData: null,
  })),
  on(viewEventTemplate, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewEventTemplateSuccess, (state, { viewEventTemplateData }) => ({
    ...state,
    viewEventTemplateData,
    errorData: null,
  })),
  on(editEventTemplate, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editEventTemplateSuccess, (state, { editEventTemplateData }) => ({
    ...state,
    editEventTemplateData,
    errorData: null,
  })),
  on(getOrganizationList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getOrganizationListSuccess, (state, { organizationData }) => ({
    ...state,
    organizationData,
    errorData: null,
  })),
  on(addOrganization, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addOrganizationSuccess, (state, { addOrganizationData }) => ({
    ...state,
    addOrganizationData,
    errorData: null,
  })),
  on(addOrganization, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addOrganizationSuccess, (state, { addOrganizationData }) => ({
    ...state,
    addOrganizationData,
    errorData: null,
  })),
  on(viewOrganization, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewOrganizationSuccess, (state, { viewOrganizationData }) => ({
    ...state,
    viewOrganizationData,
    errorData: null,
  })),
  on(editOrganization, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editOrganizationSuccess, (state, { editOrganizationData }) => ({
    ...state,
    editOrganizationData,
    errorData: null,
  })),
  on(orgFileUpload, (state) => ({
    ...state,
    errorData: null,
  })),
  on(orgFileUploadSuccess, (state, { orgFileUploadData }) => ({
    ...state,
    orgFileUploadData,
    errorData: null,
  })),
  on(orgGetLogo, (state) => ({
    ...state,
    errorData: null,
  })),
  on(orgGetLogoSuccess, (state, { orgGetLogoData }) => ({
    ...state,
    orgGetLogoData,
    errorData: null,
  })),
  on(getFacilityList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getFacilityListSuccess, (state, { facilityData }) => ({
    ...state,
    facilityData,
    errorData: null,
  })),
  on(addFacility, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addFacilitySuccess, (state, { addFacilityData }) => ({
    ...state,
    addFacilityData,
    errorData: null,
  })),
  on(facilityFileUpload, (state) => ({
    ...state,
    errorData: null,
  })),
  on(facilityFileUploadSuccess, (state, { facilityFileUploadData }) => ({
    ...state,
    facilityFileUploadData,
    errorData: null,
  })),
  on(getFacilityFirstLogo, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getFacilityFirstLogoSuccess, (state, { facilityFirstLogoData }) => ({
    ...state,
    facilityFirstLogoData,
    errorData: null,
  })),
  on(getFacilitySecondLogo, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getFacilitySecondLogoSuccess, (state, { facilitySecondLogoData }) => ({
    ...state,
    facilitySecondLogoData,
    errorData: null,
  })),
  on(getFacilitySettings, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getFacilitySettingsSuccess, (state, { getFacilitySettingsData }) => ({
    ...state,
    getFacilitySettingsData,
    errorData: null,
  })),
  on(addFacilitySettings, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addFacilitySettingsSuccess, (state, { addFacilitySettingsData }) => ({
    ...state,
    addFacilitySettingsData,
    errorData: null,
  })),
  on(editFacilitySettings, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editFacilitySettingsSuccess, (state, { editFacilitySettingsData }) => ({
    ...state,
    editFacilitySettingsData,
    errorData: null,
  })),
  on(facilityDeptMap, (state) => ({
    ...state,
    errorData: null,
  })),
  on(facilityDeptMapSuccess, (state, { facilityDeptMapData }) => ({
    ...state,
    facilityDeptMapData,
    errorData: null,
  })),
  on(facilityGetDeptMap, (state) => ({
    ...state,
    errorData: null,
  })),
  on(facilityGetDeptMapSuccess, (state, { facilityGetDepMapData }) => ({
    ...state,
    facilityGetDepMapData,
    errorData: null,
  })),
  on(viewFacility, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewFacilitySuccess, (state, { viewFacilityData }) => ({
    ...state,
    viewFacilityData,
    errorData: null,
  })),
  on(editFacility, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editFacilitySuccess, (state, { editFacilityData }) => ({
    ...state,
    editFacilityData,
    errorData: null,
  })),
  on(facilityPreferenceTypes, (state) => ({
    ...state,
    errorData: null,
  })),
  on(facilityPreferenceTypesSuccess, (state, { facilityPreferenceData }) => ({
    ...state,
    facilityPreferenceData,
    errorData: null,
  })),
  on(facilityAddPreference, (state) => ({
    ...state,
    errorData: null,
  })),
  on(facilityAddPreferenceSuccess, (state, { facilityAddPreferenceData }) => ({
    ...state,
    facilityAddPreferenceData,
    errorData: null,
  })),
  on(setFacilityId, (state) => ({
    ...state,
    errorData: null,
  })),
  on(setFacilityIdSuccess, (state, { facilityId }) => ({
    ...state,
    facilityId,
    errorData: null,
  })),
  on(getFacilityDefaultServicesList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    getFacilityDefaultServicesListSuccess,
    (state, { facilityDefaultServicesData }) => ({
      ...state,
      facilityDefaultServicesData,
      errorData: null,
    })
  ),
  on(addDefaultService, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addDefaultServiceSuccess, (state, { addDefaultServiceData }) => ({
    ...state,
    addDefaultServiceData,
    errorData: null,
  })),
  on(getFacilitySpecialHolidaySettingsList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    getFacilitySpecialHolidaySettingsListSuccess,
    (state, { facilitySpecialHolidaySettingsData }) => ({
      ...state,
      facilitySpecialHolidaySettingsData,
      errorData: null,
    })
  ),
  on(addHolidays, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addHolidaysSuccess, (state, { addHolidaysData }) => ({
    ...state,
    addHolidaysData,
    errorData: null,
  })),
  on(getHoliday, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getHolidaySuccess, (state, { getHolidaysData }) => ({
    ...state,
    getHolidaysData,
    errorData: null,
  })),
  on(editHolidays, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editHolidaysSuccess, (state, { editHolidaysData }) => ({
    ...state,
    editHolidaysData,
    errorData: null,
  })),
  on(getFacilityServiceItems, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getFacilityServiceItemsSuccess, (state, { serviceItemsData }) => ({
    ...state,
    serviceItemsData,
    errorData: null,
  })),
  on(getDepartmentList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getDepartmentListSuccess, (state, { departmentData }) => ({
    ...state,
    departmentData,
    errorData: null,
  })),
  on(addDepartment, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addDepartmentSuccess, (state, { addDepartmentData }) => ({
    ...state,
    addDepartmentData,
    errorData: null,
  })),
  on(viewDepartment, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewDepartmentSuccess, (state, { viewDepartmentData }) => ({
    ...state,
    viewDepartmentData,
    errorData: null,
  })),
  on(editDepartment, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editDepartmentSuccess, (state, { editDepartmentData }) => ({
    ...state,
    editDepartmentData,
    errorData: null,
  })),
  on(addDeptLogo, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addDeptLogoSuccess, (state, { addDeptLogoData }) => ({
    ...state,
    addDeptLogoData,
    errorData: null,
  })),
  on(getDeptLogo, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getDeptLogoSuccess, (state, { getDeptLogoData }) => ({
    ...state,
    getDeptLogoData,
    errorData: null,
  })),
  on(getRolesList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getRolesListSuccess, (state, { rolesData }) => ({
    ...state,
    rolesData,
    errorData: null,
  })),
  on(addRole, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addRoleSuccess, (state, { addRoleData }) => ({
    ...state,
    addRoleData,
    errorData: null,
  })),
  on(viewRole, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewRoleSuccess, (state, { viewRoleData }) => ({
    ...state,
    viewRoleData,
    errorData: null,
  })),
  on(editRole, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editRoleSuccess, (state, { editRoleData }) => ({
    ...state,
    editRoleData,
    errorData: null,
  })),
  on(rolesMapFacility, (state) => ({
    ...state,
    errorData: null,
  })),
  on(rolesMapFacilitySuccess, (state, { rolesMapFacilityData }) => ({
    ...state,
    rolesMapFacilityData,
    errorData: null,
  })),
  on(rolesGetFacility, (state) => ({
    ...state,
    errorData: null,
  })),
  on(rolesGetFacilitySuccess, (state, { rolesGetFacilityData }) => ({
    ...state,
    rolesGetFacilityData,
    errorData: null,
  })),
  on(getGroupsList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getGroupsListSuccess, (state, { groupsData }) => ({
    ...state,
    groupsData,
    errorData: null,
  })),
  on(addGroup, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addGroupSuccess, (state, { addGroupsData }) => ({
    ...state,
    addGroupsData,
    errorData: null,
  })),
  on(viewGroup, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewGroupSuccess, (state, { viewGroupsData }) => ({
    ...state,
    viewGroupsData,
    errorData: null,
  })),
  on(editGroup, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editGroupSuccess, (state, { editGroupsData }) => ({
    ...state,
    editGroupsData,
    errorData: null,
  })),
  on(groupsMapFacility, (state) => ({
    ...state,
    errorData: null,
  })),
  on(groupsMapFacilitySuccess, (state, { groupsMapFacilityData }) => ({
    ...state,
    groupsMapFacilityData,
    errorData: null,
  })),
  on(groupsGetFacility, (state) => ({
    ...state,
    errorData: null,
  })),
  on(groupsGetFacilitySuccess, (state, { groupsGetFacilityData }) => ({
    ...state,
    groupsGetFacilityData,
    errorData: null,
  })),
  on(groupsMapRole, (state) => ({
    ...state,
    errorData: null,
  })),
  on(groupsMapRoleSuccess, (state, { groupsMapRoleData }) => ({
    ...state,
    groupsMapRoleData,
    errorData: null,
  })),
  on(groupsGetRole, (state) => ({
    ...state,
    errorData: null,
  })),
  on(groupsGetRoleSuccess, (state, { groupsGetRoleData }) => ({
    ...state,
    groupsGetRoleData,
    errorData: null,
  })),
  on(getSpecialityList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getSpecialityListSuccess, (state, { specialityData }) => ({
    ...state,
    specialityData,
    errorData: null,
  })),
  on(addSpeciality, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addSpecialitySuccess, (state, { addSpecialityData }) => ({
    ...state,
    addSpecialityData,
    errorData: null,
  })),
  on(viewSpeciality, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewSpecialitySuccess, (state, { viewSpecialityData }) => ({
    ...state,
    viewSpecialityData,
    errorData: null,
  })),
  on(updateSpeciality, (state) => ({
    ...state,
    errorData: null,
  })),
  on(updateSpecialitySuccess, (state, { updateSpecialityData }) => ({
    ...state,
    updateSpecialityData,
    errorData: null,
  })),
  on(getUsersList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getUsersListSuccess, (state, { usersData }) => ({
    ...state,
    usersData,
    errorData: null,
  })),
  on(addUser, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addUserSuccess, (state, { addUserData }) => ({
    ...state,
    addUserData,
    errorData: null,
  })),
  on(editUser, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editUserSuccess, (state, { editUserData }) => ({
    ...state,
    editUserData,
    errorData: null,
  })),
  on(viewUser, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewUserSuccess, (state, { viewUserData }) => ({
    ...state,
    viewUserData,
    errorData: null,
  })),
  on(userGetEmployees, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userGetEmployeesSuccess, (state, { userGetEmployeesData }) => ({
    ...state,
    userGetEmployeesData,
    errorData: null,
  })),
  on(userMapDept, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userMapDeptSuccess, (state, { userMapDeptData }) => ({
    ...state,
    userMapDeptData,
    errorData: null,
  })),
  on(userGetDept, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userGetDeptSuccess, (state, { userGetDeptData }) => ({
    ...state,
    userGetDeptData,
    errorData: null,
  })),
  on(userMapFacility, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userMapFacilitySuccess, (state, { userMapFacilityData }) => ({
    ...state,
    userMapFacilityData,
    errorData: null,
  })),
  on(userGetFacility, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userGetFacilitySuccess, (state, { userGetFacilityData }) => ({
    ...state,
    userGetFacilityData,
    errorData: null,
  })),
  on(userMapSpeciality, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userMapSpecialitySuccess, (state, { userMapSpecialityData }) => ({
    ...state,
    userMapSpecialityData,
    errorData: null,
  })),
  on(userGetSpeciality, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userGetSpecialitySuccess, (state, { userGetSpecialityData }) => ({
    ...state,
    userGetSpecialityData,
    errorData: null,
  })),
  on(userAddTeam, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userAddTeamSuccess, (state, { userAddTeamData }) => ({
    ...state,
    userAddTeamData,
    errorData: null,
  })),
  on(userGetTeam, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userGetTeamSuccess, (state, { userGetTeamData }) => ({
    ...state,
    userGetTeamData,
    errorData: null,
  })),
  on(userAddTaxDetails, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userAddTaxDetailsSuccess, (state, { userAddTaxDetailsData }) => ({
    ...state,
    userAddTaxDetailsData,
    errorData: null,
  })),
  on(userViewTaxDetails, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userViewTaxDetailsSuccess, (state, { userViewTaxDetailsData }) => ({
    ...state,
    userViewTaxDetailsData,
    errorData: null,
  })),
  on(userEditTaxDetails, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userEditTaxDetailsSuccess, (state, { userEditTaxDetailsData }) => ({
    ...state,
    userEditTaxDetailsData,
    errorData: null,
  })),
  on(userAddDefaultService, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userAddDefaultServiceSuccess, (state, { userAddDefaultServiceData }) => ({
    ...state,
    userAddDefaultServiceData,
    errorData: null,
  })),
  on(userViewDefaultService, (state) => ({
    ...state,
    errorData: null,
  })),
  on(userViewDefaultServiceSuccess, (state, { userViewDefaultServiceData }) => ({
    ...state,
    userViewDefaultServiceData,
    errorData: null,
  })),
  on(getUserProfile, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getUserProfileSuccess, (state, { userProfileData }) => ({
    ...state,
    userProfileData,
    errorData: null,
  })),
  on(getUserSign, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getUserSignSuccess, (state, { userSignData }) => ({
    ...state,
    userSignData,
    errorData: null,
  })),
  on(getPasswordResetList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPasswordResetListSuccess, (state, { passwordResetData }) => ({
    ...state,
    passwordResetData,
    errorData: null,
  })),

  on(getResetPassword, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getResetPasswordSuccess, (state, { resetPasswordData }) => ({
    ...state,
    resetPasswordData,
    errorData: null,
  })),

  on(getOptions, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getOptionsSuccess, (state, { optionsData }) => ({
    ...state,
    optionsData,
    errorData: null,
  })),
  on(getCountry, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getCountrySuccess, (state, { countryData }) => ({
    ...state,
    countryData,
    errorData: null,
  })),
  on(getState, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getStateSuccess, (state, { stateData }) => ({
    ...state,
    stateData,
    errorData: null,
  })),
  on(getCity, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getCitySuccess, (state, { cityData }) => ({
    ...state,
    cityData,
    errorData: null,
  })),
  on(getDistrict, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getDistrictSuccess, (state, { DistrictData }) => ({
    ...state,
    DistrictData,
    errorData: null,
  })),
  on(getPincode, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPincodeSuccess, (state, { pincodeData }) => ({
    ...state,
    pincodeData,
    errorData: null,
  })),
  on(getSpecialPrivilegeList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getSpecialPrivilegeListSuccess, (state, { specialPrivilegeData }) => ({
    ...state,
    specialPrivilegeData,
    errorData: null,
  })),
  on(addSpecialPrivilege, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addSpecialPrivilegeSuccess, (state, { addSpecialPrivilegeData }) => ({
    ...state,
    addSpecialPrivilegeData,
    errorData: null,
  })),
  on(getControlforRoleMap, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getControlforRoleMapSuccess, (state, { getControlRoleData }) => ({
    ...state,
    getControlRoleData,
    errorData: null,
  })),
  on(rolesControlMap, (state) => ({
    ...state,
    errorData: null,
  })),
  on(rolesControlMapSuccess, (state, { rolesControlMapData }) => ({
    ...state,
    rolesControlMapData,
    errorData: null,
  })),
  on(rolesMapControlData, (state) => ({
    ...state,
    errorData: null,
  })),
  on(rolesMapControlDataSuccess, (state, { rolesControlMapsData }) => ({
    ...state,
    rolesControlMapsData,
    errorData: null,
  })),
  on(rolesGetAllReports, (state) => ({
    ...state,
    errorData: null,
  })),
  on(rolesGetAllReportsSuccess, (state, { rolesGetAllReportsData }) => ({
    ...state,
    rolesGetAllReportsData,
    errorData: null,
  })),
  on(rolesGetReports, (state) => ({
    ...state,
    errorData: null,
  })),
  on(rolesGetReportsSuccess, (state, { rolesGetReportsData }) => ({
    ...state,
    rolesGetReportsData,
    errorData: null,
  })),
  on(rolesMapReports, (state) => ({
    ...state,
    errorData: null,
  })),
  on(rolesMapReportsSuccess, (state, { rolesMapReportsData }) => ({
    ...state,
    rolesMapReportsData,
    errorData: null,
  })),
  on(getReferenceGroups, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getReferenceGroupsSuccess, (state, { referenceGroupsData }) => ({
    ...state,
    referenceGroupsData,
    errorData: null,
  })),
  on(addReferenceGroup, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addReferenceGroupSuccess, (state, { addReferenceGroupData }) => ({
    ...state,
    addReferenceGroupData,
    errorData: null,
  })),
  on(updateReferenceGroup, (state) => ({
    ...state,
    errorData: null,
  })),
  on(updateReferenceGroupSuccess, (state, { updateReferenceGroupData }) => ({
    ...state,
    updateReferenceGroupData,
    errorData: null,
  })),
  on(viewReferenceGroup, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewReferenceGroupSuccess, (state, { viewReferenceGroupData }) => ({
    ...state,
    viewReferenceGroupData,
    errorData: null,
  })),
  on(getReferenceValues, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getReferenceValuesSuccess, (state, { referenceValueData }) => ({
    ...state,
    referenceValueData,
    errorData: null,
  })),
  on(addReferenceValue, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addReferenceValueSuccess, (state, { addReferenceValueData }) => ({
    ...state,
    addReferenceValueData,
    errorData: null,
  })),
  on(updateReferenceValue, (state) => ({
    ...state,
    errorData: null,
  })),
  on(updateReferenceValueSuccess, (state, { updateReferenceValueData }) => ({
    ...state,
    updateReferenceValueData,
    errorData: null,
  })),
  on(viewReferenceValue, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewReferenceValueSuccess, (state, { viewReferenceValueData }) => ({
    ...state,
    viewReferenceValueData,
    errorData: null,
  })),
  on(deleteReferenceValue, (state) => ({
    ...state,
    errorData: null,
  })),
  on(deleteReferenceValueSuccess, (state, { deleteReferenceValueData }) => ({
    ...state,
    deleteReferenceValueData,
    errorData: null,
  })),
  on(getModules, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getModulesSuccess, (state, { modulesData }) => ({
    ...state,
    modulesData,
    errorData: null,
  })),
  on(addModule, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addModuleSuccess, (state, { addModuleData }) => ({
    ...state,
    addModuleData,
    errorData: null,
  })),
  on(viewModule, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewModuleSuccess, (state, { viewModuleData }) => ({
    ...state,
    viewModuleData,
    errorData: null,
  })),
  on(updateModule, (state) => ({
    ...state,
    errorData: null,
  })),
  on(updateModuleSuccess, (state, { updateModuleData }) => ({
    ...state,
    updateModuleData,
    errorData: null,
  })),
  on(deleteModule, (state) => ({
    ...state,
    errorData: null,
  })),
  on(deleteModuleSuccess, (state, { deleteModuleData }) => ({
    ...state,
    deleteModuleData,
    errorData: null,
  })),
  on(getModulesScreens, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getModulesScreensSuccess, (state, { screensData }) => ({
    ...state,
    screensData,
    errorData: null,
  })),
  on(addModuleScreen, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addModuleScreenSuccess, (state, { addModuleScreenData }) => ({
    ...state,
    addModuleScreenData,
    errorData: null,
  })),
  on(viewModuleScreen, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewModuleScreenSuccess, (state, { viewModuleScreenData }) => ({
    ...state,
    viewModuleScreenData,
    errorData: null,
  })),
  on(updateModuleScreen, (state) => ({
    ...state,
    errorData: null,
  })),
  on(updateModuleScreenSuccess, (state, { updateModuleScreenData }) => ({
    ...state,
    updateModuleScreenData,
    errorData: null,
  })),
  on(deleteModuleScreen, (state) => ({
    ...state,
    errorData: null,
  })),
  on(deleteModuleScreenSuccess, (state, { deleteModuleScreenData }) => ({
    ...state,
    deleteModuleScreenData,
    errorData: null,
  })),
  on(getErpInbound, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getErpInboundSuccess, (state, { erpInboundData }) => ({
    ...state,
    erpInboundData,
    errorData: null,
  })),
  on(getErpOutbound, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getErpOutboundSuccess, (state, { erpOutboundData }) => ({
    ...state,
    erpOutboundData,
    errorData: null,
  })),
  on(getControls, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getControlsSuccess, (state, { controlsData }) => ({
    ...state,
    controlsData,
    errorData: null,
  })),
  on(addControl, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addControlSuccess, (state, { addControlData }) => ({
    ...state,
    addControlData,
    errorData: null,
  })),
  on(viewControl, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewControlSuccess, (state, { viewControlData }) => ({
    ...state,
    viewControlData,
    errorData: null,
  })),
  on(updateControl, (state) => ({
    ...state,
    errorData: null,
  })),
  on(updateControlSuccess, (state, { updateControlData }) => ({
    ...state,
    updateControlData,
    errorData: null,
  })),
  on(getActiveUsersList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getActiveUsersListSuccess, (state, { activeUsersListData }) => ({
    ...state,
    activeUsersListData,
    errorData: null,
  })),
  on(getActiveUsersList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getActiveUsersListSuccess, (state, { activeUsersListData }) => ({
    ...state,
    activeUsersListData,
    errorData: null,
  })),
  on(getSequenceMasterList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getSequenceMasterListSuccess, (state, { sequenceMasterData }) => ({
    ...state,
    sequenceMasterData,
    errorData: null,
  })),
  on(addSequenceMaster, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addSequenceMasterSuccess, (state, { addSequenceMasterData }) => ({
    ...state,
    addSequenceMasterData,
    errorData: null,
  })),
  on(sqlToRedis, (state) => ({
    ...state,
    errorData: null,
  })),
  on(sqlToRedisSuccess, (state, { sqlToRedisData }) => ({
    ...state,
    sqlToRedisData,
    errorData: null,
  })),
  on(redisToSql, (state) => ({
    ...state,
    errorData: null,
  })),
  on(redisToSqlSuccess, (state, { redisToSqlData }) => ({
    ...state,
    redisToSqlData,
    errorData: null,
  })),
  on(getPrintLogs, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getPrintLogsSuccess, (state, { getPrintLogData }) => ({
    ...state,
    getPrintLogData,
    errorData: null,
  })),
  on(addReportSchedulars, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addReportSchedularsSuccess, (state, { addReportSchedularsData }) => ({
    ...state,
    addReportSchedularsData,
    errorData: null,
  })),
  on(viewReportSchedulars, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewReportSchedularsSuccess, (state, { viewReportSchedularsData }) => ({
    ...state,
    viewReportSchedularsData,
    errorData: null,
  })),
  on(editReportSchedulars, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editReportSchedularsSuccess, (state, { editReportSchedularsData }) => ({
    ...state,
    editReportSchedularsData,
    errorData: null,
  })),
  on(getReportSchedularsList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getReportSchedularsListSuccess, (state, { reportSchedularsData }) => ({
    ...state,
    reportSchedularsData,
    errorData: null,
  })),
  on(getRoleReportList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getRoleReportListSuccess, (state, { roleReportData }) => ({
    ...state,
    roleReportData,
    errorData: null,
  })),
  on(getUnitDepartmentMappingList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    getUnitDepartmentMappingListSuccess,
    (state, { unitDepartmentMappingData }) => ({
      ...state,
      unitDepartmentMappingData,
      errorData: null,
    })
  ),
  on(getUnitDepartmentMappingDocterList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    getUnitDepartmentMappingDocterListSuccess,
    (state, { unitDepartmentMappingDocterListData }) => ({
      ...state,
      unitDepartmentMappingDocterListData,
      errorData: null,
    })
  ),
  on(addUnitDepartmentMapping, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    addUnitDepartmentMappingSuccess,
    (state, { addUnitDepartmentMappingData }) => ({
      ...state,
      addUnitDepartmentMappingData,
      errorData: null,
    })
  ),
  on(updateUnitDepartmentMapping, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    updateUnitDepartmentMappingSuccess,
    (state, { updateUnitDepartmentMappingData }) => ({
      ...state,
      updateUnitDepartmentMappingData,
      errorData: null,
    })
  ),
  on(viewUnitDepartmentMapping, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    viewUnitDepartmentMappingSuccess,
    (state, { viewUnitDepartmentMappingData }) => ({
      ...state,
      viewUnitDepartmentMappingData,
      errorData: null,
    })
  ),
  on(deleteUnitDepartmentMapping, (state) => ({
    ...state,
    errorData: null,
  })),
  on(
    deleteUnitDepartmentMappingSuccess,
    (state, { deleteUnitDepartmentMappingData }) => ({
      ...state,
      deleteUnitDepartmentMappingData,
      errorData: null,
    })
  ),
  on(addEventDashboard, (state) => ({
    ...state,
    errorData: null,
  })),
  on(addEventDashboardSuccess, (state, { addEventDashboardData }) => ({
    ...state,
    addEventDashboardData,
    errorData: null,
  })),
  on(getEventDashboardList, (state) => ({
    ...state,
    errorData: null,
  })),
  on(getEventDashboardListSuccess, (state, { getEventDashboardListData }) => ({
    ...state,
    getEventDashboardListData,
    errorData: null,
  })),
  on(viewEventDashboard, (state) => ({
    ...state,
    errorData: null,
  })),
  on(viewEventDashboardSuccess, (state, { viewEventDashboardData }) => ({
    ...state,
    viewEventDashboardData,
    errorData: null,
  })),
  on(editEventDashboard, (state) => ({
    ...state,
    errorData: null,
  })),
  on(editEventDashboardSuccess, (state, { editEventDashboardData }) => ({
    ...state,
    editEventDashboardData,
    errorData: null,
  })),
  on(errorMessage, (state, { errorData }) => ({
    ...state,
    errorData,
  })),
  on(clearErrorMessage, (state) => ({
    ...state,
    errorData: null,
  })),
  on(clearErrorMessageSuccess, (state) => ({
    ...state,
    errorData: null,
  }))
);

export function appManagerReducer(
  state: AppManagerState | undefined,
  action: Action
) {
  return _reducer(state, action);
}
