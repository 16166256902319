<mat-toolbar>
  <div class="left">
    <div routerLink="/dashboard/healthmed">
      @if(openSidebar){
      <img
        src="../../../assets/images/HealthmedLogo.png"
        alt="logo"
        class="logo"
      />
      } @else {
      <img
        src="../../../assets/images/HealthmedLogo2.png"
        alt="logo"
        class="logo2"
      />
      }
    </div>
    <button class="button-mat-svg" (click)="onToggleSidebar()">
      <mat-icon svgIcon="menuIcon"></mat-icon>
    </button>

    <div class="headline-4">{{ user?.Facility?.FacilityName }}</div>
  </div>

  <div class="right">
    <div class="headerMenu">
      <button class="button-mat-svg">
        <mat-icon svgIcon="bellIcon"></mat-icon>
      </button>
      <small>Notification</small>
    </div>

    <div class="headerMenu" [matMenuTriggerFor]="languageMenu">
      <button class="button-mat-svg">
        <mat-icon svgIcon="languageIcon"></mat-icon>
      </button>
      <button mat-button>
        <div class="language-menu-dropdown">
          English
          <span [innerHTML]="dropdownDownArrowIcon3"></span>
        </div>
      </button>
      <mat-menu #languageMenu="matMenu">
        <button mat-menu-item>English</button>
        <button mat-menu-item>Tamil</button>
      </mat-menu>
    </div>

    <div class="headerMenu">
      <button class="button-mat-svg">
        <mat-icon svgIcon="appInfoIcon"></mat-icon>
      </button>
      <small>App Info</small>
    </div>

    <button mat-button [matMenuTriggerFor]="menu">
      <div class="user-profile">
        <div class="user-name">
          <div class="profileMenu-user-name">
            {{ userName }}
          </div>
          <div class="profileMenu-user-role">
            {{ user?.ClinicalRole?.Description }}
          </div>
        </div>
        <img
          src="../../../assets/images/user-img.png"
          alt="user"
          class="user-img"
        />
      </div>
    </button>

    <mat-menu #menu="matMenu" class="wrapper-user-menu" xPosition="before">
      <button mat-menu-item>
        <mat-icon svgIcon="userIcon"></mat-icon>
        My Profile
      </button>
      <button mat-menu-item>
        <mat-icon svgIcon="changePasswordIcon"></mat-icon>
        Change Password
      </button>
      <button mat-menu-item>
        <mat-icon svgIcon="darkThemeIcon"></mat-icon>
        Dark Mode
      </button>
      <button mat-menu-item>
        <mat-icon svgIcon="settingsIcon"></mat-icon>
        Settings
      </button>
      <button mat-menu-item (click)="isLogout()">
        <mat-icon svgIcon="logoutIcon"></mat-icon>
        Log out
      </button>
    </mat-menu>
  </div>
</mat-toolbar>
