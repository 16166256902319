import { inject, Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from "@ngrx/effects";
import {
  clearErrorMessage,
  clearErrorMessageSuccess,
  errorMessage,
  getAppoinmentCategoryList,
  getAppoinmentCategoryListSuccess,
  getAppoinmentRequestList,
  getAppoinmentRequestListSuccess,
  getAppoinmentSessionList,
  getAppoinmentSessionListSuccess,
  getAppoinmentList,
  getAppoinmentListSuccess,
  checkoutPatients,
  checkoutPatientsSuccess,
  addAppointment,
  addAppointmentSuccess,
  getAppointmentMultiSessions,
  getAppointmentMultiSessionsSuccess,
  addAppointmentCatogory,
  addAppointmentCatogorySuccess,
  doctorAppointments,
  doctorAppointmentsSuccess,
  addAppointmentSession,
  addAppointmentSessionSuccess,
  getAppointmentSession,
  getAppointmentSessionSuccess,
  getAppointmentMultiSession,
  getAppointmentMultiSessionSuccess,
  updateAppointmentCatogory,
  updateAppointmentCatogorySuccess,
  getByIdAppointmentCatogory,
  getByIdAppointmentCatogorySuccess,
  addAppointmentMultiSession,
  addAppointmentMultiSessionSuccess,
  editAppointmentMultiSession,
  editAppointmentMultiSessionSuccess,
  addappointmentHolidays,
  addappointmentHolidaysSuccess,
  getappointmentHolidays,
  getappointmentHolidaysSuccess,
} from "./actions";
import { catchError, map, mergeMap, of } from "rxjs";
import { Router } from "@angular/router";
import { CommonService } from "@services/common.service";
import { APPOINTMENT_API_URL } from "@core/api";

@Injectable()
export class AppointmentEffects {
  private action$ = inject(Actions);
  constructor(private commonService: CommonService, private router: Router) { }
  //Appointments
  getAppoinmentList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getAppoinmentList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.GET_APPOINTMENTS)
          .pipe(
            map((resp) => {
              return getAppoinmentListSuccess({
                appoinmentListData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Doctor_Appointments
  doctorAppointments$ = createEffect(() =>
    this.action$.pipe(
      ofType(doctorAppointments),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.GET_APPOINTMENTS)
          .pipe(
            map((resp) => {
              return doctorAppointmentsSuccess({
                doctorAppointmentsData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Add_Appointment
  addAppointment$ = createEffect(() =>
    this.action$.pipe(
      ofType(addAppointment),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.ADD_APPOINTMENT)
          .pipe(
            map((resp) => {
              return addAppointmentSuccess({
                addAppointmentData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Get_Appointment_Multi_Sessions
  getAppointmentMultiSessions$ = createEffect(() =>
    this.action$.pipe(
      ofType(getAppointmentMultiSessions),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APPOINTMENT_API_URL.GET_APPOINTMENT_MULTI_SESSIONS
          )
          .pipe(
            map((resp) => {
              return getAppointmentMultiSessionsSuccess({
                getAppointmentMultiSessionsData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Checkout_Patients
  checkoutPatients$ = createEffect(() =>
    this.action$.pipe(
      ofType(checkoutPatients),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.CHECKOUT_PATIENTS)
          .pipe(
            map((resp) => {
              return checkoutPatientsSuccess({
                checkoutPatientsData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Sesssions
  getAppoinmentSessionList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getAppoinmentSessionList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.GET_APPOINMENT_SESSIONS)
          .pipe(
            map((resp) => {
              return getAppoinmentSessionListSuccess({
                appoinmentSessionListData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Add_Sessions
  addAppointmentSession$ = createEffect(() =>
    this.action$.pipe(
      ofType(addAppointmentSession),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.ADD_APPOINMENT_SESSIONS)
          .pipe(
            map((resp) => {
              return addAppointmentSessionSuccess({
                addAppointmentSessionData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Get_Appointment_Session_ById
  getAppointmentSession$ = createEffect(() =>
    this.action$.pipe(
      ofType(getAppointmentSession),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APPOINTMENT_API_URL.GET_APPOINTMENT_SESSION_BYID
          )
          .pipe(
            map((resp) => {
              return getAppointmentSessionSuccess({
                getAppointmentSessionData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Get_Appointment_Multi_Session_ById
  getAppointmentMultiSession$ = createEffect(() =>
    this.action$.pipe(
      ofType(getAppointmentMultiSession),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APPOINTMENT_API_URL.GET_APPOINTMENT_MULTI_SESSION_BYID
          )
          .pipe(
            map((resp) => {
              return getAppointmentMultiSessionSuccess({
                getAppointmentMultiSessionData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Add_Appointment_Multi_Session
  addAppointmentMultiSession$ = createEffect(() =>
    this.action$.pipe(
      ofType(addAppointmentMultiSession),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APPOINTMENT_API_URL.ADD_MULTI_APPOINTMENT
          )
          .pipe(
            map((resp) => {
              return addAppointmentMultiSessionSuccess({
                addAppointmentMultiSessionData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Edit_Appointment_Multi_Session
  editAppointmentMultiSession$ = createEffect(() =>
    this.action$.pipe(
      ofType(editAppointmentMultiSession),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APPOINTMENT_API_URL.UPDATE_APPOINTMENT_MULTI_SESSION
          )
          .pipe(
            map((resp) => {
              return editAppointmentMultiSessionSuccess({
                editAppointmentMultiSessionData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Add_Appointment_Holidays
  addappointmentHolidays$ = createEffect(() =>
    this.action$.pipe(
      ofType(addappointmentHolidays),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APPOINTMENT_API_URL.APPOINTMENT_MANAGE_HOLIDAYS
          )
          .pipe(
            map((resp) => {
              return addappointmentHolidaysSuccess({
                addappointmentHolidaysData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Get_Appointment_Holidays
  getappointmentHolidays$ = createEffect(() =>
    this.action$.pipe(
      ofType(getappointmentHolidays),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APPOINTMENT_API_URL.GET_APPOINTMENT_HOLIDAYS
          )
          .pipe(
            map((resp) => {
              return getappointmentHolidaysSuccess({
                getappointmentHolidaysData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Requests
  getAppoinmentRequestList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getAppoinmentRequestList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.GET_APPOINMENT_REQUESTS)
          .pipe(
            map((resp) => {
              return getAppoinmentRequestListSuccess({
                appoinmentRequestListData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Categories
  getAppoinmentCategoryList$ = createEffect(() =>
    this.action$.pipe(
      ofType(getAppoinmentCategoryList),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.GET_APPOINMENT_CATEGORIES)
          .pipe(
            map((resp) => {
              return getAppoinmentCategoryListSuccess({
                appoinmentCategoryListData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );

  //Add_Categories
  addAppointmentCatogory$ = createEffect(() =>
    this.action$.pipe(
      ofType(addAppointmentCatogory),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.ADD_APPOINMENT_CATEGORIES)
          .pipe(
            map((resp) => {
              return addAppointmentCatogorySuccess({
                addAppointmentCatogoryData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  //Add_Categories
  updateAppointmentCatogory$ = createEffect(() =>
    this.action$.pipe(
      ofType(updateAppointmentCatogory),
      mergeMap((action) =>
        this.commonService
          .post(
            action.payload,
            APPOINTMENT_API_URL.UPDATE_APPOINMENT_CATEGORIES
          )
          .pipe(
            map((resp) => {
              return updateAppointmentCatogorySuccess({
                updateAppointmentCatogoryData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  ); //Add_Categories
  getByIdAppointmentCatogory$ = createEffect(() =>
    this.action$.pipe(
      ofType(getByIdAppointmentCatogory),
      mergeMap((action) =>
        this.commonService
          .post(action.payload, APPOINTMENT_API_URL.GETBYID_APPOINMENT_CATEGORIES)
          .pipe(
            map((resp) => {
              return getByIdAppointmentCatogorySuccess({
                getByIdAppointmentCatogoryData: resp,
              });
            }),
            catchError((error) => {
              return of(errorMessage({ errorData: error }));
            })
          )
      )
    )
  );
  clearErrorMessage$ = createEffect(() =>
    this.action$.pipe(
      ofType(clearErrorMessage),
      map(() => {
        return clearErrorMessageSuccess();
      })
    )
  );
}
