import { CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { MatIcon } from "@angular/material/icon";
import { MatListItem, MatNavList } from "@angular/material/list";
import { SafeHtml } from "@angular/platform-browser";
import { Router, RouterModule, RouterOutlet } from "@angular/router";
import { UtilsService } from "@services/utils.service";
import { BreakpointObserver } from "@angular/cdk/layout";
import { BreadcrumbComponent } from "@reusableComponents/breadcrumb/breadcrumb.component";
import { Store } from "@ngrx/store";
import { AppState } from "@store/index";
import { controlsList } from "@store/auth/selector";
import { InputComponent } from "@reusableComponents/input/input.component";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { IconModule } from "@shared/icon.module";

@Component({
  selector: "app-sidenav",
  standalone: true,
  imports: [
    RouterOutlet,
    MatNavList,
    MatListItem,
    MatIcon,
    RouterModule,
    CommonModule,
    BreadcrumbComponent,
    InputComponent,
    IconModule,
  ],
  templateUrl: "./sidenav.component.html",
  styleUrl: "./sidenav.component.scss",
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit, AfterViewInit {
  searchForm: FormGroup;
  formsubmitted: boolean = false;
  screenWidth!: number;
  searchIcon!: SafeHtml;
  dropdownDownArrowIcon!: SafeHtml;
  activeMenu: string | null = null;
  menuItems: any[] = [];
  @Input() openSidebar: boolean = true;
  @Output() toggleSidebarEvent = new EventEmitter<void>();
  filteredMenuItems: any = [];
  searchQuery: string = '';
  private ctrlKeyPressed: boolean = false;
  @ViewChild('globalSearch') appInput!: InputComponent; // get template variable to appinput child

  constructor(
    private fb: FormBuilder,
    public utilsService: UtilsService,
    private breakpointObserver: BreakpointObserver,
    private renderer: Renderer2,
    private router: Router,
    private store: Store<AppState>,
  ) {
    this.searchIcon = this.utilsService.searchIcon("#2C2E31");
    this.dropdownDownArrowIcon = this.utilsService.dropdownDownArrowIcon();
    this.getControlList();

    this.searchForm = this.fb.group({
      search: [null],
    });
  }

  getControl(controlName: string) {
    return this.searchForm.get(controlName) as FormControl;
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1320) {
      this.openSidebar = false;
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (event.key === 'Control' || event.key === 'Meta') {
      this.ctrlKeyPressed = true;
    }

    if (this.ctrlKeyPressed && event.key === 'k') {
      event.preventDefault(); // prevent default browser behiavour
      this.focusSearchInput(); // keyboard event matched to call focusSearchInput fn
    }
  }

  focusSearchInput() {
    this.openSidebar = !this.openSidebar; // open sidemenu
    this.toggleSidebarEvent.emit();
    if (this.appInput) {
      setTimeout(() => {
        this.appInput.focusInput(); // call appinput function
      }, 100);
    }
  }

  showSubmenu(itemEl: HTMLElement, menuLabel: string) {
    itemEl.classList.toggle("showMenu");

    if (itemEl.classList.contains("showMenu")) {
      this.activeMenu = menuLabel;
    } else {
      this.activeMenu = null;
    }
  }

  ngAfterViewInit(): void {
    this.checkActiveRoute();
    if (this.appInput) {
      this.focusSearchInput();
    }
  }

  checkActiveRoute(): void {
    setTimeout(() => {
      const activeMenuEl = document.querySelector(".active-sidemenu");
      if (activeMenuEl) {
        activeMenuEl.classList.add("showMenu");
      }
    }, 0);
  }

  isRouteActive(route: string): boolean {
    return this.router.isActive(route, true);
  }

  subMenuOpen: { [key: string]: boolean } = {};

  mouseenter() {
    const sidebar = document.querySelector(".sidebar");
    if (!this.openSidebar) {
      this.renderer.removeClass(sidebar, "sidebar-close");
      this.toggleSidebarEvent.emit();
    }
  }

  mouseleave() {
    const sidebar = document.querySelector(".sidebar");
    if (!this.openSidebar) {
      this.renderer.addClass(sidebar, "sidebar-close");
      // this.toggleSidebarEvent.emit();
    }
  }

  searchValue(event: any) {
    this.searchQuery = event.toLowerCase(); //search_value
    if (!this.searchQuery) {
      this.filteredMenuItems = this.menuItems; //set filterMenu to menuItems
      return;
    }
    const matched: any[] = []; //declare matched array
    this.menuItems.forEach((item: any) => {
      if (item.label.toLowerCase().includes(this.searchQuery)) {  //check parent menu & store matched array
        matched.push(item);
      }
      else if (item.subItems.some((subItem: any) => subItem.label.toLowerCase().includes(this.searchQuery))) {
        matched.push(item); //check child menu based get parent & store matched array
      }
    });

    this.filteredMenuItems = matched; //set filtermenu to matched array
    const activeMenuEl = document.querySelector(`
      [data-menu-label="${matched[0].label}"]`); //set menu label on based search first parent

    if (activeMenuEl) {
      activeMenuEl.classList.add("showMenu"); // open menu include this showmenu
      this.activeMenu = matched[0].label; // open menu based label
    } else {
      this.activeMenu = null; // else close menus
    }
  }

  getControlList() {
    this.store.select(controlsList).subscribe((controlList) => {
      if (controlList) this.setSideMenu(controlList);
    });
  }

  setSideMenu(controlList: any) {
    const menuList = controlList?.Data;
    interface MenuItem {
      label: string;
      icon: any;
      subItems: subMenuItem[];
    }

    interface subMenuItem {
      label: string;
      link: string;
    }

    const menu: MenuItem[] = [];

    menuList.forEach((parent: any) => {
      if (parent.ParentControlId === null) {
        const svgRef = parent.svgRef;
        const newMenuItem = {
          Id: parent.Id,
          DisplayOrder: parent.DisplayOrder,
          label: parent.Display,
          icon: `${svgRef}`,
          subItems: this.setChilds(menuList, parent.Id),
        };
        menu.push(newMenuItem);
      }
    });

    this.menuItems = menu;
    this.filteredMenuItems = menu;
  }

  setChilds(list: any, id: number) {
    const filterChilds = list.filter((app: any) => app.ParentControlId === id);
    const setProp = filterChilds.map((item: any) => ({
      ...item,
      label: item?.Display,
      link: `/${item?.SRef.replace(/\./g, "/")}`,
    }));
    return setProp;
  }
}

// setSideMenu(controlList: any) {
//   const menuList = controlList?.Data;
//   interface MenuItem {
//     label: string;
//     icon: any;
//     subItems: subMenuItem[];
//   }

//   interface subMenuItem {
//     label: string;
//     link: string;
//   }

//   const menu: MenuItem[] = [];
//   // const IconMap: any = {
//   //   dotIcon: this.utilsService.dotIcon(),
//   //   // AppManagerIcon: this.utilsService.AppManagerIcon(),
//   //   registrationIcon: this.utilsService.registrationIcon(),
//   //   appointmentIcon: this.utilsService.appointmentIcon(),
//   //   billingIcon: this.utilsService.billingIcon(),
//   //   billingMasterIcon: this.utilsService.billingMasterIcon(),
//   //   lisIcon: this.utilsService.lisIcon(),
//   //   diagnosticMastersIcon: this.utilsService.diagnosticMastersIcon(),
//   //   emrIcon: this.utilsService.emrIcon(),
//   //   risIcon: this.utilsService.risIcon(),
//   //   endoscopyIcon: this.utilsService.endoscopyIcon(),
//   //   nuclearMedicineIcon: this.utilsService.nuclearMedicineIcon(),
//   //   echoIcon: this.utilsService.echoIcon(),
//   //   generalIcon: this.utilsService.generalIcon(),
//   //   reportIcon: this.utilsService.reportIcon(),
//   //   dashboardIcon: this.utilsService.dashboardIcon(),
//   //   ipManagementIcon: this.utilsService.ipManagementIcon(),
//   //   pharmacyIcon: this.utilsService.pharmacyIcon(),
//   //   inventoryManagementIcon: this.utilsService.inventoryManagementIcon(),
//   //   accountsPayableIcon: this.utilsService.accountsPayableIcon(),
//   //   inventoryMastersIcon: this.utilsService.inventoryMastersIcon(),
//   //   opticalManagementIcon: this.utilsService.opticalManagementIcon(),
//   //   accidentEmergencyIcon: this.utilsService.accidentEmergencyIcon(),
//   //   dietandKitchenIcon: this.utilsService.dietandKitchenIcon(),
//   //   claimManagementIcon: this.utilsService.claimManagementIcon(),
//   //   oTManagementlIcon: this.utilsService.oTManagementlIcon(),
//   //   cssdManagementIcon: this.utilsService.cssdManagementIcon(),
//   //   medicalCertificateIcon: this.utilsService.medicalCertificateIcon(),
//   //   ambulatoryServiceIcon: this.utilsService.ambulatoryServiceIcon(),
//   //   clinicalMaster: this.utilsService.clinicalMaster(),
//   //   generalMasterIcon: this.utilsService.generalMasterIcon(),
//   //   mrdIcon: this.utilsService.mrdIcon(),
//   //   hrmMastersIcon: this.utilsService.hrmMastersIcon(),
//   //   hrmManagementIcon: this.utilsService.hrmManagementIcon(),
//   //   recruitmentsIcon: this.utilsService.recruitmentsIcon(),
//   //   costManagementIcon: this.utilsService.costManagementIcon(),
//   //   expenseIcon: this.utilsService.expenseIcon(),
//   //   attendanceManagementIcon: this.utilsService.attendanceManagementIcon(),
//   //   assetManagementIcon: this.utilsService.assetManagementIcon(),
//   //   physiotherapyIcon: this.utilsService.physiotherapyIcon(),
//   //   bmIcon: this.utilsService.bmIcon(),
//   //   selfServiceIcon: this.utilsService.selfServiceIcon(),
//   //   patientServicesIcon: this.utilsService.patientServicesIcon(),
//   //   infectionControlsIcon: this.utilsService.infectionControlsIcon(),
//   //   ophthalmologyEmrIcon: this.utilsService.ophthalmologyEmrIcon(),
//   //   BioMedicalWasteManagementIcon:
//   //     this.utilsService.BioMedicalWasteManagementIcon(),
//   //   linenLaundryIcon: this.utilsService.linenLaundryIcon(),
//   //   bloodBankIcon: this.utilsService.bloodBankIcon(),
//   //   qmsIcon: this.utilsService.qmsIcon(),
//   //   qualityManagementIcon: this.utilsService.qualityManagementIcon(),
//   //   biReportsIcon: this.utilsService.biReportsIcon(),
//   // };

//   menuList.forEach((parent: any) => {
//     if (parent.ParentControlId === null) {
//       const svgRef = parent.svgRef;
//       const newMenuItem = {
//         Id: parent.Id,
//         DisplayOrder: parent.DisplayOrder,
//         label: parent.Display,
//         // icon: 'AppManagerIcon',
//         // icon: IconMap[`${svgRef}`],
//         icon: `${svgRef}`,
//         subItems: this.setChilds(menuList, parent.Id),
//       };
//       menu.push(newMenuItem);
//     }
//   });

//   this.menuItems = menu;
// }

// ngOnInit(): void {
//   this.screenWidth = window.innerWidth;
//   if (this.screenWidth < 1320) {
//     this.openSidebar = false;
//   }
//   // this.toggleSidebarCloseClass();

//   // this.menuItems = [
//   //   {
//   //     label: 'App manager',
//   //     icon: this.AppMangerIcon,
//   //     subItems: [
//   //       { label: 'Event Template', link: '/appmanager/event-template' },
//   //       { label: 'Organization', link: '/appmanager/organization' },
//   //       { label: 'Facility', link: '/appmanager/facility' },
//   //       { label: 'Departments', link: '/appmanager/departments' },
//   //       { label: 'Roles', link: '/appmanager/roles' },
//   //       { label: 'Groups', link: '/appmanager/groups' },
//   //       { label: 'Speciality', link: '/appmanager/speciality' },
//   //       { label: 'Users', link: '/appmanager/users' },
//   //       { label: 'Reference Groups', link: '/appmanager/ref-groups' },
//   //       { label: 'Modules', link: '/appmanager/modules' },
//   //       { label: 'Sequence Masters', link: '/appmanager/seq-masters' },
//   //       { label: 'Manager Events', link: '/appmanager/manage-events' },
//   //       { label: 'ERP Integration', link: '/appmanager/erp-integration' },
//   //       { label: 'Active Users', link: '/appmanager/users-active' },
//   //       { label: 'Event Dashboard', link: '/appmanager/event-dashboard' },
//   //       { label: 'Print Logs', link: '/appmanager/print-logs' },
//   //       { label: 'Controls', link: '/appmanager/controls' },
//   //       { label: 'Report Schedular', link: '/appmanager/report-shedular' },
//   //     ],
//   //     route: "/"
//   //   },
//   //   {
//   //     label: 'Registration',
//   //     icon: this.registrationIcon,
//   //     subItems: [
//   //       { label: 'Patient Search', link: '/registration/patient-search' },
//   //       { label: 'Quick Registration', link: '/registration/quick-registration' },
//   //     ],
//   //     route: "/"
//   //   },
//   //   {
//   //     label: 'Dashboard',
//   //     icon: this.appointmentIcon,
//   //     subItems: [
//   //       { label: 'Healthmed Dashboard', link: '/dashboard/healthmed' },
//   //     ],
//   //     route: "/"
//   //   },
//   //   {
//   //     label: 'Billing',
//   //     icon: this.billingIcon,
//   //     subItems: [
//   //       { label: 'Submenu Item 3', link: '/submenu3' },
//   //       { label: 'Submenu Item 4', link: '/submenu4' },
//   //     ],
//   //     route: "/"
//   //   }
//   // ]
// }
