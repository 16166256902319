import { createAction, props } from "@ngrx/store";

export const getPatientSearch = createAction('[Auth] get Patient Search',
    props<{ payload: any }>()
)
export const getPatientSearchSuccess = createAction('[Auth] get Patient Search Success',
    props<{ patientSearchData: any }>()
)
export const getPatientProfilePic = createAction('[Auth] get Patient Profile Pic',
    props<{ payload: any }>()
)
export const getPatientProfilePicSuccess = createAction('[Auth] get Patient Profile Pic Success',
    props<{ patientProfilePicData: any }>()
)
export const convertXlstoJson = createAction('[Auth] convert xls to json',
    props<{ payload: any }>()
)
export const convertXlstoJsonSuccess = createAction('[Auth] convert xls to json Success',
    props<{ convertXlstoJsonData: any }>()
)
export const bulkAddPatients = createAction('[Auth] bulk add Patient',
    props<{ payload: any }>()
)
export const bulkAddPatientsSuccess = createAction('[Auth] bulk add Patient Success',
    props<{ bulkAddPatientsData: any }>()
)
export const addPatient = createAction('[Auth] add Patient',
    props<{ payload: any }>()
)
export const addPatientSuccess = createAction('[Auth] add Patient Success',
    props<{ addPatientData: any }>()
)
export const addRegisterCumVisit = createAction('[Auth] addRegisterCumVisit ',
    props<{ payload: any }>()
)
export const addRegisterCumVisitSuccess = createAction('[Auth] addRegisterCumVisit  Success',
    props<{ addRegisterCumVisitData: any }>()
)
export const updatePatient = createAction('[Auth] update Patient',
    props<{ payload: any }>()
)
export const updatePatientSuccess = createAction('[Auth] update Patient Success',
    props<{ updatePatientData: any }>()
)
export const getPatientById = createAction('[Auth] get Patient ById',
    props<{ payload: any }>()
)
export const getPatientByIdSuccess = createAction('[Auth] get Patient ById Success',
    props<{ getPatientByIdData: any }>()
)
export const patientPortal = createAction('[Auth] get Patient Portal',
    props<{ payload: any }>()
)
export const patientPortalSuccess = createAction('[Auth] get Patient Portal Success',
    props<{ patientPortalData: any }>()
)
export const addPatientAttachment = createAction('[Auth] add Patient Attachment',
    props<{ payload: any }>()
)
export const addPatientAttachmentSuccess = createAction('[Auth] add Patient Attachment Success',
    props<{ addPatientAttachmentData: any }>()
)
export const getPatientAttachments = createAction('[Auth] get Patient Attachments',
    props<{ payload: any }>()
)
export const getPatientAttachmentsSuccess = createAction('[Auth] get Patient Attachments Success',
    props<{ getPatientAttachmentsData: any }>()
)
export const AddPaitentGuarantor = createAction('[Auth] AddPaitentGuarantor',
    props<{ payload: any }>()
)
export const AddPaitentGuarantorSuccess = createAction('[Auth] AddPaitentGuarantor Success',
    props<{ AddPaitentGuarantorData: any }>()
)
export const UpdatePaitentGuarantor = createAction('[Auth] UpdatePaitentGuarantor',
    props<{ payload: any }>()
)
export const UpdatePaitentGuarantorSuccess = createAction('[Auth] UpdatePaitentGuarantor Success',
    props<{ UpdatePaitentGuarantorData: any }>()
)
export const GetPaitentGuarantor = createAction('[Auth] GetPaitentGuarantor',
    props<{ payload: any }>()
)
export const GetPaitentGuarantorSuccess = createAction('[Auth] GetPaitentGuarantor Success',
    props<{ GetPaitentGuarantorData: any }>()
)
export const GetPatientGuarantorById = createAction('[Auth] GetPatientGuarantorById',
    props<{ payload: any }>()
)
export const GetPatientGuarantorByIdSuccess = createAction('[Auth] GetPatientGuarantorById Success',
    props<{ GetPatientGuarantorByIdData: any }>()
)
export const AddPaitentGuarantorGl = createAction('[Auth] AddPaitentGuarantorGl',
    props<{ payload: any }>()
)
export const AddPaitentGuarantorGlSuccess = createAction('[Auth] AddPaitentGuarantorGl Success',
    props<{ AddPaitentGuarantorGlData: any }>()
)
export const UpdatePaitentGuarantorGl = createAction('[Auth] UpdatePaitentGuarantorGl',
    props<{ payload: any }>()
)
export const UpdatePaitentGuarantorGlSuccess = createAction('[Auth] UpdatePaitentGuarantorGl Success',
    props<{ UpdatePaitentGuarantorGlData: any }>()
)
export const GetPaitentGuarantorGl = createAction('[Auth] GetPaitentGuarantorGl',
    props<{ payload: any }>()
)
export const GetPaitentGuarantorGlSuccess = createAction('[Auth] GetPaitentGuarantorGl Success',
    props<{ GetPaitentGuarantorGlData: any }>()
)
export const GetPatientGuarantorGlById = createAction('[Auth] GetPatientGuarantorGlById',
    props<{ payload: any }>()
)
export const GetPatientGuarantorGlByIdSuccess = createAction('[Auth] GetPatientGuarantorGlById Success',
    props<{ GetPatientGuarantorGlByIdData: any }>()
)
export const DeletePatientGuarantorGlById = createAction('[Auth] DeletePatientGuarantorGlById',
    props<{ payload: any }>()
)
export const DeletePatientGuarantorGlByIdSuccess = createAction('[Auth] DeletePatientGuarantorGlById Success',
    props<{ DeletePatientGuarantorGlByIdData: any }>()
)
export const GetPatientAuditLogs = createAction('[Auth] get Patient AuditLogs',
    props<{ payload: any }>()
)
export const GetPatientAuditLogsSuccess = createAction('[Auth] get Patient AuditLogs Success',
    props<{ getPatientAuditLogsData: any }>()
)
export const GetPatientKins = createAction('[Auth] get Patient Kins',
    props<{ payload: any }>()
)
export const GetPatientKinsSuccess = createAction('[Auth] get Patient Kins Success',
    props<{ getPatientKinsData: any }>()
)
export const addPatientKins = createAction('[Auth] add Patient Kins',
    props<{ payload: any }>()
)
export const addPatientKinsSuccess = createAction('[Auth] add Patient Kins Success',
    props<{ addPatientKinsData: any }>()
)
export const GetPatientKin = createAction('[Auth] get Patient Kin',
    props<{ payload: any }>()
)
export const GetPatientKinSuccess = createAction('[Auth] get Patient Kin Success',
    props<{ getPatientKinData: any }>()
)
export const editPatientKin = createAction('[Auth] edit Patient Kin',
    props<{ payload: any }>()
)
export const editPatientKinSuccess = createAction('[Auth] edit Patient Kin Success',
    props<{ editPatientKinData: any }>()
)
export const manageFamilyLinks = createAction('[Auth] manage Family Links',
    props<{ payload: any }>()
)
export const manageFamilyLinksSuccess = createAction('[Auth] manage Family Links Success',
    props<{ manageFamilyLinksData: any }>()
)
export const getFamilyLinks = createAction('[Auth] get Family Links',
    props<{ payload: any }>()
)
export const getFamilyLinksSuccess = createAction('[Auth] get Family Links Success',
    props<{ getFamilyLinksData: any }>()
)
export const addPatientId = createAction('[Auth] add PatientId',
    props<{ payload: any }>()
)
export const addPatientIdSuccess = createAction('[Auth] add PatientId Success',
    props<{ addPatientIdData: any }>()
)
export const editPatientId = createAction('[Auth] edit PatientId',
    props<{ payload: any }>()
)
export const editPatientIdSuccess = createAction('[Auth] edit PatientId Success',
    props<{ editPatientIdData: any }>()
)
export const getPatientId = createAction('[Auth] getPatientId',
    props<{ payload: any }>()
)
export const getPatientIdSuccess = createAction('[Auth] getPatientId Success',
    props<{ getPatientIdData: any }>()
)
export const managePatientId = createAction('[Auth] managePatientId',
    props<{ payload: any }>()
)
export const managePatientIdSuccess = createAction('[Auth] managePatientId Success',
    props<{ managePatientIdData: any }>()
)
export const addReferral = createAction('[Auth] add Referral',
    props<{ payload: any }>()
)
export const addReferralSuccess = createAction('[Auth] add Referral Success',
    props<{ addReferralData: any }>()
)
export const getEncounter = createAction('[Auth] get Encounter',
    props<{ payload: any }>()
)
export const getEncounterSuccess = createAction('[Auth] get Encounter Success',
    props<{ getEncounterData: any }>()
)
export const getDefaultService = createAction('[Auth] get Default Service',
    props<{ payload: any }>()
)
export const getDefaultServiceSuccess = createAction('[Auth] get Default Service Success',
    props<{ getDefaultServiceData: any }>()
)
export const getDoctorDefaultService = createAction('[Auth] getDoctor Default Service',
    props<{ payload: any }>()
)
export const getDoctorDefaultServiceSuccess = createAction('[Auth] getDoctor Default Service Success',
    props<{ getDoctorDefaultServiceData: any }>()
)
export const getDoctorShare = createAction('[Auth] getDoctor Share',
    props<{ payload: any }>()
)
export const getDoctorShareSuccess = createAction('[Auth] getDoctor Share Success',
    props<{ getDoctorShareData: any }>()
)
export const getReferrals = createAction('[Auth] get Referrals',
    props<{ payload: any }>()
)
export const getReferralsSuccess = createAction('[Auth] get Referrals Success',
    props<{ getReferralsData: any }>()
)
export const addRegisterWithBill = createAction('[Auth] add Register With Bill',
    props<{ payload: any }>()
)
export const addRegisterWithBillSuccess = createAction('[Auth] add Register With Bill Success',
    props<{ addRegisterWithBillData: any }>()
)
export const getFacilityDashboard = createAction('[Auth] getFacilityDashboard',
    props<{ payload: any }>()
)
export const getFacilityDashboardSuccess = createAction('[Auth] getFacilityDashboard Success',
    props<{ getFacilityDashboardData: any }>()
)
export const GetOtRegisters = createAction('[Auth] GetOtRegisters',
    props<{ payload: any }>()
)
export const GetOtRegistersSuccess = createAction('[Auth] GetOtRegisters Success',
    props<{ GetOtRegistersData: any }>()
)
export const getOpdDashboard = createAction('[Auth] getOpdDashboard',
    props<{ payload: any }>()
)
export const getOpdDashboardSuccess = createAction('[Auth] getOpdDashboard Success',
    props<{ getOpdDashboardData: any }>()
)
export const getMrdRequest = createAction('[Auth] getMrdRequest',
    props<{ payload: any }>()
)
export const getMrdRequestSuccess = createAction('[Auth] getMrdRequest Success',
    props<{ getMrdRequestData: any }>()
)
export const addMrdRequest = createAction('[Auth] addMrdRequest',
    props<{ payload: any }>()
)
export const addMrdRequestSuccess = createAction('[Auth] addMrdRequest Success',
    props<{ addMrdRequestData: any }>()
)
export const addConsultantCheckoutPatient = createAction('[Auth] addConsultantCheckoutPatient',
    props<{ payload: any }>()
)
export const addConsultantCheckoutPatientSuccess = createAction('[Auth] addConsultantCheckoutPatient Success',
    props<{ addConsultantCheckoutPatientData: any }>()
)
export const CheckoutPatient = createAction('[Auth] CheckoutPatient',
    props<{ payload: any }>()
)
export const CheckoutPatientSuccess = createAction('[Auth] CheckoutPatient Success',
    props<{ CheckoutPatientData: any }>()
)
export const assignPatient = createAction('[Auth] assignPatient',
    props<{ payload: any }>()
)
export const assignPatientSuccess = createAction('[Auth] assignPatient Success',
    props<{ assignPatientData: any }>()
)
export const mergePatient = createAction('[Auth] mergePatient',
    props<{ payload: any }>()
)
export const mergePatientSuccess = createAction('[Auth] mergePatient Success',
    props<{ mergePatientData: any }>()
)
export const getPatientBills = createAction('[Auth] get Patient Bills',
    props<{ payload: any }>()
)
export const getPatientBillsSuccess = createAction('[Auth] get Patient Bills Success',
    props<{ getPatientBillsData: any }>()
)
//Patient Followup

export const getPatientFollowup = createAction('[Auth] getPatientFollowup',
    props<{ payload: any }>()
)
export const getPatientFollowupSuccess = createAction('[Auth] getPatientFollowup Success',
    props<{ getPatientFollowupData: any }>()
)

export const addPatientFollowup = createAction('[Auth] addPatientFollowup',
    props<{ payload: any }>()
)
export const addPatientFollowupSuccess = createAction('[Auth] addPatientFollowup Success',
    props<{ addPatientFollowupData: any }>()
)
export const viewPatientFollowup = createAction('[Auth] viewPatientFollowup',
    props<{ payload: any }>()
)
export const viewPatientFollowupSuccess = createAction('[Auth] viewPatientFollowup Success',
    props<{ viewPatientFollowupData: any }>()
)
export const updatePatientFollowup = createAction('[Auth] updatePatientFollowup',
    props<{ payload: any }>()
)
export const updatePatientFollowupSuccess = createAction('[Auth] updatePatientFollowup Success',
    props<{ updatePatientFollowupData: any }>()
)
export const addOccupation = createAction('[Auth] add Occupation',
    props<{ payload: any }>()
)
export const addOccupationSuccess = createAction('[Auth] add Occupation Success',
    props<{ addOccupationData: any }>()
)
export const getOccupation = createAction('[Auth] get Occupation',
    props<{ payload: any }>()
)
export const getOccupationSuccess = createAction('[Auth] get Occupation Success',
    props<{ getOccupationData: any }>()
)
export const getDiagnosiss = createAction('[Auth] get Diagnosiss',
    props<{ payload: any }>()
)
export const getDiagnosissSuccess = createAction('[Auth] get Diagnosiss Success',
    props<{ getDiagnosissData: any }>()
)
export const errorMessage = createAction('[Auth] error Message',
    props<{ errorData: any }>()
)
export const clearErrorMessage = createAction('[Auth] clear Error Message',
    props<{ payload: string }>()
)
export const clearErrorMessageSuccess = createAction('[Auth] clear Error Message Success')
